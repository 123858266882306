/**
 * Widget Component
 *
 * Purpose:
 * The `Widget` component is designed to display a summary or data card with a title, counter, and an icon based on a specific type. It fetches data from the backend (such as registered users, available books, and book sales) and dynamically updates the displayed values. This component is used to present key metrics in a compact and visual way.
 *
 * Key Features:
 * - **Dynamic Content Based on Type**: The component takes a `type` prop that determines the content displayed. It can show the number of registered users, total book sales, or available books.
 * - **Data Fetching on Mount**: It fetches data from the backend API using `useEffect`. The data is fetched asynchronously using the JWT token from `localStorage` for authorization.
 * - **State Management**: Uses React's `useState` hook to manage the state for the total number of users, available books, and book sales.
 * - **Error Handling**: Handles potential errors if the API requests fail, logging an error message in the console.
 * - **Dynamic Styling**: Based on the `type` prop, different colors and icons are displayed for each widget type (e.g., registered users, book sales, available books).
 * - **Responsiveness**: The widget is designed to be flexible and can be used in different parts of the application to display relevant data.
 *
 * Props:
 * - `type`: A string that determines which metric to display. It can be one of the following values:
 *   - `"customer"`: Displays the number of registered users.
 *   - `"order"`: Displays the total number of book sales.
 *   - `"earnings"`: Displays the number of available books.
 * 
 * Expected Behavior:
 * - **Fetch Data on Mount**: When the component is mounted, it fetches data from the backend APIs for users, books, and book sales, and updates the state accordingly.
 * - **Display Data**: Depending on the `type` prop, it will display the corresponding data (registered users, book sales, or available books) with a title and counter.
 * - **Icon and Color Based on Type**: The widget will display an icon and background color specific to the type (`PersonOutlineOutlinedIcon` for users, `ShoppingCartOutlinedIcon` for book sales, and `BookIcon` for available books).
 * - **Handle Errors**: If there is an error in fetching data from any of the APIs, an error message will be logged to the console.
 *
 * Dependencies:
 * - `react` for component creation, state management, and lifecycle hooks.
 * - `@mui/icons-material` for the Material UI icons used in the widget.
 * - `fetch` for making HTTP requests to the backend APIs.
 * - `localStorage` for storing and retrieving the JWT token for authorization.
 * - `widget.scss` for styling the widget component.
 *
 * Usage:
 * ```jsx
 * <Widget type="customer" />
 * <Widget type="order" />
 * <Widget type="earnings" />
 * ```
 */

//================================WIDGET=====================================


import React, { useState, useEffect } from "react";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import BookIcon from '@mui/icons-material/Book';
import "./widget.scss";
import { BASE_URL } from '../config/apiConfig';
// State to hold data for users, books, and book sales
const Widget = ({ type }) => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalAvailableBooks, setTotalAvailableBooks] = useState(0);
  const [totalBookSale, setTotalBookSale] = useState(0);
  // useEffect hook to fetch data from the backend on component mount
  useEffect(() => {
    // Asynchronous function to fetch data
    const fetchData = async () => {
      try {
        // Retrieve the JWT token from localStorage for authentication
        const key = localStorage.getItem('jwtToken');
        // Fetch data from the backend for registered users, available books, and book sales
        const userResponse = await fetch(`${BASE_URL}/dashboard/get-all-users-detail`, {
          headers: {
            'Content-Type': 'application/json',
            'Auth': `Bearer ${key}`,// Pass the JWT token in the header for authorization
          },
        });
        const bookResponse = await fetch(`${BASE_URL}/dashboard/get`, {
          headers: {
            'Content-Type': 'application/json',
            'Auth': `Bearer ${key}`,
          },
        });
        const orderResponse = await fetch(`${BASE_URL}/dashboard/orders`, {
          headers: {
            'Content-Type': 'application/json',
            'Auth': `Bearer ${key}`,
          },
        });
        // Handle error if any of the API requests fail
        if (!userResponse.ok || !bookResponse.ok || !orderResponse.ok) {
          throw new Error("Failed to fetch data");
        }
        // Parse the JSON response from each API call
        const userData = await userResponse.json();
        const bookData = await bookResponse.json();
        const orderData = await orderResponse.json();

        // Update state with fetched data
        setTotalUsers(userData.totalUsers);
        setTotalAvailableBooks(bookData.totalAvailableBook);
        setTotalBookSale(orderData.totalBookSale);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };
    // Call the fetchData function once on component mount
    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once
  // Determine the content to display based on the "type" prop passed to the component
  let data;

  switch (type) {
    case "customer":
      data = {
        title: "Registered Users",
        counter: totalUsers,
        icon: (
          <PersonOutlineOutlinedIcon
            className="icon"
            style={{ color: "crimson", backgroundColor: "#ff000033" }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "Book Sale",
        counter: totalBookSale,
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{ color: "goldenrod", backgroundColor: "#daa52033" }}
          />
        ),
      };
      break;
    case "earnings":
      data = {
        title: "Available Book",
        counter: totalAvailableBooks,
        icon: (
          <BookIcon
            className="icon"
            style={{ color: "green", backgroundColor: "#00800033" }}
          />
        ),
      };
      break;
    default:
      data = {
        title: "Unknown",
        counter: 0,
        icon: null,
      };
      break;
  }
  // Render the widget component with the appropriate content
  return (
    <div className={`widget ${type}`}>
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">{data.counter}</span>

      </div>
      <div className="right">
        <div className="percentage positive">
          {data.icon}
        </div>
      </div>
    </div>
  );
};

export default Widget;
