/**
 * EmailVerify Component
 * 
 * This component handles the display of the email verification status page. It provides users with feedback on
 * the outcome of their email verification process based on the URL parameters (status). The status could indicate
 * whether the email was successfully verified, the account was already verified, the verification token was invalid,
 * or an unknown error occurred.
 * 
 * - The component checks the `status` query parameter from the URL to determine what message and icon to display.
 * - Depending on the status, it shows an appropriate message (e.g., success, already verified, invalid token) and an
 *   icon (checkmark, lock, cross, etc.).
 * - It uses inline styles and conditional rendering to style the icon color and the message based on the status.
 * 
 * - `getMessageAndIcon`: Determines the message, icon, and icon color based on the verification status.
 * 
 * The layout includes a centered box with the status message and icon, which is responsive for different screen sizes
 * (desktop, tablet, and mobile).
 */

import React from "react";
import { useSearchParams } from "react-router-dom";

const EmailVerify = () => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  // Function to get the message and icon based on the status
  const getMessageAndIcon = () => {
    switch (status) {
      case "success":
        return {
          message: "Your email was verified. You can continue using the application.",
          icon: "✔️", // Success icon
          iconColor: "#6ab04c", // Green color for success
        };
      case "already-verified":
        return {
          message: "This account has already been verified, please login.",
          icon: "🔒", // Locked icon for already verified
          iconColor: "#f39c12", // Yellow color for "already verified"
        };
      case "invalid":
        return {
          message: "Invalid verification token.",
          icon: "❌", // Cross icon for invalid
          iconColor: "#e74c3c", // Red color for error
        };
      default:
        return {
          message: "Something went wrong. Please try again.",
          icon: "❓", // Question mark for unknown errors
          iconColor: "#95a5a6", // Gray color for unknown errors
        };
    }
  };

  const { message, icon, iconColor } = getMessageAndIcon();

  return (
    <div className="email-verify-container">
      <div className="email-verify-box">
        <div className="verify-icon" style={{ backgroundColor: iconColor }}>
          <span>{icon}</span>
        </div>
        <h2>Email Verification</h2>
        <p>{message}</p>
      </div>

      {/* Internal CSS Styling */}
      <style jsx>{`
        /* General container styles */
        .email-verify-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100vh;
          background-color: #f5f5f5; /* Light gray background */
          font-family: Arial, sans-serif;
          margin: 0;
        }

        /* Box styles */
        .email-verify-box {
          background-color: white;
          padding: 30px 40px;
          width: 400px;
          text-align: center;
          border-radius: 12px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
          height: 350px; /* Fixed height */
        }

        /* Verification icon styles */
        .verify-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          font-size: 32px;
          margin: 0 auto 20px;
        }

        /* Heading styles */
        .email-verify-box h2 {
          font-size: 24px;
          margin: 0 0 10px;
          color: #333; /* Dark text color */
        }

        /* Paragraph text */
        .email-verify-box p {
          color: #666; /* Light gray text */
          font-size: 16px;
          line-height: 1.5;
        }

        /* Footer styles */
        footer {
          margin-top: 20px;
          font-size: 14px;
          color: #888;
          text-align: center;
        }

        /* Responsive styles */
        @media (max-width: 768px) {
          .email-verify-box {
            width: 90%; /* Full width on smaller screens */
            height: auto; /* Auto height to adjust based on content */
            padding: 20px 30px; /* Less padding on smaller screens */
          }

          .verify-icon {
            width: 50px;
            height: 50px;
            font-size: 28px; /* Smaller icon on small screens */
          }

          .email-verify-box h2 {
            font-size: 20px; /* Smaller heading font size */
          }

          .email-verify-box p {
            font-size: 14px; /* Smaller paragraph text */
          }
        }

        @media (max-width: 480px) {
          .email-verify-box {
            width: 95%; /* Even more reduced width on very small screens */
            padding: 15px 20px; /* Further reduced padding */
          }

          .verify-icon {
            width: 40px;
            height: 40px;
            font-size: 24px; /* Even smaller icon */
          }

          .email-verify-box h2 {
            font-size: 18px; /* Further reduced font size */
          }

          .email-verify-box p {
            font-size: 12px; /* Smaller text for smaller devices */
          }
        }
      `}</style>
    </div>
  );
};

export default EmailVerify;
