/**
 * Book Component
 *
 * Purpose:
 * The `Book` component is designed to manage and display a list of books in an admin panel. It allows the admin to:
 * - View details of all books available in the system.
 * - Search books by title.
 * - Update the quantity and price of existing books.
 * - Add new books to the system, including uploading an image for each book.
 *
 * Functionality:
 * - Fetches books from the server upon component load and displays them.
 * - Allows updating book details, such as quantity and price.
 * - Provides a popup form to add new books with fields for title, author, price, quantity, and image.
 * - Filters books based on search queries.
 *
 * Dependencies:
 * - React, Axios, React Router, and various custom components such as Sidebar and RedirectComponent.
 */
// ============Import necessary libraries and components======================================
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from "../../components/sidebar/Sidebar";
import BASE_URL from '../../components/config/apiConfig';
import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";

const Book = () => {
  // State variables to manage books, search queries, book updates, and adding new books
  const [books, setBooks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [updatedQuantity, setUpdatedQuantity] = useState('');
  const [updatedPrice, setUpdatedPrice] = useState('');
  const [showAddBookPopup, setShowAddBookPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newBookData, setNewBookData] = useState({
    title: '',
    author: '',
    quantity: '',
    price: '',
    image: '',
    year: '',
  });
  const [expandedTitle, setExpandedTitle] = useState({});
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [selectedBook, setSelectedBook] = useState(null);
  // Fetch all books when the component mounts
  useEffect(() => {
    fetchBooks();
  }, []);
  // Fetch books from the api
  const fetchBooks = async () => {
    try {
      const key = localStorage.getItem('jwtToken');
      const response = await axios.get(`${BASE_URL}/dashboard/get-all-book-admin`, {
        headers: {
          'Content-Type': 'application/json',
          'Auth': `Bearer ${key}`,
        },
      });
      setBooks(response.data);
    } catch (error) {
      console.error('Error fetching book details:', error);
    }
  };
  // Toggle the expanded/collapsed state of a book's title
  const toggleExpandedTitle = (bookId) => {
    setExpandedTitle((prevState) => ({
      ...prevState,
      [bookId]: !prevState[bookId],
    }));
  };
  // Handle the click to update a book's details
  const handleUpdateClick = (book) => {
    setSelectedBook(book);
    setUpdatedQuantity(book.quantity);
    setUpdatedPrice(book.price);
  };
  // Save the updated book details
  const handleUpdate = async () => {
    try {
      const key = localStorage.getItem('jwtToken');
      const updatedBook = {
        ...selectedBook,
        quantity: updatedQuantity,
        price: updatedPrice,
      };
      // Send updated book details to the server
      await axios.put(`${BASE_URL}/api/booktables/${selectedBook.id}`, updatedBook, {
        headers: { 'Authorization': `Bearer ${key}` },
      });
      console.log("Book updated successfully");
      fetchBooks();
    } catch (error) {
      console.error('Error updating book:', error);
    }
  };
  // Handle input changes for the add book form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBookData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // Handle file input changes for adding book image
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewBookData((prevData) => ({
      ...prevData,
      image: file,
    }));
  };
  // Submit the form to add a new book
  const handleAddBookSubmit = async () => {
    try {
      const key = localStorage.getItem('jwtToken');
      const formData = new FormData();
      for (const key in newBookData) {
        formData.append(key, newBookData[key]);
      }
      // Check for valid image file type
      if (newBookData.image.type !== 'image/png') {
        setErrorMessage('Invalid image file type. Only PNG images are allowed.');
        return;
      }
      // Send new book data to the server
      const response = await axios.post(`${BASE_URL}/dashboard/add`, formData, {
        headers: {
          'Auth': `Bearer ${key}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        console.log("New book added");
        fetchBooks();
      } else {
        console.error('Error adding new book. Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error adding new book:', error);
    }

    if (errorMessage) {
      setErrorMessage('');
    }
  };
  // Filter books based on search query
  const filteredBooks = books.books
    ? books.books.filter((book) =>
      book.title.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : [];

  return (
    <div style={{ display: 'flex', minHeight: '100vh' }}>
      <RedirectComponent />
      <Sidebar />
      <div style={{ flex: 1, padding: '20px', marginLeft: '250px' }}>
        <h2 style={{ textAlign: 'center' }}>Book Details</h2>
        <input
          type="text"
          placeholder="Search by title"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: '100%',
            marginBottom: '10px',
            padding: '8px',
            fontSize: '16px',
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <Link to="/orderbook" style={{ textDecoration: 'none' }}>
            <button style={{ padding: '10px', fontSize: '16px', background: '#4CAF50', color: 'white', border: 'none', cursor: 'pointer' }}>
              Ordered Books
            </button>
          </Link>
          <button
            style={{ padding: '10px', fontSize: '16px', background: '#4CAF50', color: 'white', border: 'none', cursor: 'pointer' }}
            onClick={() => setShowAddBookPopup(true)}
          >
            Add New Book
          </button>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          {filteredBooks.map((book) => (
            <div
              key={book.id}
              style={{
                marginBottom: '20px',
                marginRight: '20px',
                maxWidth: '250px',
                textAlign: 'center',
                border: '1px solid #ddd',
                borderRadius: '4px',
                padding: '10px',
                width: '250px',
              }}
            >
              <div style={{ cursor: 'pointer', fontWeight: 'bold', color: 'black' }} onClick={() => toggleExpandedTitle(book.id)}>
                {expandedTitle[book.id] ? book.title : book.title.substring(0, 20)}
              </div>
              <div style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
                <img
                  src={`${BASE_URL}${book.imageUrl}`}
                  alt={book.title}
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
              </div>
              <p>Author: {book.author}</p>
              <p>Year: {book.year}</p>
              <p>Quantity: {book.quantity}</p>
              <p>Price: ₹ {book.price}</p>
              <p>Ratings: {parseFloat(book.ratings).toFixed(1)} &#9733;</p>
              <button
                style={{ padding: '10px', fontSize: '16px', background: '#4CAF50', color: 'white', border: 'none', cursor: 'pointer' }}
                onClick={() => handleUpdateClick(book)}
              >
                Update
              </button>
              {selectedBook && selectedBook.id === book.id && (
                <div style={{ marginTop: '10px' }}>
                  <input
                    type="number"
                    placeholder="Enter new quantity"
                    value={updatedQuantity}
                    onChange={(e) => setUpdatedQuantity(e.target.value)}
                    style={{ marginRight: '10px', padding: '8px' }}
                  />
                  <input
                    type="number"
                    placeholder="Enter new price"
                    value={updatedPrice}
                    onChange={(e) => setUpdatedPrice(e.target.value)}
                    style={{ marginRight: '10px', padding: '8px' }}
                  />
                  <button
                    style={{ padding: '10px', fontSize: '16px', background: '#4CAF50', color: 'white', border: 'none', cursor: 'pointer' }}
                    onClick={handleUpdate}
                  >
                    Save Changes
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
        {showAddBookPopup && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999,
            }}
          >
            <div
              style={{
                backgroundColor: '#fff',
                padding: '20px',
                zIndex: 999,
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                border: '1px solid #ccc',
                borderRadius: '8px',
                maxWidth: '400px',
                width: '100%',
              }}
            >
              <h2 style={{ textAlign: 'center', marginBottom: '10px', backgroundColor: 'gray', padding: '10px' }}>
                Add New Book
              </h2>
              {errorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>}
              <div>
                <input
                  type="text"
                  name="title"
                  placeholder="Title"
                  value={newBookData.title}
                  onChange={handleInputChange}
                  style={{ display: 'block', marginBottom: '10px', width: '100%', padding: '10px' }}
                />
                <input
                  type="text"
                  name="author"
                  placeholder="Author"
                  value={newBookData.author}
                  onChange={handleInputChange}
                  style={{ display: 'block', marginBottom: '10px', width: '100%', padding: '10px' }}
                />
                <input
                  type="number"
                  name="quantity"
                  placeholder="Quantity"
                  value={newBookData.quantity}
                  onChange={handleInputChange}
                  style={{ display: 'block', marginBottom: '10px', width: '100%', padding: '10px' }}
                />
                <input
                  type="number"
                  name="price"
                  placeholder="Price"
                  value={newBookData.price}
                  onChange={handleInputChange}
                  style={{ display: 'block', marginBottom: '10px', width: '100%', padding: '10px' }}
                />
                <input
                  type="text"
                  name="year"
                  placeholder="Year"
                  value={newBookData.year}
                  onChange={handleInputChange}
                  style={{ display: 'block', marginBottom: '10px', width: '100%', padding: '10px' }}
                />
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: 'block', marginBottom: '10px', width: '100%', padding: '10px' }}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button
                  style={{
                    padding: '10px',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                    flex: 1,
                    marginRight: '10px',
                  }}
                  onClick={handleAddBookSubmit}
                >
                  Submit
                </button>
                <button
                  style={{
                    padding: '10px',
                    backgroundColor: 'red',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                    flex: 1,
                  }}
                  onClick={() => setShowAddBookPopup(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Book;
