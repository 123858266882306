/**
 * Footer Component
 * 
 * This component renders a sticky footer at the bottom of the page, ensuring it stays
 * at the bottom regardless of the content length. It uses a dark background color,
 * white text, and is styled with padding and a subtle shadow effect.
 * 
 * You can adjust the position and styling to fit the page layout requirements. 
 * By default, the footer is absolutely positioned at the bottom of the page, 
 * but it can be modified to be fixed if needed for sticky behavior.
 */

import { Height } from "@mui/icons-material";
import React from "react";

const Footer = () => {
  const footerStyle = {
    backgroundColor: "#34495e",
    color: "#fff",
    textAlign: "center",
    padding: "20px 0", 
    // position: "fixed",
    bottom: "0",
    width: "100%",
    fontSize: "14px",
    boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
  };

  return (
    <footer style={footerStyle}>
      © 2024 National Institute of Nutrition. All rights reserved.
    </footer>
  );
};

export default Footer;

