/**
 * RatingsAndReviews Component
 *
 * Purpose:
 * This component displays the ratings and reviews section of a product or service. It visualizes the overall ratings as a star rating and provides a breakdown of the individual ratings by stars (1★ to 5★). Each rating is represented by a progress bar, where the width of each bar corresponds to the relative number of votes for each star rating compared to the highest rating count.
 *
 * Key Features:
 * - **Overall Rating Display**: Shows the average rating (e.g., 4.6★) along with the total number of ratings and reviews.
 * - **Rating Breakdown**: Displays a list of ratings by stars (1★ to 5★) with a progress bar indicating the proportion of votes for each rating.
 * - **Progress Bar Visualization**: The width of each progress bar is dynamically calculated based on the count of each rating and is proportional to the highest count, providing a visual comparison of ratings.
 * - **Responsive Design**: The component adjusts to different screen sizes and ensures that ratings and reviews are displayed clearly, regardless of the viewport.
 * - **Styling**: The component uses custom styles for layout and accessibility, with a clean, minimal design focused on displaying rating data in an easily digestible format.
 *
 * Expected Behavior:
 * - **Ratings Summary**: The component displays the average rating (e.g., 4.6★) along with the total number of ratings and reviews. This helps users quickly gauge the overall quality of the product or service.
 * - **Star Rating Breakdown**: A list of ratings by stars (1★ to 5★) is shown, each with a corresponding progress bar that visually represents the proportion of votes for that rating.
 * - **Interactive Design**: The component dynamically adjusts the width of each progress bar based on the number of votes for each rating, providing a visual hierarchy of popularity.
 *
 * Dependencies:
 * - `React` for creating the component and managing state.
 * - Custom CSS styles for layout, responsiveness, and accessibility.
 * - `Math.max()` JavaScript function for calculating the maximum rating count.
 *
 * Example:
 * - The component receives an array of ratings data in the form of an object with the properties `stars` and `count`. It then calculates the width of each progress bar based on the highest count and renders the ratings breakdown on the page.
 *
 * Usage:
 * ```jsx
 * <RatingsAndReviews />
 * ```
 */

// This component displays a Ratings & Reviews section with an overall rating and a breakdown of ratings by stars.
// Each rating is visualized with a progress bar indicating its proportion relative to the highest rating count.


import React from 'react';

const RatingsAndReviews = () => {
  // Data for ratings
  const ratingsData = [
    { stars: '5★', count: 332 },
    { stars: '4★', count: 700 },
    { stars: '3★', count: 346 },
    { stars: '2★', count: 127 },
    { stars: '1★', count: 368 }
  ];

  // Find maximum count
  const maxCount = Math.max(...ratingsData.map(item => item.count));

  return (
    <div className="card z-index-3 ratings-container" style={{ marginTop: '40px', maxWidth: '100%', overflowX: 'auto' }}>
      <style>{`
        .ratings-container {
          width: 100%;
          margin: 20px auto;
          padding: 10px;
        }

        .rating-row {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 5px;
        }

        .star-label {
          margin-right: 10px;
        }

        .progress-bar1 {
          flex: 1;
          height: 10px;
          background-color: #ddd;
          border-radius: 5px;
          overflow: hidden;
          margin-right: 10px;
        }

        .progress-value {
          height: 100%;
          background-color: #5cb85c;
        }

        .ratings-summary {
          text-align: center;
          margin-bottom: 5px;
        }

        h6 {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
          margin-top: 0;
        }

        .ratings-reviews {
          font-size: 18px;
          margin-top: 5px;
        }
      `}</style>

      <div className="ratings-summary">
        <h6>Ratings &amp; Reviews</h6>
        <h6>4.6★</h6>
        <div className="ratings-reviews">
          220 Ratings &amp; <br /> 18 Reviews
        </div>
      </div>

      <div className="ratings-summary">
        <div className="overall-rating">
          {ratingsData.map((rating, index) => (
            <div className="rating-row" key={index}>
              <span className="star-label">{rating.stars}</span>
              <div className="progress-bar1">
                <div className="progress-value" style={{ width: `${(rating.count / maxCount) * 100}%` }}></div>
              </div>
              <span className="rating-count">{rating.count}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RatingsAndReviews;
