/**
 * Sidebar Component
 *
 * Purpose:
 * This component implements a responsive and navigational sidebar menu for the application. The sidebar includes various menu items that allow users to navigate between different sections of the application. It also provides a toggle button to open and close the sidebar.
 *
 * Key Features:
 * - **Collapsible Design**: The sidebar can be toggled open and closed using a button. The `isOpen` state controls the visibility of the sidebar.
 * - **Dynamic Active Links**: Highlights the active menu item based on the current route. The active route is determined using the `useLocation` hook from `react-router-dom`.
 * - **Icon and Label Integration**: Each menu item includes an icon (from Material UI) and descriptive text, making navigation intuitive for users.
 * - **Navigation Links**: The sidebar contains links to various application pages (e.g., Dashboard, Health Status, Users Data, etc.) using `react-router-dom`'s `Link` component.
 * - **Styling**: Includes custom styles from `sidebar.scss` for styling the layout, ensuring a responsive and user-friendly interface.
 * - **Responsive Design**: The sidebar is designed to be responsive and adapts to different screen sizes. On smaller screens, it can be toggled via the hamburger menu.
 *
 * Expected Behavior:
 * - **Toggle Sidebar**: When the user clicks on the hamburger menu button, the sidebar will open or close, depending on its current state.
 * - **Active Link Highlighting**: The active menu item is visually highlighted based on the current route, providing users with clear feedback about their location within the app.
 * - **Navigation**: Clicking on a sidebar item will navigate the user to the corresponding page using `react-router-dom`.
 *
 * Dependencies:
 * - `react` for component creation and state management.
 * - `@mui/icons-material` for the Material UI icons used in the sidebar menu items.
 * - `react-router-dom` for handling navigation and routing.
 * - Custom `sidebar.scss` file for styling the sidebar.
 *
 * Usage:
 * ```jsx
 * <Sidebar />
 * ```
 */

// The Sidebar component implements a responsive and navigational sidebar menu for the application. It includes:

// Collapsible Design: The sidebar can be toggled open and closed using a button.
// Dynamic Active Links: Highlights the active menu item based on the current route (useLocation).
// Icon and Label Integration: Each menu item includes an icon (from Material UI) and descriptive text.
// Navigation Links: Provides links to various application pages using react-router-dom.
// Styling: Includes class-based styling from sidebar.scss for customization.


import React, { useState } from "react";
import ErrorIcon from '@mui/icons-material/Error';
import DashboardIcon from "@mui/icons-material/Dashboard";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import Person3OutlinedIcon from "@mui/icons-material/Person3Outlined";
import BookIcon from '@mui/icons-material/Book';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import LocalDiningIcon from '@mui/icons-material/LocalDining';

import "./sidebar.scss"; // Import the CSS file
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button className="toggle-sidebar-btn" onClick={toggleSidebar}>
        <MenuIcon style={{ color: '#000' }} />
      </button>
      <div className={`sidebar ${isOpen ? "show" : ""}`}>
        <div className="top">
          <Link to="/" style={{ textDecoration: "none", fontSize: '18px' }}>
            <span className="logo">NIN Admin Dashboard</span>
          </Link>
        </div>
        <hr />
        <div className="bottom">
          <ul>
            <Link to="/home" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/home" ? "active" : ""}>
                <DashboardIcon className="icon" />
                <span>Dashboard</span>
              </li>
            </Link>
            <Link to="/health" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/health" ? "active" : ""}>
                <HealthAndSafetyIcon className="icon" />
                <span>Health Status</span>
              </li>
            </Link>
            <Link to="/users" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/users" ? "active" : ""}>
                <Person3OutlinedIcon className="icon" />
                <span>Users Data</span>
              </li>
            </Link>
            <Link to="/bookdata" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/bookdata" ? "active" : ""}>
                <BookIcon className="icon" />
                <span>Book Details</span>
              </li>
            </Link>
            <Link to="/bugs" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/bugs" ? "active" : ""}>
                <ErrorIcon className="icon" />
                <span>Bug Reported</span>
              </li>
            </Link>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/contact" ? "active" : ""}>
                <ContactEmergencyIcon className="icon" />
                <span>Feedback</span>
              </li>
            </Link>

            <Link to="/rawdata" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/rawdata" ? "active" : ""}>
                <EmojiFoodBeverageIcon className="icon" />
                <span>Raw Food</span>
              </li>
            </Link>
            <Link to="/recipes" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/recipes" ? "active" : ""}>
                <LocalDiningIcon className="icon" />
                <span>Recipes</span>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
