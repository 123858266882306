
/**
 * Health Page Component
 * This component is used to display various health-related metrics such as BMI, calories, sleep data, water intake, gender distribution,
 * work level, steps, heart rate, and blood pressure statistics. The data is fetched from an API and visualized using charts.
 * 
 * The component utilizes Chart.js to render line and bar charts based on data from the server. Each metric has its own chart and is loaded asynchronously.
 * - BMI Chart
 * - Calories Burned Chart
 * - Sleep Data Chart
 * - Water Intake Chart
 * - Gender Distribution Chart
 * - Work Level Data Chart
 * - Step Count Data Chart
 * - Heart Rate Data Chart
 * - Blood Pressure Data Chart (By age group and gender)
 * 
 * State management is used to store data for each metric, and loading/error states are managed to show the appropriate UI during data fetching.
 * 
 * Features:
 * 1. Fetches data from multiple endpoints.
 * 2. Dynamically generates and updates charts based on fetched data.
 * 3. Provides error handling and loading states for each data type.
 * 4. Uses React hooks for state management and lifecycle management via useEffect.
 * 
 * Dependencies:
 * - Chart.js (For rendering charts)
 * - Reactstrap (For layout components like Row, Col, Card)
 * - Bootstrap (For styling)
 * - API calls made using fetch to a backend for health statistics.
 * 
 * Components included:
 * - Sidebar
 * - Navbar
 * - RedirectComponent (for redirecting if necessary)
 */
//===========================================Health page ====================================================================

import React, { useState, useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js/auto";
import { Row, Col, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import "./health.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css"; // Add Bootstrap CSS
import Navbar from "../../components/navbar/Navbar";
import BASE_URL from '../../components/config/apiConfig';
import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
Chart.register(...registerables);

function Health(props) {
  //=========================state==============================
  const [bmiData, setBmiData] = useState({});
  const [caloriesData, setCaloriesData] = useState([]);
  const [sleepData, setSleepData] = useState([]);
  const [waterIntakeData, setWaterIntakeData] = useState({});
  const [genderCountData, setGenderCountData] = useState({});
  const genderCountChartRef = useRef(null);
  const [workLevelData, setWorkLevelData] = useState({});
  const workLevelChartRef = useRef(null);
  const [stepsData, setStepsData] = useState([]);
  const stepsChartRef = useRef(null);
  const [heartRateData, setHeartRateData] = useState({});
  const [calorieData, setCalorieData] = useState([]); // Declare setCalorieData
  const calorieChartRef = useRef(null); // Declare calorieChartRef
  const bmiChartRef = useRef(null);
  const caloriesChartRef = useRef(null);
  const sleepChartRef = useRef(null);
  const waterIntakeChartRef = useRef(null);
  const totalStepsKmsChartRef = useRef(null);
  const heartRateChartRef = useRef(null);
  const bloodPressureChartRef = useRef(null);



  const [calorieDataLoading, setCalorieDataLoading] = useState(true);
  const [calorieDataError, setCalorieDataError] = useState(null);
  const [bmiDataLoading, setBmiDataLoading] = useState(true);
  const [bmiDataError, setBmiDataError] = useState(null);
  const [sleepDataLoading, setSleepDataLoading] = useState(true);
  const [sleepDataError, setSleepDataError] = useState(null);
  const [waterIntakeDataLoading, setWaterIntakeDataLoading] = useState(true);
  const [waterIntakeDataError, setWaterIntakeDataError] = useState(null);
  const [genderCountDataLoading, setGenderCountDataLoading] = useState(true);
  const [genderCountDataError, setGenderCountDataError] = useState(null);
  const [workLevelDataLoading, setWorkLevelDataLoading] = useState(true);
  const [workLevelDataError, setWorkLevelDataError] = useState(null);
  const [stepsDataLoading, setStepsDataLoading] = useState(true);
  const [stepsDataError, setStepsDataError] = useState(null);
  const [heartRateDataLoading, setHeartRateDataLoading] = useState(true);
  const [heartRateDataError, setHeartRateDataError] = useState(null);


  let bmiChart = null;
  let sleepChart = null;
  let waterIntakeChart = null;
  let genderCountChart = null;
  let workLevelChart = null;


  useEffect(() => {

    fetchBmiData();
    fetchSleepData();
    fetchWaterIntakeData();
    fetchGenderCountData();
    fetchWorkLevelData();
    fetchStepsData();

    return () => {

      if (bmiChart) {
        bmiChart.destroy();
      }

      if (sleepChart) {
        sleepChart.destroy();
      }
      if (waterIntakeChart) {
        waterIntakeChart.destroy();
      }

    };
  }, []);
  // =========================blood pressure..........===============================
  useEffect(() => {
    const fetchData = async () => {
      const key = localStorage.getItem("jwtToken");

      try {
        const response = await fetch(`${BASE_URL}/dashboard/blood-pressure-stats`, {
          headers: {
            "Content-Type": "application/json",
            Auth: `Bearer ${key}`,
          },
        });
        const data = await response.json();
        renderChart(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  const renderChart = (data) => {
    const labels = ['<15 yrs', '15-29 yrs', '30-44 yrs', '45-59 yrs', '>60 yrs'];
    const formattedData = {
      labels: labels,
      datasets: [
        {
          label: 'M-Glucose',
          data: labels.map(label => data.Male[label.split(' ')[0]] ? data.Male[label.split(' ')[0]].averageBloodGlucose : 0),
          fill: false,
          borderColor: 'blue',
        },
        {
          label: 'M-Diastolic',
          data: labels.map(label => data.Male[label.split(' ')[0]] ? data.Male[label.split(' ')[0]].averageDiastolicBloodPressure : 0),
          fill: false,
          borderColor: 'lightblue',
        },
        {
          label: 'M-Systolic',
          data: labels.map(label => data.Male[label.split(' ')[0]] ? data.Male[label.split(' ')[0]].averageSystolicBloodPressure : 0),
          fill: false,
          borderColor: 'darkblue',
        },
        {
          label: 'F-Glucose',
          data: labels.map(label => data.Female[label.split(' ')[0]] ? data.Female[label.split(' ')[0]].averageBloodGlucose : 0),
          fill: false,
          borderColor: 'lightgreen',
        },
        {
          label: 'F-Diastolic ',
          data: labels.map(label => data.Female[label.split(' ')[0]] ? data.Female[label.split(' ')[0]].averageDiastolicBloodPressure : 0),
          fill: false,
          borderColor: 'lightpink',
        },
        {
          label: 'F-Systolic',
          data: labels.map(label => data.Female[label.split(' ')[0]] ? data.Female[label.split(' ')[0]].averageSystolicBloodPressure : 0),
          fill: false,
          borderColor: 'darkpink',
        },
      ],
    };

    const options = {
      scales: {
        x: {
          title: {
            display: true,
            // text: 'Age Group',
          },
        },
        y: {
          title: {
            display: true,
            // text: 'Value',
          },
          min: 0,
        },
      },
    };

    const ctx = bloodPressureChartRef.current.getContext('2d');
    new Chart(ctx, {
      type: 'line',
      data: formattedData,
      options: options
    });
  };

  //==============================heart rate========================================================================


  useEffect(() => {
    let heartRateChart; // Declare heartRateChart variable

    if (heartRateData && typeof heartRateData === 'object' && Object.keys(heartRateData).length > 0) {
      const ageGroups = ['<15yrs', '15-29yrs', '30-44yrs', '45-59yrs', '>60yrs'];
      const maleHeartRates = Array(ageGroups.length).fill(0);
      const femaleHeartRates = Array(ageGroups.length).fill(0);

      if (heartRateChart) {
        heartRateChart.destroy();
      }

      // Check if heartRateData is an array of objects
      if (Array.isArray(heartRateData)) {
        heartRateData.forEach((entry) => {
          const index = ageGroups.indexOf(entry.ageGroup);
          if (index !== -1) {
            if (entry.gender === 'male') {
              maleHeartRates[index] = parseFloat(entry.average.split('bpm')[0]);
            } else if (entry.gender === 'female') {
              femaleHeartRates[index] = parseFloat(entry.average.split('bpm')[0]);
            }
          }
        });
      }

      heartRateChart = new Chart(heartRateChartRef.current, {
        type: "bar",
        data: {
          labels: ageGroups,
          datasets: [
            {
              label: "Male",
              backgroundColor: "rgba(54, 162, 235, 0.5)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
              data: maleHeartRates,
            },
            {
              label: "Female",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 1,
              data: femaleHeartRates,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Average Heart Rate in bpm', // Updated title
              },
            },
            x: {
              title: {
                display: true,
                text: '',
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                let label = data.datasets[tooltipItem.datasetIndex].label || '';
                if (label) {
                  label += ': ';
                }
                label += tooltipItem.yLabel.toLocaleString() + ' bpm';
                return label;
              }
            }
          },
          plugins: {
            title: {
              display: true,
              text: 'Average Heart Rate in bpm',
              font: {
                size: 16,
                weight: 'bold'
              }
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const datasetIndex = context.datasetIndex;
                  const ageGroup = context.label;
                  const gender = datasetIndex === 0 ? 'male' : 'female';
                  const entry = heartRateData.find(item => item.ageGroup === ageGroup && item.gender === gender);
                  if (entry) {
                    return [
                      `Gender: ${gender}`,
                      `Average Heart Rate: ${entry.average}`,
                      `User Count: ${entry.userCount}`
                    ];
                  }
                }
              }
            }
          }
        },
      });
    }

    return () => {
      if (heartRateChart) {
        heartRateChart.destroy();
      }
    };
  }, [heartRateData]);

  const fetchHeartRateData = async () => {
    const key = localStorage.getItem("jwtToken");
    try {
      const response = await fetch(`${BASE_URL}/dashboard/average-heart-rate`, {
        headers: {
          "Content-Type": "application/json",
          "Auth": `Bearer ${key}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setHeartRateData(data);
      } else {
        setHeartRateDataError("Failed to fetch heart rate data");
      }
    } catch (error) {
      setHeartRateDataError("Error fetching heart rate data: " + error.message);
    } finally {
      setHeartRateDataLoading(false);
    }
  };

  useEffect(() => {
    fetchHeartRateData();
  }, []);


  // ==============================avg step======================================================

  useEffect(() => {
    const ageGroups = ['<15yrs', '15-29yrs', '30-44yrs', '45-59yrs', '>60yrs'];
    const maleSteps = Array(ageGroups.length).fill(0);
    const femaleSteps = Array(ageGroups.length).fill(0);

    if (stepsChartRef.current && stepsData.length > 0) {
      stepsData.forEach(entry => {
        const index = ageGroups.indexOf(entry.ageGroup);
        if (index !== -1) {
          if (entry.gender === 'Male') {
            maleSteps[index] = parseFloat(entry.average);
          } else if (entry.gender === 'Female') {
            femaleSteps[index] = parseFloat(entry.average);
          }
        }
      });

      const stepsChart = new Chart(stepsChartRef.current, {
        type: 'bar',
        data: {
          labels: ageGroups,
          datasets: [
            {
              label: 'Male',
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
              data: maleSteps,
            },
            {
              label: 'Female',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
              data: femaleSteps,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          aspectRatio: 2, // Set aspect ratio to fill available space
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'No.of Average Steps',
              },
            },
            x: {
              title: {
                display: false, // Hide x-axis title
                text: '',
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Average Steps',
              font: {
                size: 16,
                weight: 'bold',
              },
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const datasetIndex = context.datasetIndex;
                  const ageGroup = context.label;
                  const gender = datasetIndex === 0 ? 'Male' : 'Female';
                  const entry = stepsData.find(item => item.ageGroup === ageGroup && item.gender === gender);
                  if (entry) {
                    return [
                      `Gender: ${gender}`,
                      `Average Steps: ${entry.average}`,
                      `User Count: ${entry.userCount}`,
                      `Active Users: ${entry.activeUsers}`,
                    ];
                  }
                },
              },
            },
          },
        },
      });

      return () => {
        stepsChart.destroy();
      };
    }
  }, [stepsData]);



  const fetchStepsData = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/dashboard/average-steps-by-age`
      );
      if (response.ok) {
        const data = await response.json();
        setStepsData(data);
      } else {
        setStepsDataError("Failed to fetch steps data");
      }
    } catch (error) {
      setStepsDataError("Error fetching steps data: " + error.message);
    } finally {
      setStepsDataLoading(false);
    }
  };

  useEffect(() => {
    fetchStepsData();
  }, []);


  //========================================work level=============================================
  useEffect(() => {
    let workLevelChart;

    if (Object.keys(workLevelData).length > 0 && workLevelChartRef.current) {
      if (workLevelChart) {
        workLevelChart.destroy();
      }

      const labels = Object.keys(workLevelData);
      const data = Object.values(workLevelData);

      workLevelChart = new Chart(workLevelChartRef.current, {
        type: "pie",
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: [
                "rgba(255, 99, 132, 0.5)",
                "rgba(54, 162, 235, 0.5)",
                "rgba(255, 206, 86, 0.5)",
                "rgba(75, 192, 192, 0.5)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: "bottom",
            labels: {
              fontColor: "#333",
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Number of Users by Work Level',
              font: {
                size: 16,
                weight: 'bold',
              },
            },
          },
        },
      });
    }

    return () => {
      if (workLevelChart) {
        workLevelChart.destroy();
      }
    };
  }, [workLevelData]);

  const fetchWorkLevelData = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/dashboard/byWorkLevel`,
      );
      if (response.ok) {
        const data = await response.json();
        setWorkLevelData(data.usersByWorkLevel);
      } else {
        setWorkLevelDataError("Failed to fetch work level data");
      }
    } catch (error) {
      setWorkLevelDataError("Error fetching work level data: " + error.message);
    } finally {
      setWorkLevelDataLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkLevelData();
  }, []);
  // ====================================gender count ========================================================
  useEffect(() => {
    if (genderCountChartRef.current && Object.keys(genderCountData).length > 0) {
      if (genderCountChart) {
        genderCountChart.destroy();
      }

      const labels = Object.keys(genderCountData);
      const data = Object.values(genderCountData);

      genderCountChart = new Chart(genderCountChartRef.current, {
        type: "pie",
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: ["rgba(54, 162, 235, 0.5)", "rgba(255, 99, 132, 0.5)"],
              borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Number of Users by Gender', // Set the title text
              font: {
                size: 16,
                weight: 'bold',
              },
            },
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              fontColor: "#333",
            },
          },
        },
      });
    }

    return () => {
      if (genderCountChart) {
        genderCountChart.destroy();
      }
    };
  }, [genderCountData]);


  const fetchGenderCountData = async () => {
    const key = localStorage.getItem("jwtToken");
    try {
      const response = await fetch(
        `${BASE_URL}/dashboard/gender-count`,
        {
          headers: {
            "Content-Type": "application/json",
            Auth: `Bearer ${key}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setGenderCountData(data);
      } else {
        setGenderCountDataError("Failed to fetch gender count data");
      }
    } catch (error) {
      setGenderCountDataError("Error fetching gender count data: " + error.message);
    } finally {
      setGenderCountDataLoading(false);
    }
  };

  useEffect(() => {
    fetchGenderCountData();
  }, []);


  // =========================Render water intake distribution chart=========================
  useEffect(() => {
    if (Object.keys(waterIntakeData).length > 0 && waterIntakeChartRef.current) {
      const labels = ['<15yrs', '15-29yrs', '30-44yrs', '45-59yrs', '>60yrs'];
      const maleIntake = Array(labels.length).fill(0);
      const femaleIntake = Array(labels.length).fill(0);

      if (waterIntakeChart) {
        waterIntakeChart.destroy();
      }

      waterIntakeData.forEach((entry) => {
        const index = labels.indexOf(entry.ageGroup);
        if (index !== -1) {
          if (entry.gender === 'male') {
            maleIntake[index] = parseFloat(entry.average.split('ml')[0]);
          } else if (entry.gender === 'female') {
            femaleIntake[index] = parseFloat(entry.average.split('ml')[0]);
          }
        }
      });

      waterIntakeChart = new Chart(waterIntakeChartRef.current, {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Male",
              backgroundColor: "rgba(54, 162, 235, 0.5)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
              data: maleIntake,
            },
            {
              label: "Female",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 1,
              data: femaleIntake,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Average Intake in ml', // Updated title
              },
            },
            x: {
              title: {
                display: true,
                text: '',
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                let label = data.datasets[tooltipItem.datasetIndex].label || '';
                if (label) {
                  label += ': ';
                }
                label += tooltipItem.yLabel.toLocaleString() + ' ml';
                return label;
              }
            }
          },
          plugins: {
            title: {
              display: true,
              text: 'Average Water Intake in ml',
              font: {
                size: 16,
                weight: 'bold'
              }
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const datasetIndex = context.datasetIndex;
                  const ageGroup = context.label;
                  const gender = datasetIndex === 0 ? 'male' : 'female';
                  const entry = waterIntakeData.find(item => item.ageGroup === ageGroup && item.gender === gender);
                  if (entry) {
                    return [
                      `Gender: ${gender}`,
                      `Average Intake: ${entry.average}`,
                      `User Count: ${entry.userCount}`
                    ];
                  }
                }
              }
            }
          }
        },
      });
    }

    return () => {
      if (waterIntakeChart) {
        waterIntakeChart.destroy();
      }
    };
  }, [waterIntakeData]);



  // ================Chart rendering for BMI Distribution=============================================
  useEffect(() => {
    if (bmiChartRef.current && Object.keys(bmiData).length > 0) {
      if (bmiChart) {
        bmiChart.destroy();
      }

      bmiChart = new Chart(bmiChartRef.current, {
        type: "bar",
        data: {
          labels: ["Underweight", "Normal", "Overweight", "Obese"],
          datasets: [
            {
              label: "Male",
              backgroundColor: "rgba(54, 162, 235, 0.5)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
              data: [
                bmiData.maleCategories.underweightCount,
                bmiData.maleCategories.normalCount,
                bmiData.maleCategories.overweightCount,
                bmiData.maleCategories.obeseCount,
              ],
            },
            {
              label: "Female",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 1,
              data: [
                bmiData.femaleCategories.underweightCount,
                bmiData.femaleCategories.normalCount,
                bmiData.femaleCategories.overweightCount,
                bmiData.femaleCategories.obeseCount,
              ],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'No. of Users',
              },
            },
          },
        },
      });
    }

    return () => {
      if (bmiChart) {
        bmiChart.destroy();
      }
    };
  }, [bmiData]);



  const fetchBmiData = async () => {
    setBmiDataLoading(true);
    const key = localStorage.getItem("jwtToken");
    try {
      const response = await fetch(`${BASE_URL}/dashboard/combined-bmi-categories`, {
        headers: {
          "Content-Type": "application/json",
          Auth: `Bearer ${key}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setBmiData(data);
      } else {
        setBmiDataError("Failed to fetch BMI data");
      }
    } catch (error) {
      setBmiDataError("Error fetching BMI data: " + error.message);
    } finally {
      setBmiDataLoading(false);
    }
  };

  //==============================fetch averagecalories==============================




  useEffect(() => {
    const fetchCalorieData = async () => {
      const key = localStorage.getItem("jwtToken");
      try {
        const response = await fetch(`${BASE_URL}/dashboard/averageCalories`, {
          headers: {
            "Content-Type": "application/json",
            Auth: `Bearer ${key}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCalorieData(data);
        } else {
          setCalorieDataError('Failed to fetch calorie data');
        }
      } catch (error) {
        setCalorieDataError('Error fetching calorie data: ' + error.message);
      } finally {
        setCalorieDataLoading(false);
      }
    };

    fetchCalorieData();
  }, []);
  useEffect(() => {
    let calorieChart;

    if (calorieData.length > 0 && calorieChartRef.current) {
      const ageGroups = ['<15yrs', '15-29yrs', '30-44yrs', '45-59yrs', '>60yrs'];
      const maleCalories = Array(ageGroups.length).fill(0);
      const femaleCalories = Array(ageGroups.length).fill(0);

      calorieData.forEach(entry => {
        const index = ageGroups.indexOf(entry.ageGroup);
        if (index !== -1) {
          if (entry.gender === 'Male') {
            maleCalories[index] = parseFloat(entry.averageCalories.split('kcal')[0]);
          } else if (entry.gender === 'Female') {
            femaleCalories[index] = parseFloat(entry.averageCalories.split('kcal')[0]);
          }
        }
      });

      calorieChart = new Chart(calorieChartRef.current, {
        type: 'bar',
        data: {
          labels: ageGroups,
          datasets: [
            {
              label: 'Male',
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
              data: maleCalories,
            },
            {
              label: 'Female',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
              data: femaleCalories,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          aspectRatio: 2, // Set aspect ratio to fill available space
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Average Calories in kcal',
              },
            },
            x: {
              title: {
                display: false, // Hide x-axis title
                text: '',
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Average Calories',
              font: {
                size: 16,
                weight: 'bold',
              },
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const datasetIndex = context.datasetIndex;
                  const ageGroup = context.label;
                  const gender = datasetIndex === 0 ? 'Male' : 'Female';
                  const entry = calorieData.find(item => item.ageGroup === ageGroup && item.gender === gender);
                  if (entry) {
                    return [
                      `Gender: ${gender}`,
                      `Average Calories: ${entry.averageCalories}`,
                      `User Count: ${entry.userCount}`,
                      `Active Users: ${entry.activeUsers}`,
                      `Activity Count: ${entry.activityCount}`,
                    ];
                  }
                },
              },
            },
            averageCalories: {
              display: true,
              text: 'Average Calories',
              position: 'top',
              align: 'start',
              font: {
                size: 14,
                weight: 'bold',
              },
              color: '#333',
              padding: 10,
            },
          },
        },
      });
    }

    return () => {
      if (calorieChart) {
        calorieChart.destroy();
      }
    };
  }, [calorieData]);



  //====================fetch sleep data =============================================================================================


  const fetchSleepData = async () => {
    const key = localStorage.getItem("jwtToken");
    try {
      const response = await fetch(
        `${BASE_URL}/dashboard/average-sleep`,
        {
          headers: {
            "Content-Type": "application/json",
            Auth: `Bearer ${key}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setSleepData(data);
      } else {
        setSleepDataError("Failed to fetch sleep data");
      }
    } catch (error) {
      setSleepDataError("Error fetching sleep data: " + error.message);
    } finally {
      setSleepDataLoading(false);
    }
  };

  useEffect(() => {
    fetchSleepData();
  }, []);




  useEffect(() => {
    if (!Array.isArray(sleepData) || sleepData.length === 0 || !sleepChartRef.current) return;

    const ctx = sleepChartRef.current.getContext('2d');
    let sleepChart;

    if (sleepChartRef.current.chart) {
      sleepChartRef.current.chart.destroy();
    }

    const labels = ['<15yrs', '15-29yrs', '30-44yrs', '45-59yrs', '>60yrs'];
    const maleDurations = Array(labels.length).fill(0);
    const femaleDurations = Array(labels.length).fill(0);

    sleepData.forEach((entry) => {
      const index = labels.indexOf(entry.ageGroup);
      if (index !== -1) {
        const durationInMinutes = parseFloat(entry.average.split('h:')[0]) * 60 + parseFloat(entry.average.split('h:')[1].replace('m', ''));
        if (entry.gender === 'Male') {
          maleDurations[index] = durationInMinutes;
        } else if (entry.gender === 'Female') {
          femaleDurations[index] = durationInMinutes;
        }
      }
    });

    // Set the width and height of the canvas element
    sleepChartRef.current.width = sleepChartRef.current.parentNode.clientWidth;
    sleepChartRef.current.height = sleepChartRef.current.parentNode.clientHeight;

    const newChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'Male',
          data: maleDurations,
          backgroundColor: 'rgba(54, 162, 235, 0.5)', // Blue color for male
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1
        },
        {
          label: 'Female',
          data: femaleDurations,
          backgroundColor: 'rgba(255, 99, 132, 0.2)', // Pink color for female
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1
        }]
      },
      options: {
        maintainAspectRatio: false,
        aspectRatio: 2, // Aspect ratio set to 2:1
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Duration in Minutes', // Updated title
            }
          },
          x: {
            title: {
              display: false, // Set display to false to hide the x-axis title
              text: ''
            }
          }
        },

        plugins: {
          title: {
            display: true,
            text: 'Average Sleep Duration', // Updated title
            font: {
              size: 16,
              weight: 'bold'
            }
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const datasetIndex = context.datasetIndex;
                const ageGroup = context.label;
                const gender = datasetIndex === 0 ? 'Male' : 'Female';
                const entry = sleepData.find(item => item.ageGroup === ageGroup && item.gender === gender);
                if (entry) {
                  return [
                    `Gender: ${gender}`,
                    `Average Duration: ${entry.average}`,
                    `User Count: ${entry.userCount}`,
                    `Active Users: ${entry.activeUsers}`
                  ];
                }
              }
            }
          }
        }
      }
    });

    // Store the chart instance in ref for later use
    sleepChartRef.current.chart = newChart;
  }, [sleepData]);




  //=================================water intake==================================
  const fetchWaterIntakeData = async () => {
    const key = localStorage.getItem("jwtToken");
    try {
      const response = await fetch(
        `${BASE_URL}/dashboard/average-water-intake`,
        {
          headers: {
            "Content-Type": "application/json",
            Auth: `Bearer ${key}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setWaterIntakeData(data);
      } else {
        setWaterIntakeDataError("Failed to fetch water intake data");
      }
    } catch (error) {
      setWaterIntakeDataError("Error fetching water intake data: " + error.message);
    } finally {
      setWaterIntakeDataLoading(false);
    }
  };

  useEffect(() => {
    fetchWaterIntakeData();
  }, []);


  // ===================================render the data on page==================================================
  return (
    <div className="content">
      <RedirectComponent />
      <Navbar />
      <div className="scrollableSidebar">
        <Sidebar />
      </div>

      <Row>
        {/* ==============================================================BMI============================================== */}
        <Col lg="4">
          <Card className="card-chart h-100">
            <CardHeader>
              {/* <h5 className="card-category">BMI Distribution</h5> */}
              <CardTitle tag="h4">BMI Distribution Overview</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="chart-area">
                {bmiDataLoading && <p>Loading BMI distribution data...</p>}
                {bmiDataError && <p>Error: {bmiDataError}</p>}
                {!bmiDataLoading && !bmiDataError && (
                  <canvas id="bmi-distribution-chart" ref={bmiChartRef} />
                )}
              </div>

            </CardBody>
          </Card>
        </Col>

        {/* =======================================================================heartRate======================= */}
        <Col lg="4">
          <Card className="card-chart h-100">
            <CardHeader>
              <CardTitle tag="h4">Heart Rate Distribution in the Last Week</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="chart-area">
                {heartRateDataLoading && <p>Loading heart rate data...</p>}
                {heartRateDataError && <p>Error: {heartRateDataError}</p>}
                {!heartRateDataLoading && !heartRateDataError && (
                  <canvas id="heart-rate-distribution-chart" ref={heartRateChartRef} />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>


        {/* ====================================================bloodPressure============================================       */}
        <Col lg="4">
          <Card className="card-chart h-100">
            <CardHeader>
              <CardTitle tag="h4">Blood Pressure Distribution Overview</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="chart-area" style={{ position: 'relative', width: '100%', height: '100%' }}>
                {/* Add a canvas element for blood pressure chart */}
                <canvas id="blood-pressure-chart" ref={bloodPressureChartRef} style={{ width: '100%', height: '100%' }} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* ====================================================calorie======================================= */}
      <Row>
        <Col lg="4">
          <Card className="card-chart h-100">
            <CardHeader>
              <CardTitle tag="h4">Calories Distribution Overview</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="chart-area">
                {calorieDataLoading && <p>Loading calorie data...</p>}
                {calorieDataError && <p>Error: {calorieDataError}</p>}
                {!calorieDataLoading && !calorieDataError && (
                  <canvas id="calories-distribution-chart" ref={calorieChartRef} />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* ==============================================================sleep return data =========================      */}
        <Col lg="4">
          <Card className="card-chart h-100">
            <CardHeader>
              <CardTitle tag="h4">Sleep Duration in the Last Week</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="chart-area">
                {sleepDataLoading && <p>Loading sleep data...</p>}
                {sleepDataError && <p>Error: {sleepDataError}</p>}
                {!sleepDataLoading && !sleepDataError && (
                  <canvas id="sleep-distribution-chart" ref={sleepChartRef} />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* ========================================water=================================== */}
        <Col lg="4">
          <Card className="card-chart h-100">
            <CardHeader>
              <CardTitle tag="h4">Water Intake in the Last Week</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="chart-area">
                {waterIntakeDataLoading && <p>Loading water intake data...</p>}
                {waterIntakeDataError && <p>Error: {waterIntakeDataError}</p>}
                {!waterIntakeDataLoading && !waterIntakeDataError && (
                  <canvas id="water-intake-distribution-chart" ref={waterIntakeChartRef} />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>


      </Row>
      <Row>

        {/* ==================================================================gendercount================================        */}
        <Col lg="4">
          <Card className="card-chart h-100">
            <CardHeader>
              <CardTitle tag="h4">Gender Count Overview</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="chart-area">
                {genderCountDataLoading && <p>Loading gender count data...</p>}
                {genderCountDataError && <p>Error: {genderCountDataError}</p>}
                {!genderCountDataLoading && !genderCountDataError && (
                  <canvas id="gender-count-chart" ref={genderCountChartRef} />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          <Card className="card-chart h-100">
            <CardHeader>
              <CardTitle tag="h4">Work Level Overview</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="chart-area">
                {workLevelDataLoading && <p>Loading work level data...</p>}
                {workLevelDataError && <p>Error: {workLevelDataError}</p>}
                {!workLevelDataLoading && !workLevelDataError && (
                  <canvas id="work-level-chart" ref={workLevelChartRef} />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* ==========================================================================step========================================      */}
        <Col lg="4">
          <Card className="card-chart h-100">
            <CardHeader>
              <CardTitle tag="h4">Steps in the Last Week</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="chart-area">
                {stepsDataLoading && <p>Loading steps data...</p>}
                {stepsDataError && <p>Error: {stepsDataError}</p>}
                {!stepsDataLoading && !stepsDataError && (
                  <canvas id="steps-distribution-chart" ref={stepsChartRef} />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
      </Row>

    </div>
  );
}

export default Health;
