/**
 * OrderList Component
 * 
 * This component is responsible for displaying a list of orders and providing functionality to filter and update the 
 * delivery status of each order. It fetches the list of orders from an API and allows the user to filter orders 
 * by a selected delivery date. The status of each order can also be updated through a dropdown menu.
 * 
 * - The component uses React's `useState` and `useEffect` hooks to manage the state of orders, loading, error, 
 *   and selected date.
 * - The list of orders is displayed in cards, with each card showing the details of the order, including the 
 *   customer's name, book title, quantity, amount, payment ID, delivery address, delivery date, and current 
 *   delivery status.
 * - Users can filter the displayed orders based on the selected delivery date, and the orders will be updated 
 *   accordingly.
 * - The delivery status of an order can be updated using a dropdown menu, and the change is sent to the API 
 *   to update the backend data.
 * 
 * - `orders`: Holds the array of orders fetched from the API.
 * - `loading`: Tracks whether the data is still loading.
 * - `error`: Holds any error that occurs during the data fetching process.
 * - `selectedDate`: Holds the selected date for filtering orders.
 * 
 * The component uses inline styles for layout and styling, ensuring that the page is responsive and visually 
 * appealing.
 */

import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import { BASE_URL } from '../../components/config/apiConfig';
// State hooks for orders, loading, error, and selected date
const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  // Effect hook to fetch orders when the component mounts
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        // Fetch orders from the API
        const response = await fetch(`${BASE_URL}/dashboard/orders`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setOrders(data.orders); // assuming data contains orders array
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);// Empty dependency array ensures it runs only once when the component mounts
  // Function to filter orders by the selected date
  const filterOrdersByDate = (date) => {
    setSelectedDate(date);
  };
  // Filtered orders based on selected date
  const filteredOrders = selectedDate ? orders.filter(order => new Date(order.deliveryDate).toDateString() === selectedDate.toDateString()) : orders;
  // Function to handle the delivery status change for a specific order
  const handleDeliveryStatusChange = async (orderId, newStatus) => {
    try {
      // Update delivery status via API
      const response = await fetch(`${BASE_URL}/dashboard/orders/updateDeliveryStatus?orderId=${orderId}&newStatus=${newStatus}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to update delivery status');
      }
      // Update the orders list after successful status update
      const updatedOrders = orders.map(order => {
        if (order.orderId === orderId) {
          return { ...order, deliveryStatus: newStatus };
        }
        return order;
      });
      setOrders(updatedOrders);
    } catch (error) {
      console.error('Error updating delivery status:', error);
    }
  };
  // Loading and error state rendering
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  // Custom input component for DatePicker
  const CustomInput = ({ value, onClick }) => (
    <div style={styles.customInput}>
      <input
        type="text"
        value={value}
        onClick={onClick}
        readOnly
        style={styles.dateInput}
      />
      <FaCalendarAlt style={styles.calendarIcon} onClick={onClick} />
    </div>
  );

  return (
    <div style={styles.container}>
      <Sidebar />
      <div style={styles.content}>
        <h2 style={styles.heading}>Orders</h2>
        <div style={styles.datePickerContainer}>
          <label style={styles.label}>Select Date:</label> {/* added label text */}
          <DatePicker
            selected={selectedDate}
            onChange={filterOrdersByDate}
            dateFormat="MM/dd/yyyy"
            customInput={<CustomInput />}
            style={styles.datePicker}
          />
        </div>
        <div style={styles.orderContainer}>
          {filteredOrders.map((order, index) => (
            <div key={order.orderId} style={styles.card}>
              <div style={styles.cardBody}>
                <h3 style={styles.cardTitle}>Order Details</h3>
                <p><span style={styles.label}>Name:</span> <span style={styles.value}>{order.name}</span></p>
                <p><span style={styles.label}>Email:</span> <span style={styles.value}>{order.email}</span></p>
                <p><span style={styles.label}>Book Title:</span> <span style={styles.value}>{order.bookTitle}</span></p>
                <p><span style={styles.label}>Quantity:</span> <span style={styles.value}>{order.quantity}</span></p>
                <p><span style={styles.label}>Amount:</span> <span style={styles.value}>{(order.amount / 100)}</span></p>
                <p><span style={styles.label}>Payment ID:</span> <span style={styles.value}>{order.paymentId}</span></p>
                <p><span style={styles.label}>Delivery Address:</span> <span style={styles.value}>{order.deliveryAddress}</span></p>
                <p><span style={styles.label}>Delivery Date:</span> <span style={styles.value}>{order.deliveryDate}</span></p>
                <div>
                  <label style={styles.label}>Delivery Status:</label>
                  <select value={order.deliveryStatus} onChange={(e) => handleDeliveryStatusChange(order.orderId, e.target.value)} style={styles.statusSelect}>
                    <option value="Pending">Pending</option>
                    <option value="Canceled">Canceled</option>
                    <option value="Delivered">Delivered</option>
                  </select>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
// Inline styles for the components
const styles = {
  container: {
    display: 'flex',
    backgroundColor: '#f0f2f5',
    minHeight: '100vh',
  },
  content: {
    flex: 1,
    padding: '20px',
    marginLeft: '250px', // Adjust according to your sidebar width
  },
  heading: {
    fontSize: '24px',
    marginBottom: '20px',
    color: 'black', // White color for text
    textAlign: 'center', // Center align the text
    backgroundColor: '#e0e0e0', // Dark gray background color
    padding: '10px', // Add padding for better readability
    borderRadius: '5px', // Rounded corners
  },
  datePickerContainer: {
    marginBottom: '20px',
  },
  label: {
    fontWeight: 'bold',
    color: '#333',
  },
  value: {
    color: '#666',
  },
  customInput: {
    display: 'flex',
    alignItems: 'center',
  },
  calendarIcon: {
    marginLeft: '5px',
    cursor: 'pointer',
  },
  datePicker: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '8px',
    marginRight: '5px',
    width: '150px',
  },
  orderContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    // gap: '20px',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    flex: '1 1 300px',
    minWidth: '300px',
  },
  cardTitle: {
    fontSize: '20px',
    marginBottom: '10px',
    color: 'white', // White color for text
    textAlign: 'center', // Center align the text
    backgroundColor: '#666', // Gray background color
    padding: '10px', // Add padding for better readability
    borderRadius: '5px', // Rounded corners
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    // gap: '10px',
  },
  statusSelect: {
    width: '100%',
    padding: '8px',
    marginTop: '5px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
};

export default OrderList;
