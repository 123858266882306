/**
 * Changepassword Component
 * 
 * This component represents a page that allows a user to reset their password.
 * It uses the token passed as a query parameter in the URL to verify the password reset request.
 * The user is required to enter a new password and confirm it to complete the reset process.
 * 
 * The component uses React's `useState` to manage the new password and confirmation password values.
 * Upon form submission, it sends a POST request to the server to update the password, using the token and new password details.
 * 
 * - `handleSubmit`: Handles form submission and sends a request to the backend to reset the password.
 * - On successful password reset, an alert message is shown to the user.
 * - If an error occurs, an error message is displayed.
 * 
 * The page is styled with inline CSS to ensure a responsive and user-friendly design.
 * 
 * - The layout centers the form on the page with a white card containing the password reset form.
 * - The form fields include a new password and a confirmation password, both required.
 * - The reset button changes color on hover and active states for better user interaction.
 */
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
 
function Changepassword() {
    // Extract the "token" parameter from the URL query string
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
   // State variables for storing new password and confirmation password
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
 // Handles form submission for password reset
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch("http://localhost:7073/auth/reset-password", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: new URLSearchParams({
                    token,
                    newPassword,
                    confirmPassword,
                }),
            });

            if (response.ok) {
                alert("Password reset successful!");
            } else {
                alert("Error resetting password");
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="reset-password-container">
            <div className="reset-password-card">
                <h2>Reset Your Password</h2>
                <p>Enter your new password below to reset it.</p>
                <form onSubmit={handleSubmit} className="reset-password-form">
                    <div className="form-group">
                        <label htmlFor="newPassword">New Password:</label>
                        <input
                            id="newPassword"
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            placeholder="Enter new password"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirmPassword">Confirm Password:</label>
                        <input
                            id="confirmPassword"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            placeholder="Confirm new password"
                        />
                    </div>
                    <button type="submit" className="submit-btn">Update Password</button>
                </form>
            </div>
 {/* Inline CSS for component styling */}
            <style jsx>{`
                .reset-password-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                    background: #f4f7fb;  /* Subtle light background */
                    padding: 20px;
                }

                .reset-password-card {
                    background: #fff;
                    border-radius: 10px;
                    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
                    width: 100%;
                    max-width: 450px;
                    padding: 40px;
                    text-align: center;
                    border: 1px solid #e0e4e8; /* Light border for card */
                }

                h2 {
                    font-size: 1.6em;
                    color: #333;
                    margin-bottom: 15px;
                    font-weight: 600;
                }

                p {
                    font-size: 1em;
                    color: #6c7c8a;
                    margin-bottom: 20px;
                }

                .reset-password-form .form-group {
                    margin-bottom: 20px;
                    text-align: left;
                }

                label {
                    display: block;
                    font-size: 0.9em;
                    color: #4a5d6c;
                    margin-bottom: 5px;
                    font-weight: 500;
                }

                input {
                    width: 100%;
                    padding: 12px;
                    font-size: 1.1em;
                    border-radius: 6px;
                    border: 1px solid #ddd;
                    background-color: #f9f9f9;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                    transition: all 0.3s ease;
                }

                input:focus {
                    outline: none;
                    border-color: #007bff;
                    background-color: #fff;
                }

                .submit-btn {
                    width: 100%;
                    padding: 12px;
                    font-size: 1.1em;
                    background-color: #007bff;
                    color: #fff;
                    border: none;
                    border-radius: 6px;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                }

                .submit-btn:hover {
                    background-color: #0056b3;
                }

                .submit-btn:active {
                    background-color: #004085;
                }

                @media (max-width: 480px) {
                    .reset-password-card {
                        width: 90%;
                        padding: 20px;
                    }

                    h2 {
                        font-size: 1.4em;
                    }

                    p {
                        font-size: 0.9em;
                    }

                    .submit-btn {
                        font-size: 1em;
                    }
                }
            `}</style>
        </div>
    );
}

export default Changepassword;
