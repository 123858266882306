// ============Import necessary libraries and components======================================
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import BASE_URL from '../../components/config/apiConfig';
import 'font-awesome/css/font-awesome.min.css';
import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
import "./bookdata.scss";
import Header from '../bookinfo/header';  // Import Header
import Footer from '../bookinfo/footer';
// State variables
const Book = () => {
  const [books, setBooks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [updatedQuantity, setUpdatedQuantity] = useState('');
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAddBookPopup, setShowAddBookPopup] = useState(false);

  useEffect(() => {
    fetchBooks();
  }, []);
  // Function to fetch all books from the backend
  const fetchBooks = async () => {
    try {
      const key = localStorage.getItem('jwtToken');
      const response = await axios.get(`${BASE_URL}/dashboard/get-all-book-admin`, {
        headers: {
          'Content-Type': 'application/json',
          'Auth': `Bearer ${key}`,
        },
      });
      setBooks(response.data);
    } catch (error) {
      console.error('Error fetching book details:', error);
    }
  };
  // Handle click on "Update Quantity" button
  const handleUpdateClick = (book) => {
    setSelectedBook(book);
    setUpdatedQuantity(book.quantity);
    setShowUpdatePopup(true);
  };
  // Function to update book quantity
  const handleUpdate = async () => {
    try {
      const key = localStorage.getItem('jwtToken');
      if (!key) {
        console.error('No JWT token found');
        return;
      }

      const updatedBook = {
        quantity: updatedQuantity,  // Only update quantity, not price
      };

      const response = await axios.put(
        `${BASE_URL}/dashboard/update-book-quantity?bookId=${selectedBook.id}&bookQuantity=${updatedQuantity}`,
        updatedBook, {
        headers: {
          'Authorization': `Bearer ${key}`,
          'Content-Type': 'application/json',
        },
      }
      );

      if (response.status === 200) {
        console.log("Book quantity updated successfully");
        fetchBooks();  // Fetch the books again to refresh the list
        setShowUpdatePopup(false);  // Close the popup
      } else {
        console.error('Error updating book quantity:', response.status);
      }
    } catch (error) {
      console.error('Error updating book:', error);
    }
  };
  // Filter books based on the search query
  const filteredBooks = books.books ? books.books.filter((book) =>
    book && book.title && book.title.toLowerCase().includes(searchQuery.toLowerCase())
  ) : [];

  return (

    <div className="book-page">
      <RedirectComponent />
      <Header />

      <h2 style={{ textAlign: "center" }}>Book Details</h2>

      <input
        type="text"
        placeholder="Search by title"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="book-input"
      />

      <div className="book-container">
        {filteredBooks.map((book) => (
          <div key={book.id} className="book-card">
            <div className="book-details">
              <div className="book-title">{book.title}</div>
              <div className="book-image">
                <img
                  src={`${BASE_URL}${book.imageUrl}`}
                  alt={book.title}
                  className="book-image"
                />
              </div>
              <p>Author: {book.author}</p>
              <p>Year: {book.year}</p>
              <p>Quantity: {book.quantity}</p>
              <p>Price: ₹ {book.price}</p>
              <p>Ratings: {parseFloat(book.ratings).toFixed(1)} <span>&#9733;</span></p>
              <button className="book-button" onClick={() => handleUpdateClick(book)}>Update Quantity</button>
            </div>
          </div>
        ))}
      </div>

      {/* Update Book Popup */}
      {showUpdatePopup && selectedBook && (
        <div className="book-popup">
          <div className="book-popup-content">
            <button className="close-button" onClick={() => setShowUpdatePopup(false)}>
              <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>

            <h2 className="book-popup-header">Update Quantity</h2>

            {errorMessage && <div className="book-error">{errorMessage}</div>}

            <div className="book-update-form">
              <input
                type="number"
                placeholder="Enter new quantity"
                value={updatedQuantity}
                onChange={(e) => setUpdatedQuantity(e.target.value)}
                className="book-update-input"
              />
              <button className="book-button" onClick={handleUpdate}>Save</button>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>

  );
};

export default Book;
