/**
 * App Component
 *
 * This component serves as the main entry point for the application. It defines the routes using react-router-dom,
 * incorporates various pages, and manages the overall application structure.
 *
 * @version 1.0.0
 * @date March 6, 2024
 * @returns {JSX.Element} - Rendered component for the entire application
 */
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import Home from "./pages/home/Home";

import Login from "./pages/login/Login";
import User from "./pages/user/User";
import Visitor from "./pages/visitor/Visitor";
import Statuspage from "./pages/statuspage/Statuspage";
import Health from "./pages/health/Health";
import Bugs from "./pages/bugs/Bugs";
import Contact from "./pages/contact/Contact";
import Bookdata from "./pages/bookdata/Bookdata";
import Book from "./pages/bookdata/Book";
import Orderbook from "./pages/orderbook/Orderbook";
import Recipes from "./pages/recipes/Recipes";
import Bookinfo from "./pages/bookinfo/Bookinfo"
import Accountadmin from "./pages/accountadmin/accountadmin"
import Stock from "./pages/bookinfo/updatestock"
import Changepassword from "./pages/changepassword/changepassword"
import Emailverify from "./pages/emailverify/Emailverify"
import "./style/dark.scss";
import { useContext } from "react";
import Addrawdata from "./pages/addrowdata/Addrowdata";
import Rawdata from "./pages/rowdata/Rowdata";
import { DarkModeContext } from "./context/darkModeContext";
import Privacypolicy from "./pages/privacypolicy/privacypolicy";
import Contactus from "./pages/contactus/Contactus";
import './app.scss';

const routes = [
  {
    path: "/",
    element: <Login />,
    // path: "/",
    // element: <Home />,
  },
  {
    path: "/visitor",
    element: <Visitor />,
  },
  {
    path: "/users",
    element: <User />,
  },
  {
    path: "/user/:ID", // Note the corrected path parameter syntax
    element: <Statuspage />,
  },
  // {
  //   path: "/register",
  //   element: <Register />,
  // },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/health",
    element: <Health />,
  },
  {
    path: "/bugs",
    element: <Bugs />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/bookdata",
    element: <Bookdata />,
  },
  {
    path: "/book",
    element: <Book />,
  },

  // {
  //   path: "/statuspage",
  //   element: <StatusPage />,
  // },
  {
    path: "/statuspage/:userId", // Use uppercase ID if that's your intended parameter
    element: <Statuspage />,
  },
  {
    path: "/addrawdata",
    element: <Addrawdata />,
  },
  {
    path: "/rawdata",
    element: <Rawdata />,
  },
  {
    path: "/orderbook",
    element: <Orderbook />,
  },
  {
    path: "/recipes",
    element: <Recipes />,
  },

  {
    path: "/bookinfo",
    element: <Bookinfo />,
  },
  {
    path: "/accountadmin",
    element: <Accountadmin />,
  },
  {
    path: "/stock",
    element: <Stock />,
  },
  {
    path: "/privacypolicy",
    element: <Privacypolicy />,
  },
  {
    path: "/contactus",
    element: <Contactus />,
  },

  {
    path: "/changepassword/reset-password", // Correctly define the changepassword route
    element: <Changepassword />,
  },


  {
    path: "/emailverify", // Correctly define the changepassword route
    element: <Emailverify />,
  },

];

const router = createBrowserRouter(routes);

function App() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <RouterProvider router={router} />
      {/* {routes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
      </RouterProvider> */}
    </div>
  );
}

export default App;








