/**
 * Footer Component
 * 
 * This component renders the footer section of the website, which contains the copyright information and links to
 * important pages such as "Contact Us" and "Privacy Policy". It is styled to be responsive and fixed at the bottom
 * of the page.
 * 
 * - The footer is styled with a dark background, white text, and centered content. It uses Flexbox to align and
 *   justify the items, ensuring a neat layout on all screen sizes.
 * - The "Contact Us" and "Privacy Policy" links are created using `Link` from `react-router-dom`, which provides
 *   client-side navigation without reloading the page.
 * 
 * - `footerStyle`: Defines the overall styling of the footer, including background color, text color, alignment,
 *   padding, and box shadow.
 * - `linkStyle`: Specifies the styling for the links, including color, text decoration, and margin.
 * 
 * The footer remains at the bottom of the page, with a clean and professional design.
 */

import React from "react";
import { Link } from "react-router-dom"; // Use Link from react-router-dom

const Footer = () => {
  const footerStyle = {
    backgroundColor: "#34495e",
    color: "#fff",
    textAlign: "center",
    padding: "25px 15px",
    minHeight: "100px",
    bottom: "0",
    width: "100%",
    fontSize: "14px",
    boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const linkStyle = {
    color: "#ecf0f1",
    textDecoration: "none",
    margin: "0 10px",
  };

  return (
    <footer style={footerStyle}>
      <p>© 2024 National Institute of Nutrition. All rights reserved.</p>
      <p>
        <Link to="/contactUs" style={linkStyle}>
          Contact Us
        </Link>
        |
        <Link to="/privacypolicy" style={linkStyle}>
          Privacy Policy
        </Link>
      </p>
    </footer>
  );
};

export default Footer;
