

// This component handles user authentication by checking the presence of a JWT token in localStorage.
// If the token is not found, it redirects the user to the login page.


import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectComponent = () => {
  const history = useNavigate();

  useEffect(() => {
    const key = localStorage.getItem('jwtToken');
    if (!key) {
      // Redirect to login page
      history('/');
    }
  }, [history]);

  return null; // This component doesn't render anything
};

export default RedirectComponent;
