/**
 * Bugs Component
 * 
 * This component represents a page that displays a list of bug reports or contact queries.
 * Each report shows details like the contact name, number, email, the queries they submitted,
 * an image related to their issue, feedback message, status (resolved or pending), and a reply button.
 * 
 * The component uses React's `useState` and `useEffect` hooks to manage state and handle data fetching.
 * It fetches contact/query data from the API when the component mounts and displays it in a table.
 * 
 * - `handleReply`: Opens a modal for replying to a specific contact's query.
 * - `handleSaveReply`: Saves the reply and sends it via the API, updating the contact's status to "Resolved".
 * - `downloadImage`: Allows the user to download contact images by clicking on them.
 * - `openReplyPopup` & `closeReplyPopup`: Controls the visibility of the reply modal for each contact.
 * 
 * The page includes a `Sidebar` and a `RedirectComponent` for navigation, along with an error handling mechanism
 * for when the data fails to load.
 */

//===========================================bugs===================================================================================
import React, { useEffect, useState } from 'react';
import BASE_URL from '../../components/config/apiConfig';
import Sidebar from '../../components/sidebar/Sidebar';
import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
import "./bugs.scss";

const Bugs = () => {
  // State variables
  const [contactData, setContactData] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [replyMessage, setReplyMessage] = useState('');
  const [error, setError] = useState(null);
  const [showReplyPopup, setShowReplyPopup] = useState(false);
  const [hoverCloseBtn, setHoverCloseBtn] = useState(false);
  // Fetch contact data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      // Fetch all contact data from the API
      try {
        const response = await fetch(`${BASE_URL}/api/contact-us/all`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch contact data');
        }

        const data = await response.json();
        setContactData(data);
      } catch (error) {
        console.error('Error fetching contact data:', error);
        setError('Error fetching contact data');
      }
    };

    fetchData();
  }, []);// Empty dependency array ensures this runs only once
  // Function to download contact image
  const downloadImage = (imageName) => {
    const imageUrl = `${BASE_URL}/dashboard/contactUs/viewImage/${imageName}`;
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = imageName;
    link.click();
  };
  // Open the reply popup for a specific contact
  const openReplyPopup = (contact) => {
    setSelectedContact(contact);
    setReplyMessage('');
    setShowReplyPopup(true);
  };
  // Close the reply popup
  const closeReplyPopup = () => {
    setSelectedContact(null);
    setReplyMessage('');
    setShowReplyPopup(false);
  };
  // Handle clicking the "Reply" button
  const handleReply = (contact) => {
    openReplyPopup(contact);
  };
  // Save the reply and send it via the API
  const handleSaveReply = async () => {
    try {
      const contactUsId = selectedContact ? selectedContact.id : null;

      if (contactUsId !== null) {
        setContactData((prevData) =>
          prevData.map((contact) =>
            contact.id === selectedContact.id
              ? { ...contact, reply: replyMessage, status: 'Resolved' }
              : contact
          )
        );
        // Send the reply via the API
        await fetch(`${BASE_URL}/dashboard/send-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            contactUsId: contactUsId,
            message: replyMessage,
          }),
        });

        closeReplyPopup();

        alert('Reply sent successfully!');
      } else {
        alert('Error: Contact ID is not available.');
      }
    } catch (error) {
      console.error('Error updating reply message or sending email:', error);
      alert('Error updating reply message or sending email');
    }
  };
  // Inline styles for various elements
  const styles = {
    heading: {
      textAlign: 'center',
    },
    table: {
      width: '100%',
      overflowX: 'auto',
      borderCollapse: 'collapse', // Better table layout
    },
    tableHead: {
      backgroundColor: '#f2f2f2',
    },
    tableCell: {
      padding: '8px',
      border: '1px solid #ddd', // Table cell borders
    },
    contactImage: {
      width: '50px',
      height: '50px',
      objectFit: 'cover',
      cursor: 'pointer',
    },
    modalBackdrop: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalContent: {
      background: '#fff',
      padding: '20px',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      textAlign: 'center',
      maxWidth: '400px',
      width: '80%',
      position: 'fixed', // Change from 'relative' to 'fixed'
      top: '50%', // Center vertically
      left: '50%', // Center horizontally
      transform: 'translate(-50%, -50%)', // Center the modal box
      zIndex: 4,
    },
    popupHeader: {
      background: 'gray',
      color: '#fff',
      padding: '10px',
      borderRadius: '8px 8px 0 0',
      position: 'relative',
    },
    closeBtn: {
      position: 'absolute',
      top: '1px',
      right: '10px',
      cursor: 'pointer',
      fontSize: '20px',
      color: '#fff',
      background: 'gray',
      border: 'none',
      padding: '4px 2px',
      borderRadius: '5px',
      transition: 'background 0.3s ease, color 0.3s ease',
    },
    closeBtnHover: {
      background: '#ff5555',
      color: '#fff',
    },
    replyButton: {
      background: '#4CAF50',
      color: '#fff',
      border: 'none',
      padding: '10px 20px',
      borderRadius: '5px',
      cursor: 'pointer',
      marginRight: '10px',
    },
    hr: {
      backgroundColor: '#ccc',
      height: '2px',
      border: 'none',
      margin: '20px 0',
    },
    menuButton: {
      position: 'fixed',
      top: '20px',
      left: '20px',
      zIndex: 2,
      cursor: 'pointer',
    },
  };

  return (
    <div className="home">
      <RedirectComponent />
      {/* Render menu button only on small screens */}
      <div className="scrollableSidebar">
        <Sidebar />
      </div>
      <div className="contentContainer">
        <h2 style={styles.heading}>BUGS</h2>
        <hr style={styles.hr} />
        {error ? (
          <p>{error}</p>
        ) : (
          <table style={styles.table}>
            <thead style={styles.tableHead}>
              <tr>
                <th style={styles.tableCell}>Contact Name</th>
                <th style={styles.tableCell}>Contact Number</th>
                <th style={styles.tableCell}>Contact Email</th>
                <th style={styles.tableCell}>Contact Queries</th>
                <th style={styles.tableCell}>Contact Image</th>
                <th style={styles.tableCell}>Feedback Message</th>
                <th style={styles.tableCell}>Status</th>
                <th style={styles.tableCell}>Reply</th>
              </tr>
            </thead>
            <tbody>
              {contactData.map((contact) => (
                <tr key={contact.id}>
                  <td style={styles.tableCell}>{contact.name}</td>
                  <td style={styles.tableCell}>{contact.number}</td>
                  <td style={styles.tableCell}>{contact.email}</td>
                  <td style={styles.tableCell}>{contact.queries}</td>
                  <td style={styles.tableCell}>
                    {contact.imageData && (
                      <img
                        src={`${BASE_URL}/dashboard/contactUs/viewImage/${contact.imageData}`}
                        alt="Contact Image"
                        style={styles.contactImage}
                        onClick={() => downloadImage(contact.imageData)}
                      />
                    )}
                  </td>
                  <td style={styles.tableCell}>{contact.feedbackMessage}</td>
                  <td style={{ ...styles.tableCell, color: contact.status ? 'green' : 'red' }}>
                    {contact.status ? 'Resolved' : 'Pending'}
                  </td>
                  <td style={styles.tableCell}>
                    <button style={styles.replyButton} onClick={() => handleReply(contact)}>Reply</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {showReplyPopup && selectedContact && (
          <div>
            {/* Modal backdrop */}
            <div style={styles.modalBackdrop} onClick={closeReplyPopup}></div>
            <div style={styles.modalContent}>
              <div style={styles.popupHeader}>
                {/* Reply text area */}
                <h5>Reply to {selectedContact.name}'s query</h5>
                <button
                  style={{
                    ...styles.closeBtn,
                    ...(hoverCloseBtn ? styles.closeBtnHover : {}),
                  }}
                  onMouseEnter={() => setHoverCloseBtn(true)}
                  onMouseLeave={() => setHoverCloseBtn(false)}
                  onClick={closeReplyPopup}
                >
                  &times;
                </button>
              </div>
              <textarea
                rows="5"
                cols="40"
                value={replyMessage}
                onChange={(e) => setReplyMessage(e.target.value)}
              />
              {/* Save reply button */}
              <button
                style={styles.replyButton}
                onClick={handleSaveReply}
              >
                Send Reply
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Bugs;
