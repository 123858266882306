/**
 
 * Purpose:
 * This component facilitates adding raw food nutrient data through a user-friendly form. 
 * It manages input state, validates data, and submits it to the backend API. The component 
 * also provides real-time feedback on submission status and supports navigation to the 
 * previous page.
 *
 * Key Features:
 * - **Dynamic Form Rendering**: Automatically generates form fields based on the `formData` state.
 * - **Input Validation**: Ensures correct data types by parsing numeric fields.
 * - **API Integration**: Sends a POST request with formatted data to the backend API.
 * - **Error Handling**: Displays error messages for failed API calls using `react-toastify`.
 * - **User Feedback**: Notifies users of successful or failed submissions.
 * - **Responsive Design**: Styled using inline styles to create an accessible and visually appealing form.
 * - **Navigation**: Includes a back button to allow users to return to the previous page.
 */


// ============Import necessary libraries and components======================================

import React, { useState } from 'react';
import { useSubmit } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/sidebar/Sidebar';
import BASE_URL from '../../components/config/apiConfig';
// Tesing component for adding raw food nutrient data
function Tesing() {
  // For navigation (if needed in future enhancements)
  const history = useSubmit();
  //  State to manage form data inputs
  const [formData, setFormData] = useState({
    source: '',
    food_code: '',
    food: '',
    typesoffood: '',
    category: '',
    protein: '',
    total_fat: '',
    total_dietary_fibre: '',
    carbohydrate: '',
    energy_kcal: '',
    thiamine: '',
    riboflavin_b2: '',
    niacin: '',
    total_b6: '',
    total_folate_b9: '',
    vit_C: '',
    vit_A: '',
    Iron_fe: '',
    Zinc_zn: '',
    sodium_na: '',
    calcium_ca: '',
    magnesium: ''
  });
  // Labels for form fields
  const labels = {
    food: 'Food Name',
    food_code: 'Food Code',
    category: 'Group',
    source: 'Source',
    typesoffood: 'Type of Food',
    protein: 'Protein',
    total_fat: 'Total Fat',
    total_dietary_fibre: 'Total Dietary Fibre',
    carbohydrate: 'Carbohydrate',
    energy_kcal: 'Energy (kcal)',
    thiamine: 'Thiamine (B1)',
    riboflavin_b2: 'Riboflavin (B2)',
    niacin: 'Niacin (B3)',
    total_b6: 'Total (B6)',
    total_folate_b9: 'Total Folate (B9)',
    vit_C: 'Vitamin (C)',
    vit_A: 'Vitamin (A)',
    Iron_fe: 'Iron (Fe)',
    Zinc_zn: 'Zinc (Zn)',
    sodium_na: 'Sodium (Na)',
    calcium_ca: 'Calcium (Ca)',
    magnesium: 'Magnesium'
  };
  // Handles form input changes 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  // Handles form submission 
  const handleSubmit = (e) => {
    e.preventDefault();
    // Format the data to match API's expected structure
    const formattedData = {
      food: formData.food,
      foodCode: formData.food_code,
      category: formData.category,
      source: formData.source,
      typesOfFood: formData.typesoffood,
      energy: parseFloat(formData.energy_kcal),
      protein: parseFloat(formData.protein),
      totalFat: parseFloat(formData.total_fat),
      totalDietaryFibre: parseFloat(formData.total_dietary_fibre),
      carbohydrate: parseFloat(formData.carbohydrate),
      thiamineB1: parseFloat(formData.thiamine),
      riboflavinB2: parseFloat(formData.riboflavin_b2),
      niacinB3: parseFloat(formData.niacin),
      vitB6: parseFloat(formData.total_b6),
      totalFolatesB9: parseFloat(formData.total_folate_b9),
      vitC: parseFloat(formData.vit_C),
      vitA: parseFloat(formData.vit_A),
      iron: parseFloat(formData.Iron_fe),
      zinc: parseFloat(formData.Zinc_zn),
      sodium: parseFloat(formData.sodium_na),
      calcium: parseFloat(formData.calcium_ca),
      magnesium: parseFloat(formData.magnesium)
    };
    // Make a POST request to save the data
    fetch(`${BASE_URL}/dashboard/add-row-food-nutrients`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formattedData)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Notify success and reset form 
        toast.success('Data added successfully');
        setFormData({
          source: '',
          food_code: '',
          food: '',
          typesoffood: '',
          category: '',
          protein: '',
          total_fat: '',
          total_dietary_fibre: '',
          carbohydrate: '',
          energy_kcal: '',
          thiamine: '',
          riboflavin_b2: '',
          niacin: '',
          total_b6: '',
          total_folate_b9: '',
          vit_C: '',
          vit_A: '',
          Iron_fe: '',
          Zinc_zn: '',
          sodium_na: '',
          calcium_ca: '',
          magnesium: ''
        });
      })
      .catch(error => {
        console.error('There was a problem with your fetch operation:', error);
        toast.error('Error adding data');
      });
  };
  // Handles the back button to navigate to the previous page 
  const handleBack = () => {
    window.history.back(); // Go back to the previous page
  };
  // =======Render the component===========
  return (
    <div style={styles.container}>
      <Sidebar />
      <div style={styles.formContainer}>
        <form onSubmit={handleSubmit} style={styles.form}>
          <h2 style={styles.heading}>Enter Raw Ingredient Details</h2>
          {Object.keys(formData).map((field, index) => (
            <div key={index} style={styles.formGroup}>
              <label style={styles.label}>{labels[field]}</label>
              {field === 'typesoffood' ? (
                <select
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  style={styles.input}
                >
                  <option value="vegetarian">Vegetarian</option>
                  <option value="non-vegetarian">Non-Vegetarian</option>
                </select>
              ) : (
                <input
                  type="text"
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  style={styles.input}
                />
              )}
            </div>
          ))}
          <div style={styles.buttonContainer}>
            <button type="button" onClick={handleBack} style={styles.buttonBack}>
              Back
            </button>
            <button type="submit" style={styles.button}>Submit</button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
// Inline styles for the component
const styles = {
  container: {
    display: 'flex',
  },
  formContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    width: '100%',
  },
  heading: {
    marginBottom: '20px',
    color: '#333',
    textAlign: 'center'
  },
  formGroup: {
    marginBottom: '20px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#555',
  },
  input: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontSize: '16px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    fontSize: '16px',
  },
};

export default Tesing;

