/**
 * Navbar Component
 *
 * Purpose:
 * This component renders a responsive navbar for the application. It provides users with functionality for toggling dark mode, viewing notifications and messages, searching content, and logging out. The component integrates with the backend to fetch notifications and messages, handles user interaction, and updates the UI dynamically based on user actions.
 *
 * Key Features:
 * - **Dark Mode Toggle**: A switch to toggle between light and dark mode, utilizing the context API.
 * - **Notification and Message Management**: Displays notifications and messages, with counters showing unread notifications and messages.
 * - **Search Functionality**: Allows users to search through content with a search bar integrated into the navbar.
 * - **Logout**: Provides a logout button that clears the JWT token from local storage and redirects the user to the home page.
 * - **Popup Notifications**: When clicked, a popup shows a list of notifications that were fetched from the backend.
 * - **Message Box**: Displays a list of messages in a modal-like popup, which users can close.
 * - **Event Listeners**: Uses `useEffect` to add and clean up event listeners for window resizing and document clicks.
 * - **Styling**: The component uses inline styles for layout, responsiveness, and accessibility, with an emphasis on clarity and user experience.
 *
 * Expected Behavior:
 * - **Notifications**: When the notification icon is clicked, a list of notifications is displayed. Users can click on individual notifications to view details.
 * - **Messages**: When the message icon is clicked, a list of messages is displayed, showing the message timestamp and content.
 * - **Search**: The search input allows users to filter content dynamically (the search functionality can be further implemented).
 * - **Responsive Design**: The navbar adjusts its layout based on screen size, ensuring usability on both desktop and mobile.
 *
 * Dependencies:
 * - `@mui/material` for the `Switch` component.
 * - `@mui/icons-material` for the notification and message icons.
 * - `react-router-dom` for navigation.
 * - A custom context (`DarkModeContext`) for dark mode toggle.
 * - A backend API for fetching notification and message data.
 */




import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Switch } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { DarkModeContext } from "../../context/darkModeContext";
import BASE_URL from '../config/apiConfig';
const Navbar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const history = useNavigate();
  const notificationRef = useRef(null);
  const messageRef = useRef(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [messageData, setMessageData] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    fetchNotificationData();
    fetchMessageData();
    window.addEventListener("resize", handleWindowResize);
    document.addEventListener("click", handleDocumentClick);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleWindowResize = () => {
    //================ Handle window resize logic here==========================
  };

  useEffect(() => {
    const notificationCount = notificationData.length;
    const notificationCounter = document.getElementById("notificationCounter");
    if (notificationCounter) {
      notificationCounter.textContent = notificationCount;
    }
  }, [notificationData]);

  const fetchNotificationData = async () => {
    try {
      const key = localStorage.getItem("jwtToken");
      const response = await fetch(`${BASE_URL}/api/contact-us/all`, {
        headers: {
          "Content-Type": "application/json",
          Auth: `Bearer ${key}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setNotificationData(data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching notification data:", error);
    }
  };
  //=====================================fetch MessageData from api ============================================================
  const fetchMessageData = async () => {
    try {
      const key = localStorage.getItem("jwtToken");
      const response = await fetch(`${BASE_URL}/dashboard/missingRowFoods`, {
        headers: {
          "Content-Type": "application/json",
          Auth: `Bearer ${key}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setMessageData(data.missingRowFoods);
        setMessageCount(data.totalCount);
      } else {
        throw new Error("Failed to fetch message data");
      }
    } catch (error) {
      console.error("Error fetching message data:", error);
    }
  };
  //========================== logout function =====================
  const handleLogout = () => {
    // Remove the JWT token from localStorage to log the user out
    localStorage.removeItem("jwtToken");
    // Redirect the user to the home page after logout
    history("/");
  };
  //========================== Notification Popup =====================
  const toggleNotificationPopup = () => {
    // Toggle the state of the notification popup (show or hide)
    setShowNotification(!showNotification);
  };
  //========================== Message Box ===========================
  const handleMessageClick = () => {
    // Show the message box when a user clicks on a message
    setShowMessageBox(true);
  };

  // Close the message box when the user decides to close it
  const handleCloseMessageBox = () => {
    setShowMessageBox(false);
  };
  //========================== Notification Close =======================
  const handleCloseNotification = () => {
    // Reset the selected notification to null when closing
    setSelectedNotification(null);
    // Hide the notification popup
    setShowNotification(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    // You can add search functionality here
  };

  const handleNotificationClick = async (notificationId) => {
    // Handle notification click logic
  };
  //============handle document link========================
  const handleDocumentClick = (event) => {
    if (
      !notificationRef.current.contains(event.target) &&
      !messageRef.current.contains(event.target)
    ) {
      setShowNotification(false);
      setShowMessageBox(false);
    }
  };

  return (
    <div style={styles.navbarContainer}>
      <div style={styles.leftItems}>
        <Switch
          className="icon"
          style={styles.switch}
          onClick={() => dispatch({ type: "TOGGLE" })}
        />
        <div
          style={styles.notificationIcon}
          ref={notificationRef}
          onClick={toggleNotificationPopup}
        >
          <div style={styles.counter} id="notificationCounter">
            {notificationData.length}
          </div>
          <NotificationsActiveOutlinedIcon style={styles.icon} />
        </div>
        <div style={styles.messageIcon} ref={messageRef} onClick={handleMessageClick}>
          <MessageIcon style={styles.icon} />
          <span style={styles.messageCount}>{messageCount}</span>
        </div>
      </div>
      <div style={styles.rightItems}>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={styles.searchInput}
        />
        <LogoutIcon style={styles.icon} onClick={handleLogout} />
      </div>
      {(showNotification || selectedNotification) && (
        <div className="notificationPopupContainer" style={styles.notificationPopupContainer}>
          <div className="notificationBox" style={styles.notificationBox}>
            <h3>Notifications</h3>
            <ul style={styles.notificationList}>
              {notificationData.map((notification, index) => (
                <li key={index}>
                  <Link to="/bugs" style={{ textDecoration: "none", color: "inherit" }}>
                    <strong>Its Query From:</strong> {notification.email}
                    <br />
                  </Link>
                </li>
              ))}
            </ul>
            <button onClick={handleCloseNotification}>Close</button>
          </div>
        </div>
      )}
      {showMessageBox && (
        <div className="messageBoxContainer" style={styles.messageBoxContainer}>
          <div className="messageBox" style={styles.messageBox}>
            <h3>Messages</h3>
            <ul style={styles.messageList}>
              {messageData.map((message, index) => (
                // <li
                //   key={`message-${index}`}
                //   style={styles.messageListItem}
                //   onMouseEnter={() => setIsHovered(true)}
                //   onMouseLeave={() => setIsHovered(false)}
                //   style={isHovered ? styles.messageListItemHover : null}
                // >
                <li
                  key={`message-${index}`}
                  style={isHovered ? { ...styles.messageListItem, ...styles.messageListItemHover } : styles.messageListItem}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <p style={styles.messageTimestamp}>{message.timestamp}</p>
                  <p>{message.messingMessage}</p>
                </li>
              ))}
            </ul>
            <button onClick={handleCloseMessageBox}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  navbarContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    //background: "#333",
    // background: "skyblue",
    background: "rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1))",
    color: "#fff",
    marginBottom: "20px",
  },
  leftItems: {
    display: "flex",
    alignItems: "center",
  },
  rightItems: {
    display: "flex",
    alignItems: "center",
  },
  switch: {
    marginRight: "10px",
  },
  notificationIcon: {
    position: "relative",
    marginRight: "10px",
  },
  counter: {
    backgroundColor: "red",
    color: "white",
    borderRadius: "50%",
    padding: "4px 8px",
    position: "absolute",
    top: "-12px",
    right: "-9px",
    fontSize: "0.65rem",
    zIndex: "2",
  },
  messageIcon: {
    position: "relative",
    marginRight: "10px",
  },
  messageCount: {
    backgroundColor: "red",
    color: "white",
    borderRadius: "50%",
    padding: "4px 8px",
    position: "absolute",
    top: "-13px",
    right: "-9px",
    fontSize: "0.65rem",
    zIndex: "2",
  },
  searchInput: {
    width: "200px",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "16px",
    boxSizing: "border-box",
    marginRight: "10px",
  },
  notificationPopupContainer: {
    position: "absolute",
    top: "50px",
    right: "20px",
    zIndex: "999",
    // maxHeight: "20vh",
    overflowY: "auto",
    background: "#fff",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    borderRadius: "5px",
    padding: "20px",
  },
  notificationBox: {
    maxWidth: "300px",
    background: "#fff", // Add a white background
    color: "#333", // Darken the text color for better visibility
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Add a subtle box shadow
  },

  messageBox: {
    maxWidth: "300px",
    background: "#fff", // Add a white background
    color: "#333", // Darken the text color for better visibility
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Add a subtle box shadow
  },
  notificationList: {
    listStyleType: "none",
    padding: "0",
    margin: "0",
  },
  messageBoxContainer: {
    position: "absolute",
    top: "50px",
    right: "20px",
    zIndex: "999",
    maxHeight: "70vh",
    overflowY: "auto",
    background: "#fff",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    borderRadius: "5px",
    padding: "20px",
  },

  messageList: {
    listStyleType: "none",
    padding: "0",
    margin: "0",
  },
  messageListItem: {
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    cursor: "pointer",
  },
  messageTimestamp: {
    margin: "0",
    color: "#666",
    fontSize: "0.8rem",
    marginBottom: "5px",
  },
  messageListItemHover: {
    backgroundColor: "#f0f0f0",
  },
};

export default Navbar;
