

// import React, { useState, useEffect } from 'react';
// import BASE_URL from '../../components/config/apiConfig';
// import { useNavigate } from 'react-router-dom';
// import { FaEnvelope, FaLock, FaUserShield, FaEye, FaEyeSlash } from 'react-icons/fa';
// import Footer from "../bookinfo/footer";
// const Login = () => {
//   //state 
//   const [showPassword, setShowPassword] = useState(false);
//   const [email, setEmail] = useState(localStorage.getItem('savedEmail') || '');
//   const [password, setPassword] = useState(localStorage.getItem('savedPassword') || '');
//   const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
//   const [role, setRole] = useState('admin');
//   const [errorMessage, setErrorMessage] = useState('');


//   const history = useNavigate();
//   const navigate = useNavigate();
//   useEffect(() => {
//     const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
//     setRememberMe(rememberMeValue);
//   }, []);

//   const getJwtToken = () => {
//     const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
//     return tokenCookie ? tokenCookie.split('=')[1] : null;
//   };

//   const handleEmailChange = (event) => {
//     setEmail(event.target.value);
//   };

//   const handlePasswordChange = (event) => {
//     setPassword(event.target.value);
//   };

//   const handleRememberMeChange = (event) => {
//     setRememberMe(event.target.checked);
//   };

//   const handleRoleChange = (event) => {
//     setRole(event.target.value); // Set the role value from the dropdown
//   };
//   const togglePasswordVisibility = () => setShowPassword(!showPassword);
  
//   const handleSignIn = async () => {
//     try {
//       const apiUrl = role === 'admin'
//         ? `${BASE_URL}/dashboard/user-login`
//         : `${BASE_URL}/dashboard/book-publication-user-login`;

//       const response = await fetch(apiUrl, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email, password }),
//       });

//       if (!response.ok) {
//         throw new Error('Invalid email or password');
//       }

//       const data = await response.json();
//       localStorage.setItem('jwtToken', data.jwt);

//       if (rememberMe) {
//         localStorage.setItem('savedEmail', email);
//         localStorage.setItem('savedPassword', password);
//       } else {
//         localStorage.removeItem('savedEmail');
//         localStorage.removeItem('savedPassword');
//       }

//       if (role === 'admin') {
//         history('/home');
//       } else if (role === 'publicationAdmin') {
//         history('/bookinfo');
//       }
//     } catch (error) {
//       console.error('Error:', error.message);
//       setErrorMessage(error.message); // Set the error message
//       setTimeout(() => setErrorMessage(''), 4000); // Hide error after 4 seconds
//     }
//   };
import React, { useState, useEffect } from 'react';
import BASE_URL from '../../components/config/apiConfig';
import { useNavigate } from 'react-router-dom';
import { FaEnvelope, FaLock, FaUserShield, FaEye, FaEyeSlash } from 'react-icons/fa';
import Footer from "../bookinfo/footer";

const Login = () => {
  // State
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem('savedEmail') || '');
  const [password, setPassword] = useState(localStorage.getItem('savedPassword') || '');
  const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
  const [role, setRole] = useState('admin');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
    setRememberMe(rememberMeValue);
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value); // Set the role value from the dropdown
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleSignIn = async () => {
    try {
      let apiUrl;
      if (role === 'admin') {
        apiUrl = `${BASE_URL}/dashboard/user-login`;
      } else if (role === 'publicationAdmin') {
        apiUrl = `${BASE_URL}/dashboard/book-publication-user-login`;
      } else if (role === 'accountAdmin') {
        apiUrl = `${BASE_URL}/dashboard/book-account-user-login`;
      }

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Invalid email or password');
      }

      const data = await response.json();
      localStorage.setItem('jwtToken', data.jwt);

      if (rememberMe) {
        localStorage.setItem('savedEmail', email);
        localStorage.setItem('savedPassword', password);
      } else {
        localStorage.removeItem('savedEmail');
        localStorage.removeItem('savedPassword');
      }

      if (role === 'admin') {
        navigate('/home');
      } else if (role === 'publicationAdmin') {
        navigate('/bookinfo');
      } else if (role === 'accountAdmin') {
        navigate('/accountadmin');
      }
    } catch (error) {
      console.error('Error:', error.message);
      setErrorMessage(error.message); // Set the error message
      setTimeout(() => setErrorMessage(''), 4000); // Hide error after 4 seconds
    }
  };
  // Internal CSS
  const internalStyles = `
    .header {
      position: relative;
      padding: 5px;
      border-radius: 90px;
      margin-bottom: 20px;
      margin-top: 18px;
      background-color: #F0F8FF;
    }

    .navbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 90px;
    }

    .logo {
      color: black;
      margin-left: 20px;
      text-decoration: none;
      border-radius: 90px;
    }

    .iosDownloadContainer {
      padding: 1px 5px;
      border-radius: 90px;
      margin-left: 10px;
      cursor: pointer;
    }

    .formLabel {
      font-weight: bold;
    }

    .formIcon {
      margin-right: 10px;
      margin-bottom: 33px;
    }

    .formInput {
      width: 100%;
      padding: 10px;
      margin-bottom: 15px;
      border-radius: 4px;
      border: 1px solid #ccc;
    }

    .footer {
      background-color: #F0F8FF;
      border-radius: 00px;
      margin-top: 10px;
      padding-bottom: 0px;
    }
    .errorMessage {
      position: fixed;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ff4d4d;
      color: white;
      padding: 10px;
      border-radius: 5px;
      font-size: 14px;
      z-index: 9999;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .footerContainer {
      margin-bottom: 0;
    }
  `;

  return (
    <div className="container-fluid" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', paddingLeft: 0 }}>
      <style>{internalStyles}</style>
      {/* Error Message Popup */}
      {errorMessage && (
        <div className="errorMessage">
          {errorMessage}
        </div>
      )}
      {/* Header */}
      <div className="header">
        <div className="navbar navbar-expand-lg navbar-light">
          <h3 className="navbar-brand" href="#">
            Nutrify India Now 2.0
          </h3>
     

          <div className="navbar-nav ml-auto">
            <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
              <a
                className="nav-item nav-link"
                href="https://play.google.com/store/search?q=nutrify+india+now+2.0&c=apps&hl=en"
                style={{ color: '#fff', textDecoration: 'none' }}
                target="_blank" // Opens the link in a new tab
                rel="noopener noreferrer" // Security best practice
              >
                Download for Android
              </a>
            </div>
            <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
              <a
                className="nav-item nav-link"
                href="https://play.google.com/store/search?q=nutrify+india+now+2.0&c=apps&hl=en"
                style={{ color: '#fff', textDecoration: 'none' }}
                target="_blank" // Opens the link in a new tab
                rel="noopener noreferrer" // Security best practice
              >
                Download for iOS
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Login Page */}
      <div className="row">
        <div className="col-md-6 col-12 p-5">
          <div className="d-flex flex-column justify-content-center h-100">
            <div>
              <h1 className="font-weight-bolder text-info text-gradient">Welcome back</h1>
              <p className="mb-4">Enter your email and password to sign in</p>

              {/* Role Selection Dropdown */}
              <div className="mb-3">
                <label htmlFor="roleSelect" className="formLabel" style={{ marginLeft: "23px" }}>
                  Select Your Role
                </label>
                <div className="d-flex align-items-center">
                  <FaUserShield className="mr-2 formIcon" style={{ color: '#000000', fontSize: '1.2rem' }} />
                  <div style={{ position: 'relative', width: '100%' }}>
                    <select
                      id="roleSelect"
                      className="form-control formInput"
                      style={{
                        width: '100%',
                        padding: '10px',
                        paddingRight: '40px', // Extra space for the dropdown indicator
                        marginBottom: '15px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                        appearance: 'none', // Remove default arrow for custom styling
                        background: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black"><path d="M7 10l5 5 5-5H7z"/></svg>') no-repeat right 10px center`,
                        backgroundSize: '16px 16px', // Adjust size of the arrow
                      }}
                      value={role}
                      onChange={handleRoleChange}
                    >
                      <option value="admin">Admin Login</option>
                      <option value="publicationAdmin">Publication Admin</option>
                         <option value="accountAdmin">Account Admin</option>
                    </select>
                  </div>
                </div>
              </div>
   
              <div className="mb-3">
                {/* Email Input */}
                <div className="d-flex align-items-center mb-3">
                  <FaEnvelope className="mr-2 formIcon" />
                  <label className="formLabel"></label>
                  <input
                    type="email"
                    className="form-control formInput"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    autoComplete="username"
                  />
                </div>

                {/* Password Input with Show/Hide */}
                <div className="d-flex align-items-center position-relative">
                  <FaLock className="mr-2 formIcon" />
                  <label className="formLabel"></label>
                  <input
                    type={showPassword ? 'text' : 'password'} // Toggle input type
                    className="form-control formInput"
                    placeholder="Enter your password"
                    value={password}
                    onChange={handlePasswordChange}
                    autoComplete="current-password"
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    style={{
                      position: 'absolute',
                      right: '10px',
                      cursor: 'pointer',
                      color: '#555',
                    }}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Toggle Icon */}
                  </span>
                </div>
              </div>

              <div className="form-check form-switch mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
                <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
              </div>
              <button
                type="button"
                className="btn bg-gradient-info w-100 mb-3"
                onClick={handleSignIn}
                style={{ backgroundColor: 'rgb(23, 162, 184)' }}
              >
                Sign in
              </button>
           
            </div>

          </div>
        </div>

        <div className="col-md-6 d-none d-md-block backgroundContainer">
          <img
            src={`${process.env.PUBLIC_URL}/assets/curved6.jpg`}
            alt="background"
            className="img-fluid"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderTopLeftRadius: '500px', // Slightly cut shape on the left side
              borderBottomLeftRadius: '0px',
            }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
