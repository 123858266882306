// // /**
// //  * BookTableWithAPI Component
// //  *
// //  * Purpose:
// //  * The `BookTableWithAPI` component is responsible for displaying a paginated table of orders. 
// //  * It allows users (admins or other authorized roles) to:
// //  * - View a list of orders with details such as user name, book title, quantity, contact info, and order status.
// //  * - Filter orders by username using a search bar.
// //  * - Edit the delivery status, tracking ID, and dispatch date of individual orders.
// //  * - Display and update order details dynamically.
// //  * - Show notifications for successful or failed updates.
// //  * - Navigate through pages of orders with pagination controls.
// //  * - Interact with a popup to view additional details about each order.
// //  * 
// //  * Functionality:
// //  * - Fetches order data from the backend using an authenticated API request.
// //  * - Supports pagination to display orders in chunks.
// //  * - Allows updating specific order details via form fields (dispatch date, tracking ID, delivery status).
// //  * - Displays a popup with additional information when a user clicks on certain fields like order details or shipping address.
// //  * - Displays notifications for the outcome of any updates to order data (success or failure).
// //  * - Utilizes DatePicker and input fields for updating order-related details.
// //  *
// //  * Dependencies:
// //  * - React, Axios, React DatePicker, React Icons (for calendar), and custom components like `Header`, `Footer`, and `RedirectComponent`.
// //  */

// // // export default BookTableWithAPI;





// // import React, { useState, useEffect } from "react";
// // import axios from "axios";
// // import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
// // import DatePicker from "react-datepicker"; // DatePicker component
// // import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
// // import "./bookinfo.scss"; // External styles
// // import Header from "./header"; // Header component
// // import Footer from "./footer"; // Footer component
// // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL
// // import { format } from 'date-fns';
// // import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
// // const BookTableWithAPI = () => {
// //   const [tableData, setTableData] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [popupContent, setPopupContent] = useState(""); // Popup content
// //   const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
// //   const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
// //   const [searchQuery, setSearchQuery] = useState(""); // Search query for filtering rows
// //   const [rowData, setRowData] = useState({}); // Store row data
// //   const rowsPerPage = 9; // Number of rows per page
// //   const [notification, setNotification] = useState(""); // Message to show
// //   const [notificationType, setNotificationType] = useState(""); // 'success' or 'error'

// //   // State to control calendar visibility
// //   const [openCalendarId, setOpenCalendarId] = useState(null); // Track the row where calendar is open

// //   useEffect(() => {
// //     fetchData();
// //   }, []);

// //   const fetchData = async () => {
// //     try {
// //       setLoading(true);
// //       const key = localStorage.getItem("jwtToken"); // Retrieve JWT token from local storage

// //       const response = await axios.get(`${BASE_URL}/dashboard/orders`, {
// //         headers: {
// //           Auth: `Bearer ${key}`, // Add the Authorization header with the JWT token
// //         },
// //       });

// //       if (Array.isArray(response.data)) {
// //         setTableData(response.data); // Set fetched data
// //         const initialRowData = response.data.reduce((acc, row) => {
// //           acc[row.orderId] = {
// //             dispatchDate: row.dispatchDate || "",
// //             trackingId: row.trackingId || "",
// //             deliveryStatus: row.deliveryStatus || "",
// //           };
// //           return acc;
// //         }, {});
// //         setRowData(initialRowData);
// //       } else {
// //         console.error("Invalid data format received:", response.data);
// //       }
// //     } catch (error) {
// //       console.error("Error fetching data:", error);
// //       alert("Failed to fetch data from the server.");
// //     } finally {
// //       setLoading(false);
// //     }
// //   };

// //   const handleShowPopup = (content) => {
// //     setPopupContent(content);
// //     setPopupVisible(true);
// //   };

// //   const closePopup = () => {
// //     setPopupVisible(false);
// //   };

// //   const handleInputChange = (orderId, field, value) => {
// //     setRowData((prev) => ({
// //       ...prev,
// //       [orderId]: {
// //         ...prev[orderId],
// //         [field]: value,
// //       },
// //     }));
// //   };

//   // const handleUpdateRow = async (orderId) => {
//   //   const updatedRow = rowData[orderId]; // Get the updated row data
//   //   const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage

//   //   try {
//   //     // Send PUT request to update row data
//   //     const response = await axios.put(
//   //       `${BASE_URL}/dashboard/update-orders?orderId=${orderId}`,
//   //       {
//   //         deliveryStatus: updatedRow.deliveryStatus,
//   //         trackingId: updatedRow.trackingId,
//   //         dispatchDate: updatedRow.dispatchDate,
//   //       },
//   //       {
//   //         headers: {
//   //           Auth: `Bearer ${key}`,
//   //         },
//   //       }
//   //     );

//   //     setNotification("Row updated successfully!");
//   //     setNotificationType("success");

//   //     // Hide the notification after 5 seconds
//   //     setTimeout(() => {
//   //       setNotification("");
//   //     }, 5000);

//   //     fetchData(); // Refresh table data after update
//   //   } catch (error) {
//   //     console.error("Error updating row:", error);
//   //     setNotification("Failed to update the row. Please try again.");
//   //     setNotificationType("error");

//   //     // Hide the notification after 5 seconds
//   //     setTimeout(() => {
//   //       setNotification("");
//   //     }, 10000);
//   //   }
//   // };

// //   const indexOfLastRow = currentPage * rowsPerPage;
// //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;


// //   const filteredData = tableData.filter((row) =>
// //     row.name?.toLowerCase().includes(searchQuery.toLowerCase())
// //   );

// //   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

// //   const totalPages = Math.ceil(filteredData.length / rowsPerPage);

// //   const handlePageChange = (pageNumber) => {
// //     setCurrentPage(pageNumber);
// //   };

// //   // Padding empty rows
// //   const paddedRows = [...currentRows];
// //   while (paddedRows.length < rowsPerPage) {
// //     paddedRows.push({}); 
// //   }

// //   return (
// //     <div id="root">
// //       <div className="page-container">
// //         <RedirectComponent />
// //         <Header />

// //         <main>
// //           {notification && (
// //             <div className={`notification ${notificationType}`}>
// //               {notification}
// //             </div>
// //           )}

       
// //           <div className="search-bar-container" style={{ marginLeft: '20px', marginTop: '5px' }}>
// //             <input
// //               type="text"
// //               className="search-bar"
// //               placeholder="Search by username"
// //               value={searchQuery}
// //               onChange={(e) => setSearchQuery(e.target.value)}
// //             />
// //           </div>
// //           <div className="table-container">
// //             {loading ? (
// //               <div className="loading">Loading...</div>
// //             ) : (
// //               <div className="table-wrapper">
// //                 <table className="book-table">
// //                   <thead>
// //                     <tr>
// //                       <th>S.No</th>
// //                       <th>User Name</th>
// //                       <th>Book Title</th>
// //                       <th>Qty</th>
// //                       <th>Contact No.</th>
// //                       <th>Email ID</th>
// //                       <th>Order Date</th>
// //                       <th>Shipping Add.</th>
// //                       <th>Dispatch Date</th>
// //                       <th>Tracking ID</th>
// //                       <th>Delivery Status</th>
// //                       <th>Actions</th>
// //                     </tr>
// //                   </thead>
// //                   <tbody>
// //                     {paddedRows.map((row, index) => (
// //                       <tr key={index}>
// //                         <td>{row.orderId ? indexOfFirstRow + index + 1 : ""}</td>
// //                         <td>
// //                           {row.name ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.name)}
// //                             >
// //                               {row.name.length > 15
// //                                 ? row.name.substring(0, 15) + "..."
// //                                 : row.name}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.publicationTitle ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.publicationTitle)}
// //                             >
// //                               {row.publicationTitle.length > 15
// //                                 ? row.publicationTitle.substring(0, 15) + "..."
// //                                 : row.publicationTitle}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>

// //                         <td>{row.quantity || ""}</td>
// //                         <td>{row.contact || ""}</td>
//                         // <td>
//                         //   {row.email ? (
//                         //     <span
//                         //       className="show-more"
//                         //       onClick={() => handleShowPopup(row.email)}
//                         //     >
//                         //       {row.email.length > 15
//                         //         ? row.email.substring(0, 15) + "..."
//                         //         : row.email}
//                         //     </span>
//                         //   ) : (
//                         //     ""
//                         //   )}
//                         // </td>
// //                         <td>{row.orderedDate || ""}</td>
// //                         {/* <td>{row.shipingAddress || ""}</td> */}
//                         // <td>
//                         //   {row.shipingAddress ? (
//                         //     <span
//                         //       className="show-more"
//                         //       onClick={() => handleShowPopup(row.shipingAddress)}
//                         //     >
//                         //       {row.shipingAddress.length > 15
//                         //         ? row.shipingAddress.substring(0, 15) + "..."
//                         //         : row.shipingAddress}
//                         //     </span>
//                         //   ) : (
//                         //     ""
//                         //   )}
//                         // </td>
// //                         <td>
// //                           {row.orderId ? (
// //                             <DatePicker
// //                               selected={
// //                                 rowData[row.orderId]?.dispatchDate
// //                                   ? new Date(rowData[row.orderId]?.dispatchDate)
// //                                   : row.dispatchDate
// //                                     ? new Date(row.dispatchDate)
// //                                     : null
// //                               }
// //                               onChange={(date) =>
// //                                 handleInputChange(row.orderId, "dispatchDate", format(date, "yyyy-MM-dd"))
// //                               }
// //                               placeholderText="Select Date"
// //                               dateFormat="yyyy-MM-dd"
// //                               className="date-picker inline-input"
// //                             />
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.orderId ? (

// //                             <input
// //                               type="text"
// //                               className="inline-input"
// //                               value={rowData[row.orderId]?.trackingId || row.trackingId || ""}
// //                               onChange={(e) => handleInputChange(row.orderId, "trackingId", e.target.value)}
// //                               placeholder="Enter Tracking ID"
// //                             />
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.orderId ? (
// //                             <select
// //                               value={rowData[row.orderId]?.deliveryStatus || row.deliveryStatus || ""}
// //                               onChange={(e) => handleInputChange(row.orderId, "deliveryStatus", e.target.value)}
// //                               className="inline-input"
// //                             >
// //                               {/* <option value="">Select Status</option> */}

// //                               {/* <option value="Delivered">Delivered</option> */}
// //                               <option value="Processing">In Process</option>
// //                               <option value="Dispatched">Dispatched</option>
// //                               <option value="Delivered">Delivered</option>
// //                             </select>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.orderId ? (
// //                             <button
// //                               onClick={() => handleUpdateRow(row.orderId)}
// //                               className="submit-btn"
// //                             >
// //                               Save
// //                             </button>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                       </tr>
// //                     ))}
// //                   </tbody>
// //                 </table>

// //                 {/* Pagination Controls */}
// //                 <div className="pagination">
// //                   {Array.from({ length: totalPages }, (_, i) => (
// //                     <button
// //                       key={i}
// //                       onClick={() => handlePageChange(i + 1)}
// //                       className={currentPage === i + 1 ? "active" : ""}
// //                     >
// //                       {i + 1}
// //                     </button>
// //                   ))}
// //                 </div>
// //               </div>
// //             )}
// //           </div>

// //           {popupVisible && (
// //             <div className="popup-overlay">
// //               <div className="popup-content">
// //                 <button className="close-popup" onClick={closePopup}>
// //                   X
// //                 </button>
// //                 <div className="popup-body">
// //                   <p>{popupContent}</p>
// //                 </div>
// //               </div>
// //             </div>
// //           )}

// //         </main>
// //       </div>
// //       <Footer />


// //     </div>
// //   );
// // };

// // export default BookTableWithAPI;

// // // import React, { useState, useEffect } from 'react';
// // // import ReactPaginate from 'react-paginate';

// // // const OrdersTable = () => {
// // //   const [orders, setOrders] = useState([]);
// // //   const [currentPage, setCurrentPage] = useState(0);
// // //   const [search, setSearch] = useState('');
// // //   const [totalPages, setTotalPages] = useState(1);
// // //   const [isLoading, setIsLoading] = useState(true);

// // //   const fetchOrders = async (page = 0, searchQuery = '') => {
// // //     const key = localStorage.getItem("jwtToken");
// // //     setIsLoading(true); // Set loading state
// // //     try {
// // //       const response = await fetch(
// // //         `http://localhost:7073/dashboard/orders?search=${searchQuery}&page=${page}&size=10`,
// // //         {
// // //           headers: {
// // //             Auth: `Bearer ${key}`,
// // //           },
// // //         }
// // //       );
// // //       if (!response.ok) {
// // //         throw new Error('Network response was not ok');
// // //       }
// // //       const data = await response.json();
// // //       console.log(data); // Debugging the response
// // //       setOrders(data.content || []); // Default to empty array if content is undefined
// // //       setTotalPages(data.totalPages || 1); // Default to 1 if totalPages is undefined
// // //     } catch (error) {
// // //       console.error('Error fetching orders:', error);
// // //     } finally {
// // //       setIsLoading(false); // Stop loading
// // //     }
// // //   };

// // //   useEffect(() => {
// // //     fetchOrders(currentPage, search);
// // //   }, [currentPage, search]);

// // //   return (
// // //     <div>
//       // <input
//       //   type="text"
//       //   value={search}
//       //   onChange={(e) => setSearch(e.target.value)}
//       //   placeholder="Search"
//       //   style={{ marginBottom: '20px', padding: '10px', width: '300px' }}
//       // />
// // //       {isLoading ? (
// // //         <p>Loading...</p>
// // //       ) : (
// // //         <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
// // //           <thead>
// // //             <tr>
// // //               <th>NIN Order ID</th>
// // //               <th>Name</th>
// // //               <th>Email</th>
// // //               <th>Contact</th>
// // //               <th>Shipping Address</th>
// // //               <th>Order Date</th>
// // //               <th>Total Books</th>
// // //               <th>Dispatch Date</th>
// // //               <th>Delivery Status</th>
// // //               <th>Tracking ID</th>
// // //               <th>Book Details</th>
// // //             </tr>
// // //           </thead>
// // //           <tbody>
// // //             {orders.length > 0 ? (
// // //               orders.map((order) => (
// // //                 <tr key={order.ninOrderId}>
// // //                   <td>{order.ninOrderId}</td>
// // //                   <td>{order.name}</td>
// // //                   <td>{order.email}</td>
// // //                   <td>{order.contact}</td>
// // //                   <td>{order.shippingAddress}</td>
// // //                   <td>{order.orderedDate}</td>
// // //                   <td>{order.totalBook}</td>
// // //                   <td>{order.dispatchDate}</td>
// // //                   <td>{order.deliveryStatus}</td>
// // //                   <td>{order.trackingId}</td>
// // //                   <td>
// // //                     {/* Loop through bookDetails and display them */}
// // //                     <ul>
// // //                       {order.bookDetails.map((book, index) => (
// // //                         <li key={index}>
// // //                           <strong>{book.publicationTitle}</strong>: {book.quantity} x ${book.price.toFixed(2)} 
// // //                         </li>
// // //                       ))}
// // //                     </ul>
// // //                   </td>
// // //                 </tr>
// // //               ))
// // //             ) : (
// // //               <tr>
// // //                 <td colSpan="11">No orders found</td>
// // //               </tr>
// // //             )}
// // //           </tbody>
// // //         </table>
// // //       )}
// // //       <ReactPaginate
// // //         previousLabel={'Previous'}
// // //         nextLabel={'Next'}
// // //         pageCount={totalPages}
// // //         onPageChange={(selectedPage) => {
// // //           setCurrentPage(selectedPage.selected);
// // //           fetchOrders(selectedPage.selected, search);
// // //         }}
// // //         containerClassName={'pagination'}
// // //         activeClassName={'active'}
// // //         pageRangeDisplayed={3}
// // //         marginPagesDisplayed={2}
// // //         breakLabel={'...'}
// // //       />
// // //     </div>
// // //   );
// // // };

// // // export default OrdersTable;






























// // import React, { useState, useEffect } from "react";
// // import axios from "axios";
// // import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
// // import DatePicker from "react-datepicker"; // DatePicker component
// // import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
// // import "./bookinfo.scss"; // External styles
// // import Header from "./header"; // Header component
// // import Footer from "./footer"; // Footer component
// // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL
// // import { format } from 'date-fns';
// // import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
// // const BookTableWithAPI = () => {
// //   const [tableData, setTableData] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [popupContent, setPopupContent] = useState(""); // Popup content
// //   const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
// //   const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
// //   const [searchQuery, setSearchQuery] = useState(""); // Search query for filtering rows
// //   const [rowData, setRowData] = useState({}); // Store row data
// //   const rowsPerPage = 9; // Number of rows per page
// //   const [notification, setNotification] = useState(""); // Message to show
// //   const [notificationType, setNotificationType] = useState(""); // 'success' or 'error'
// //   const [popupVisible1, setPopupVisible1] = useState(false);  // To control popup visibility
// // const [popupContent1, setPopupContent1] = useState(null);  // To store book details for popup
// // const [search, setSearch] = useState("");
// //  const [page, setPage] = useState(0);
// //   const [size, setSize] = useState(10);
// //   // State to control calendar visibility
// //   const [openCalendarId, setOpenCalendarId] = useState(null); // Track the row where calendar is open

// //   useEffect(() => {
// //     fetchData();
// //   }, []);
// // const handleShowPopup1 = (bookDetails) => {
// //   setPopupContent1(bookDetails);  // Set the book details for popup
// //   setPopupVisible1(true); // Show the popup
// // };

// //  const fetchData = async () => {
// //   try {
// //     setLoading(true);
// //     const key = localStorage.getItem("jwtToken"); // Retrieve JWT token from local storage
// //  const response = await axios.get( `${BASE_URL}/dashboard/orders?search=${searchQuery}&page=${page}&size=${size}`, {
// //     // const response = await axios.get(`${BASE_URL}/dashboard/orders`, {
// //       headers: {
// //         Auth: `Bearer ${key}`, // Add the Authorization header with the JWT token
// //       },
// //     });

// //     // Log the response to inspect the data structure
// //     console.log("Response data:", response.data);

// //     // Check if the response is an array
// //    if (response.data && response.data.content) {
// //   setTableData(response.data.content); // Set fetched data from `content` array
// //   const initialRowData = response.data.content.reduce((acc, row) => {
// //     acc[row.orderId] = {
// //       dispatchDate: row.dispatchDate || "",
// //       trackingId: row.trackingId || "",
// //       deliveryStatus: row.deliveryStatus || "",
// //     };
// //     return acc;
// //   }, {});
// //   setRowData(initialRowData);
// // } else {
// //   console.error("Invalid data format received:", response.data);
// //   setNotification("Invalid data format received from the server.");
// //   setNotificationType("error");
// //   setTimeout(() => {
// //     setNotification(""); // Clear notification after a timeout
// //   }, 5000);
// // }

// //   } catch (error) {
// //     console.error("Error fetching data:", error);
// //     alert("Failed to fetch data from the server.");
// //   } finally {
// //     setLoading(false);
// //   }
// // };

// //   const handleShowPopup = (content) => {
// //     setPopupContent(content);
// //     setPopupVisible(true);
// //   };

// //   const closePopup = () => {
// //     setPopupVisible(false);
// //   };

// //   const handleInputChange = (ninOrderId, field, value) => {
// //     setRowData((prev) => ({
// //       ...prev,
// //       [ninOrderId]: {
// //         ...prev[ninOrderId],
// //         [field]: value,
// //       },
// //     }));
// //   };

// //   // const handleUpdateRow = async (orderId) => {
// //   //   const updatedRow = rowData[orderId]; // Get the updated row data
// //   //   const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage

// //   //   try {
// //   //     // Send PUT request to update row data
// //   //     const response = await axios.put(
// //   //       `${BASE_URL}/dashboard/update-orders?orderId=${orderId}`,
// //   //       {
// //   //         deliveryStatus: updatedRow.deliveryStatus,
// //   //         trackingId: updatedRow.trackingId,
// //   //         dispatchDate: updatedRow.dispatchDate,
// //   //       },
// //   //       {
// //   //         headers: {
// //   //           Auth: `Bearer ${key}`,
// //   //         },
// //   //       }
// //   //     );

// //   //     setNotification("Row updated successfully!");
// //   //     setNotificationType("success");

// //   //     // Hide the notification after 5 seconds
// //   //     setTimeout(() => {
// //   //       setNotification("");
// //   //     }, 5000);

// //   //     fetchData(); // Refresh table data after update
// //   //   } catch (error) {
// //   //     console.error("Error updating row:", error);
// //   //     setNotification("Failed to update the row. Please try again.");
// //   //     setNotificationType("error");

// //   //     // Hide the notification after 5 seconds
// //   //     setTimeout(() => {
// //   //       setNotification("");
// //   //     }, 10000);
// //   //   }
// //   // };
// //   const handleUpdateRow = async (ninOrderId) => {
// //     const updatedRow = rowData[ninOrderId]; // Get the updated row data
// //     const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage

// //     try {
// //       // Send PUT request to update row data
// //       const response = await axios.put(
// //         `${BASE_URL}/dashboard/update-orders?ninOrderId=${ninOrderId}`,
// //         {
// //           deliveryStatus: updatedRow.deliveryStatus,
// //           trackingId: updatedRow.trackingId,
// //           dispatchDate: updatedRow.dispatchDate,
// //         },
// //         {
// //           headers: {
// //             Auth: `Bearer ${key}`,
// //           },
// //         }
// //       );

// //       setNotification("Row updated successfully!");
// //       setNotificationType("success");

// //       // Hide the notification after 5 seconds
// //       setTimeout(() => {
// //         setNotification("");
// //       }, 5000);

// //       fetchData(); // Refresh table data after update
// //     } catch (error) {
// //       console.error("Error updating row:", error);
// //       setNotification("Failed to update the row. Please try again.");
// //       setNotificationType("error");

// //       // Hide the notification after 5 seconds
// //       setTimeout(() => {
// //         setNotification("");
// //       }, 10000);
// //     }
// //   };

// //   const indexOfLastRow = currentPage * rowsPerPage;
// //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;


// //   // const filteredData = tableData.filter((row) =>
// //   //   row.name?.toLowerCase().includes(searchQuery.toLowerCase())
// //   // );


// // const filteredData = tableData.filter((row) =>
// //   row.name?.toLowerCase().includes(searchQuery.toLowerCase()) || 
// //   row.contact?.toLowerCase().includes(searchQuery.toLowerCase())  ||
// //     row.ninOrderId?.toLowerCase().includes(searchQuery.toLowerCase())
// // );

// // const paginatedData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
// //   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

// //   const totalPages = Math.ceil(filteredData.length / rowsPerPage);

// //   const handlePageChange = (pageNumber) => {
// //     setCurrentPage(pageNumber);
// //   };

// //   // Padding empty rows
// //   const paddedRows = [...currentRows];
// //   while (paddedRows.length < rowsPerPage) {
// //     paddedRows.push({}); 
// //   }

// //   return (
// //     <div id="root">
// //       <div className="page-container">
// //         <RedirectComponent />
// //         <Header />

// //         <main>
// //           {notification && (
// //             <div className={`notification ${notificationType}`}>
// //               {notification}
// //             </div>
// //           )}

       
// //          <div className="search-bar-container" style={{ marginLeft: '20px', marginTop: '5px' }}>
// //             <input
// //               type="text"
// //               className="search-bar"
// //               placeholder="Search by username"
// //               value={searchQuery}
// //               onChange={(e) => setSearchQuery(e.target.value)}
// //             />
// //           </div>
   
// //           <div className="table-container">
// //             {loading ? (
// //               <div className="loading">Loading...</div>
// //             ) : (
// //               <div className="table-wrapper">
     
// //                 <table className="book-table">
// //   <thead>
// //     <tr>
// //       <th>S.No</th>
// //       <th>Order ID</th> {/* Add NIN Order ID column */}
// //       <th>User Name</th>
// //     <th>Book Title</th> 
// //       <th style={{ width: '80px' }}>Qty</th>
// //         <th style={{ width: '120px' }}>Contact</th>
// //       <th>Email ID</th>
// //       <th>Order Date</th>
// //       <th>Shipping Add.</th>
// //       <th>Dispatch Date</th>
// //       <th>Tracking ID</th>
// //       <th>Delivery Status</th>
// //       <th>Actions</th>
// //     </tr>
// //   </thead>

// //   <tbody>
// //     {paddedRows.map((row, index) => (
// //       <tr key={index}>
// //         <td>{row.ninOrderId ? indexOfFirstRow + index + 1 : ""}</td> {/* Render index */}
// //         <td>{row.ninOrderId || ""}</td> {/* Render NIN Order ID */}
// //         <td>{row.name ? row.name : ""}</td> {/* Render user name */}
// //         <td>
// //           {row.bookDetails && row.bookDetails[0]?.publicationTitle ? (
// //             <span
// //               className="show-more"
// //               onClick={() => handleShowPopup1(row.bookDetails)}
// //             >
// //               {row.bookDetails[0]?.publicationTitle.length > 15
// //                 ? row.bookDetails[0]?.publicationTitle.substring(0, 15) + "..."
// //                 : row.bookDetails[0]?.publicationTitle}
// //             </span>
// //           ) : (
// //             ""
// //           )}
// //         </td>
// //         <td>{row.totalBook || ""}</td>
// //         <td>{row.contact || ""}</td>
// //         {/* <td>{row.email || ""}</td> */}


// //                                 <td>
// //                           {row.email ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.email)}
// //                             >
// //                               {row.email.length > 15
// //                                 ? row.email.substring(0, 15) + "..."
// //                                 : row.email}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //         <td>{row.orderedDate || ""}</td>
// //         {/* <td>{row.shippingAddress ? row.shippingAddress : ""}</td> */}
// //          <td>
// //       {row.shippingAddress ? (
// //         <span
// //           className="show-more"
// //           onClick={() => handleShowPopup(row.shippingAddress)}
// //         >
// //           {row.shippingAddress.length > 15
// //             ? row.shippingAddress.substring(0, 15) + "..."
// //             : row.shippingAddress}
// //         </span>
// //       ) : (
// //         ""
// //       )}
// //       </td>
// //         <td>
// //           {row.ninOrderId && (
// //             <DatePicker
// //               selected={rowData[row.ninOrderId]?.dispatchDate ? new Date(rowData[row.ninOrderId]?.dispatchDate) : row.dispatchDate ? new Date(row.dispatchDate) : null}
// //               onChange={(date) => handleInputChange(row.ninOrderId, "dispatchDate", format(date, "yyyy-MM-dd"))}
// //               placeholderText="Select Date"
// //               dateFormat="yyyy-MM-dd"
// //               className="date-picker inline-input"
// //             />
// //           )}
// //         </td>
// //         <td>
// //           {row.ninOrderId && (
// //             <input
// //               type="text"
// //               className="inline-input"
// //               value={rowData[row.ninOrderId]?.trackingId || row.trackingId || ""}
// //               onChange={(e) => handleInputChange(row.ninOrderId, "trackingId", e.target.value)}
// //               placeholder="Enter Tracking ID"
// //             />
// //           )}
// //         </td>
// //         <td>
// //           {row.ninOrderId && (
// //             <select
// //               value={rowData[row.ninOrderId]?.deliveryStatus || row.deliveryStatus || ""}
// //               onChange={(e) => handleInputChange(row.ninOrderId, "deliveryStatus", e.target.value)}
// //               className="inline-input"
// //             >
// //               <option value="Processing">In Process</option>
// //               <option value="Dispatched">Dispatched</option>
// //               <option value="Delivered">Delivered</option>
// //             </select>
// //           )}
// //         </td>
// //         <td>
// //           {row.ninOrderId && (
// //             <button onClick={() => handleUpdateRow(row.ninOrderId)} className="submit-btn">
// //               Save
// //             </button>
// //           )}
// //         </td>
// //       </tr>
// //     ))}
// //   </tbody>
// // </table>


// //                 {/* Pagination Controls */}
// //                 <div className="pagination">
// //                   {Array.from({ length: totalPages }, (_, i) => (
// //                     <button
// //                       key={i}
// //                       onClick={() => handlePageChange(i + 1)}
// //                       className={currentPage === i + 1 ? "active" : ""}
// //                     >
// //                       {i + 1}
// //                     </button>
// //                   ))}
// //                 </div>
// //               </div>
// //             )}
// //           </div>

// //           {popupVisible && (
// //             <div className="popup-overlay">
// //               <div className="popup-content">
// //                 <button className="close-popup" onClick={closePopup}>
// //                   X
// //                 </button>
// //                 <div className="popup-body">
// //                   <p>{popupContent}</p>
// //                 </div>
// //               </div>
// //             </div>
// //           )}

// //         </main>



// //         {/* {popupVisible1 && popupContent1 && (
// //   <div className="popup-overlay">
// //     <div className="popup-content">
// //       <button className="close-popup" onClick={() => setPopupVisible1(false)}>
// //         X
// //       </button>
// //       <div className="popup-body">
// //         <h2>Book Details</h2>
// //         {popupContent1.map((book, index) => (
// //           <div key={index} className="book-details">
// //             <p><strong>Title:</strong> {book.publicationTitle}</p>
// //             <p><strong>Quantity:</strong> {book.quantity}</p>
// //             <p><strong>Price:</strong> ${book.price}</p>
// //             <p><strong>Book ID:</strong> {book.bookId}</p>
// //           </div>
// //         ))}
// //       </div>
// //     </div>
// //   </div>
// // )} */}


// // {/* 

// // {popupVisible1 && popupContent1 && (
// //   <div className="popup-overlay">
// //     <div className="popup-content">
// //       <button className="close-popup" onClick={() => setPopupVisible1(false)}>
// //         X
// //       </button>
// //       <div className="popup-body">
// //         <h2>Book Details</h2>
// //         {popupContent1.map((book, index) => (
// //           <div key={index} className="book-details">
// //             <p><strong>Title:</strong> {book.publicationTitle}</p>
// //             <p><strong>Quantity:</strong> {book.quantity}</p>
// //             <p><strong>Price:</strong> ₹{book.price}</p>
           
// //           </div>
// //         ))}
// //       </div>
// //     </div>
// //   </div>
// // )} */}
// // {popupVisible1 && popupContent1 && (
// //   <div className="popup-overlay">
// //     <div className="popup-content">
// //       <button className="close-popup" onClick={() => setPopupVisible1(false)}>
// //         X
// //       </button>
// //       <div className="popup-body">
// //         <h2 className="popup-heading">Book Details</h2>
// //         <div className="book-list">
// //           {popupContent1.map((book, index) => (
// //             <div key={index} className="book-details">
// //               <p className="book-title"><strong>Title:</strong> {book.publicationTitle}</p>
// //               <p className="book-quantity"><strong>Quantity:</strong> {book.quantity}</p>
// //               <p className="book-price"><strong>Price:</strong> ₹{book.price}</p>
// //             </div>
// //           ))}
// //         </div>
// //       </div>
// //     </div>
// //   </div>
// // )}


// //       </div>
// //       <Footer />


// //     </div>
// //   );
// // };

// // export default BookTableWithAPI;





















// // import React, { useState, useEffect } from "react";
// // import axios from "axios";
// // import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
// // import DatePicker from "react-datepicker"; // DatePicker component
// // import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
// // import "./bookinfo.scss"; // External styles
// // import Header from "./header"; // Header component
// // import Footer from "./footer"; // Footer component
// // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL
// // import { format } from 'date-fns';
// // import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
// // const BookTableWithAPI = () => {
// //   // const [tableData, setTableData] = useState([]);
// //   // const [loading, setLoading] = useState(true);
// //   const [popupContent, setPopupContent] = useState(""); // Popup content
// //   const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
// //   const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
// //   const [searchQuery, setSearchQuery] = useState(""); // Search query for filtering rows
// //   const [rowData, setRowData] = useState({}); // Store row data
// //   const rowsPerPage = 9; // Number of rows per page
// //   const [notification, setNotification] = useState(""); // Message to show
// //   const [notificationType, setNotificationType] = useState(""); // 'success' or 'error'
// //   const [popupVisible1, setPopupVisible1] = useState(false);  // To control popup visibility
// // const [popupContent1, setPopupContent1] = useState(null);  // To store book details for popup
// // const [search, setSearch] = useState("");
// // //  const [page, setPage] = useState(0);
// // //   // const [size, setSize] = useState(10);


// // //    const [size, setSize] = useState(9); // Items per page

// // const [page, setPage] = useState(1); // Initialize page to 1 (first page)
// // const [size, setSize] = useState(10); // Default size
// // // const [totalPages, setTotalPages] = useState(0);
// // const [tableData, setTableData] = useState([]);
// // const [loading, setLoading] = useState(false);
// //   const [totalPages, setTotalPages] = useState(0); // Total number of pages
  
// //   useEffect(() => {
// //     fetchData(currentPage);
// //   }, [currentPage]);

// //   const [openCalendarId, setOpenCalendarId] = useState(null); // Track the row where calendar is open

// //   useEffect(() => {
// //     fetchData();
// //   }, []);


// // const handleShowPopup1 = (bookDetails) => {
// //   setPopupContent1(bookDetails);  // Set the book details for popup
// //   setPopupVisible1(true); // Show the popup
// // };

// // const fetchData = async (page) => {
// //   try {
// //     setLoading(true);
// //     const key = localStorage.getItem("jwtToken");

// //     // Make sure page is a valid number
// //     if (!page || page < 1) {
// //       console.error("Invalid page number:", page);
// //       return;
// //     }

// //     const response = await axios.get(
// //       `${BASE_URL}/dashboard/orders?search=${searchQuery}&page=${page - 1}&size=${size}`,
// //       {
// //         headers: {
// //           Auth: `Bearer ${key}`,
// //         },
// //       }
// //     );

// //     console.log("Response data:", response.data);

// //     if (response.data && response.data.content) {
// //       setTableData(response.data.content);
// //       setTotalPages(response.data.totalPages); // Set total pages for pagination
// //     } else {
// //       console.error("Invalid data format received:", response.data);
// //     }
// //   } catch (error) {
// //     console.error("Error fetching data:", error);
// //     alert("Failed to fetch data from the server.");
// //   } finally {
// //     setLoading(false);
// //   }
// // };

// // const handlePageChange = (pageNumber) => {
// //   setPage(pageNumber); // Update page number when a button is clicked
// //   fetchData(pageNumber); // Fetch data for the selected page
// // };

// // useEffect(() => {
// //   fetchData(page); // Fetch data when component mounts
// // }, [page]); // When page changes, fetch new data

// // useEffect(() => {
// //   fetchData(page); // Fetch data on initial load
// // }, [page]);

// //   const handleShowPopup = (content) => {
// //     setPopupContent(content);
// //     setPopupVisible(true);
// //   };

// //   const closePopup = () => {
// //     setPopupVisible(false);
// //   };

// //   const handleInputChange = (ninOrderId, field, value) => {
// //     setRowData((prev) => ({
// //       ...prev,
// //       [ninOrderId]: {
// //         ...prev[ninOrderId],
// //         [field]: value,
// //       },
// //     }));
// //   };

// //   const handleUpdateRow = async (ninOrderId) => {
// //     const updatedRow = rowData[ninOrderId]; // Get the updated row data
// //     const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage

// //     try {
// //       // Send PUT request to update row data
// //       const response = await axios.put(
// //         `${BASE_URL}/dashboard/update-orders?ninOrderId=${ninOrderId}`,
// //         {
// //           deliveryStatus: updatedRow.deliveryStatus,
// //           trackingId: updatedRow.trackingId,
// //           dispatchDate: updatedRow.dispatchDate,
// //         },
// //         {
// //           headers: {
// //             Auth: `Bearer ${key}`,
// //           },
// //         }
// //       );

// //       setNotification("Row updated successfully!");
// //       setNotificationType("success");

// //       // Hide the notification after 5 seconds
// //       setTimeout(() => {
// //         setNotification("");
// //       }, 5000);

// //       fetchData(); // Refresh table data after update
// //     } catch (error) {
// //       console.error("Error updating row:", error);
// //       setNotification("Failed to update the row. Please try again.");
// //       setNotificationType("error");

// //       // Hide the notification after 5 seconds
// //       setTimeout(() => {
// //         setNotification("");
// //       }, 10000);
// //     }
// //   };

// //   const indexOfLastRow = currentPage * rowsPerPage;
// //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // const filteredData = tableData.filter((row) =>
// //   row.name?.toLowerCase().includes(searchQuery.toLowerCase()) || 
// //   row.contact?.toLowerCase().includes(searchQuery.toLowerCase())  ||
// //     row.ninOrderId?.toLowerCase().includes(searchQuery.toLowerCase())
// // );


// //   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

// //   // const totalPages = Math.ceil(filteredData.length / rowsPerPage);

// //   // const handlePageChange = (pageNumber) => {
// //   //   setCurrentPage(pageNumber);
// //   // };

// //   // Padding empty rows
// //   const paddedRows = [...currentRows];
// //   while (paddedRows.length < rowsPerPage) {
// //     paddedRows.push({}); 
// //   }

// // //   return (
// // //     <div id="root">
// // //       <div className="page-container">
// // //         <RedirectComponent />
// // //         <Header />

// // //         <main>
// // //           {notification && (
// // //             <div className={`notification ${notificationType}`}>
// // //               {notification}
// // //             </div>
// // //           )}

       
// // //          <div className="search-bar-container" style={{ marginLeft: '20px', marginTop: '5px' }}>
// // //             <input
// // //               type="text"
// // //               className="search-bar"
// // //               placeholder="Search by username"
// // //               value={searchQuery}
// // //               onChange={(e) => setSearchQuery(e.target.value)}
// // //             />
// // //           </div>
   
// // //           <div className="table-container">
// // //             {loading ? (
// // //               <div className="loading">Loading...</div>
// // //             ) : (
// // //               <div className="table-wrapper">
     
// // //                 <table className="book-table">
// // //   <thead>
// // //     <tr>
// // //       <th>S.No</th>
// // //       <th>Order ID</th> {/* Add NIN Order ID column */}
// // //       <th>User Name</th>
// // //     <th>Book Title</th> 
// // //       <th style={{ width: '80px' }}>Qty</th>
// // //         <th style={{ width: '120px' }}>Contact</th>
// // //       <th>Email ID</th>
// // //       <th>Order Date</th>
// // //       <th>Shipping Add.</th>
// // //       <th>Dispatch Date</th>
// // //       <th>Tracking ID</th>
// // //       <th>Delivery Status</th>
// // //       <th>Actions</th>
// // //     </tr>
// // //   </thead>

// // //   <tbody>
// // //     {paddedRows.map((row, index) => (
// // //       <tr key={index}>
// // //         <td>{row.ninOrderId ? indexOfFirstRow + index + 1 : ""}</td> {/* Render index */}
// // //         <td>{row.ninOrderId || ""}</td> {/* Render NIN Order ID */}
// // //         <td>{row.name ? row.name : ""}</td> {/* Render user name */}
// // //         <td>
// // //           {row.bookDetails && row.bookDetails[0]?.publicationTitle ? (
// // //             <span
// // //               className="show-more"
// // //               onClick={() => handleShowPopup1(row.bookDetails)}
// // //             >
// // //               {row.bookDetails[0]?.publicationTitle.length > 15
// // //                 ? row.bookDetails[0]?.publicationTitle.substring(0, 15) + "..."
// // //                 : row.bookDetails[0]?.publicationTitle}
// // //             </span>
// // //           ) : (
// // //             ""
// // //           )}
// // //         </td>
// // //         <td>{row.totalBook || ""}</td>
// // //         <td>{row.contact || ""}</td>
// // //         {/* <td>{row.email || ""}</td> */}


// // //                                 <td>
// // //                           {row.email ? (
// // //                             <span
// // //                               className="show-more"
// // //                               onClick={() => handleShowPopup(row.email)}
// // //                             >
// // //                               {row.email.length > 15
// // //                                 ? row.email.substring(0, 15) + "..."
// // //                                 : row.email}
// // //                             </span>
// // //                           ) : (
// // //                             ""
// // //                           )}
// // //                         </td>
// // //         <td>{row.orderedDate || ""}</td>
// // //         {/* <td>{row.shippingAddress ? row.shippingAddress : ""}</td> */}
// // //          <td>
// // //       {row.shippingAddress ? (
// // //         <span
// // //           className="show-more"
// // //           onClick={() => handleShowPopup(row.shippingAddress)}
// // //         >
// // //           {row.shippingAddress.length > 15
// // //             ? row.shippingAddress.substring(0, 15) + "..."
// // //             : row.shippingAddress}
// // //         </span>
// // //       ) : (
// // //         ""
// // //       )}
// // //       </td>
// // //         <td>
// // //           {row.ninOrderId && (
// // //             <DatePicker
// // //               selected={rowData[row.ninOrderId]?.dispatchDate ? new Date(rowData[row.ninOrderId]?.dispatchDate) : row.dispatchDate ? new Date(row.dispatchDate) : null}
// // //               onChange={(date) => handleInputChange(row.ninOrderId, "dispatchDate", format(date, "yyyy-MM-dd"))}
// // //               placeholderText="Select Date"
// // //               dateFormat="yyyy-MM-dd"
// // //               className="date-picker inline-input"
// // //             />
// // //           )}
// // //         </td>
// // //         <td>
// // //           {row.ninOrderId && (
// // //             <input
// // //               type="text"
// // //               className="inline-input"
// // //               value={rowData[row.ninOrderId]?.trackingId || row.trackingId || ""}
// // //               onChange={(e) => handleInputChange(row.ninOrderId, "trackingId", e.target.value)}
// // //               placeholder="Enter Tracking ID"
// // //             />
// // //           )}
// // //         </td>
// // //         <td>
// // //           {row.ninOrderId && (
// // //             <select
// // //               value={rowData[row.ninOrderId]?.deliveryStatus || row.deliveryStatus || ""}
// // //               onChange={(e) => handleInputChange(row.ninOrderId, "deliveryStatus", e.target.value)}
// // //               className="inline-input"
// // //             >
// // //               <option value="Processing">In Process</option>
// // //               <option value="Dispatched">Dispatched</option>
// // //               <option value="Delivered">Delivered</option>
// // //             </select>
// // //           )}
// // //         </td>
// // //         <td>
// // //           {row.ninOrderId && (
// // //             <button onClick={() => handleUpdateRow(row.ninOrderId)} className="submit-btn">
// // //               Save
// // //             </button>
// // //           )}
// // //         </td>
// // //       </tr>
// // //     ))}
// // //   </tbody>
// // // </table>


// // //                 {/* Pagination Controls */}
// // //                 <div className="pagination">
// // //                   {Array.from({ length: totalPages }, (_, i) => (
// // //                     <button
// // //                       key={i}
// // //                       onClick={() => handlePageChange(i + 1)}
// // //                       className={currentPage === i + 1 ? "active" : ""}
// // //                     >
// // //                       {i + 1}
// // //                     </button>
// // //                   ))}
// // //                 </div>
// // //               </div>
// // //             )}
// // //           </div>

// // //           {popupVisible && (
// // //             <div className="popup-overlay">
// // //               <div className="popup-content">
// // //                 <button className="close-popup" onClick={closePopup}>
// // //                   X
// // //                 </button>
// // //                 <div className="popup-body">
// // //                   <p>{popupContent}</p>
// // //                 </div>
// // //               </div>
// // //             </div>
// // //           )}

// // //         </main>


// // {popupVisible1 && popupContent1 && (
// //   <div className="popup-overlay">
// //     <div className="popup-content">
// //       <button className="close-popup" onClick={() => setPopupVisible1(false)}>
// //         X
// //       </button>
// //       <div className="popup-body">
// //         <h2 className="popup-heading">Book Details</h2>
// //         <div className="book-list">
// //           {popupContent1.map((book, index) => (
// //             <div key={index} className="book-details">
// //               <p className="book-title"><strong>Title:</strong> {book.publicationTitle}</p>
// //               <p className="book-quantity"><strong>Quantity:</strong> {book.quantity}</p>
// //               <p className="book-price"><strong>Price:</strong> ₹{book.price}</p>
// //             </div>
// //           ))}
// //         </div>
// //       </div>
// //     </div>
// //   </div>
// // )}


// // //       </div>
// // //       <Footer />


// // //     </div>
// // //   );
// // // };

// // // export default BookTableWithAPI;


// //   return (
// //     <div id="root">
// //       <div className="page-container">
// //         <RedirectComponent />
// //         <Header />

// //         <main>
// //           {notification && (
// //             <div className={`notification ${notificationType}`}>
// //               {notification}
// //             </div>
// //           )}

// //           <div
// //             className="search-bar-container"
// //             style={{ marginLeft: "20px", marginTop: "5px" }}
// //           >
// //             <input
// //               type="text"
// //               className="search-bar"
// //               placeholder="Search by username"
// //               value={searchQuery}
// //               onChange={(e) => setSearchQuery(e.target.value)}
// //             />
// //           </div>

// //           <div className="table-container">
// //             {loading ? (
// //               <div className="loading">Loading...</div>
// //             ) : (
// //               <div className="table-wrapper">
// //                 <table className="book-table">
// //                   <thead>
// //                     <tr>
// //                       <th>S.No</th>
// //                       <th>Order ID</th>
// //                       <th>User Name</th>
// //                       <th>Book Title</th>
// //                       <th style={{ width: "80px" }}>Qty</th>
// //                       <th style={{ width: "120px" }}>Contact</th>
// //                       <th>Email ID</th>
// //                       <th>Order Date</th>
// //                       <th>Shipping Add.</th>
// //                       <th>Dispatch Date</th>
// //                       <th>Tracking ID</th>
// //                       <th>Delivery Status</th>
// //                       <th>Actions</th>
// //                     </tr>
// //                   </thead>

// //                   <tbody>
// //                     {paddedRows.map((row, index) => (
// //                       <tr key={index}>
// //                         <td>
// //                           {row.ninOrderId
// //                             ? currentPage === 1
// //                               ? index + 1
// //                               : index + 1 + (currentPage - 1) * 10
// //                             : ""}
// //                         </td>
// //                         <td>{row.ninOrderId || ""}</td>
// //                         <td>{row.name || ""}</td>
// //                         <td>
// //                           {row.bookDetails && row.bookDetails[0]?.publicationTitle ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup1(row.bookDetails)}
// //                             >
// //                               {row.bookDetails[0]?.publicationTitle.length > 15
// //                                 ? row.bookDetails[0]?.publicationTitle.substring(0, 15) + "..."
// //                                 : row.bookDetails[0]?.publicationTitle}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>{row.totalBook || ""}</td>
// //                         <td>{row.contact || ""}</td>

// //                         <td>
// //                           {row.email ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.email)}
// //                             >
// //                               {row.email.length > 15
// //                                 ? row.email.substring(0, 15) + "..."
// //                                 : row.email}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>

// //                         <td>{row.orderedDate || ""}</td>
// //                         <td>
// //                           {row.shippingAddress ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.shippingAddress)}
// //                             >
// //                               {row.shippingAddress.length > 15
// //                                 ? row.shippingAddress.substring(0, 15) + "..."
// //                                 : row.shippingAddress}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>

// //                         <td>
// //                           {row.ninOrderId && (
// //                             <DatePicker
// //                               selected={
// //                                 rowData[row.ninOrderId]?.dispatchDate
// //                                   ? new Date(rowData[row.ninOrderId]?.dispatchDate)
// //                                   : row.dispatchDate
// //                                   ? new Date(row.dispatchDate)
// //                                   : null
// //                               }
// //                               onChange={(date) =>
// //                                 handleInputChange(row.ninOrderId, "dispatchDate", format(date, "yyyy-MM-dd"))
// //                               }
// //                               placeholderText="Select Date"
// //                               dateFormat="yyyy-MM-dd"
// //                               className="date-picker inline-input"
// //                             />
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.ninOrderId && (
// //                             <input
// //                               type="text"
// //                               className="inline-input"
// //                               value={rowData[row.ninOrderId]?.trackingId || row.trackingId || ""}
// //                               onChange={(e) =>
// //                                 handleInputChange(row.ninOrderId, "trackingId", e.target.value)
// //                               }
// //                               placeholder="Enter Tracking ID"
// //                             />
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.ninOrderId && (
// //                             <select
// //                               value={rowData[row.ninOrderId]?.deliveryStatus || row.deliveryStatus || ""}
// //                               onChange={(e) =>
// //                                 handleInputChange(row.ninOrderId, "deliveryStatus", e.target.value)
// //                               }
// //                               className="inline-input"
// //                             >
// //                               <option value="Processing">In Process</option>
// //                               <option value="Dispatched">Dispatched</option>
// //                               <option value="Delivered">Delivered</option>
// //                             </select>
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.ninOrderId && (
// //                             <button onClick={() => handleUpdateRow(row.ninOrderId)} className="submit-btn">
// //                               Save
// //                             </button>
// //                           )}
// //                         </td>
// //                       </tr>
// //                     ))}
// //                   </tbody>
// //                 </table>

// //                 {/* Pagination Controls */}
// //                 {/* <div className="pagination">
// //                   {Array.from({ length: totalPages }, (_, i) => (
// //                     <button
// //                       key={i}
// //                       onClick={() => handlePageChange(i + 1)}
// //                       className={currentPage === i + 1 ? "active" : ""}
// //                     >
// //                       {i + 1}
// //                     </button>
// //                   ))}
// //                 </div> */}
// //                <div className="pagination">
// //   {Array.from({ length: totalPages }, (_, i) => (
// //     <button
// //       key={i}
// //       onClick={() => handlePageChange(i + 1)} // pageNumber starts from 1
// //       className={page === i + 1 ? "active" : ""}
// //     >
// //       {i + 1}
// //     </button>
// //   ))}
// // </div>
// //               </div>
// //             )}
// //           </div>

// //           {popupVisible && (
// //             <div className="popup-overlay">
// //               <div className="popup-content">
// //                 <button className="close-popup" onClick={closePopup}>
// //                   X
// //                 </button>
// //                 <div className="popup-body">
// //                   <p>{popupContent}</p>
// //                 </div>
// //               </div>
// //             </div>
// //           )}

// //           {popupVisible1 && popupContent1 && (
// //             <div className="popup-overlay">
// //               <div className="popup-content">
// //                 <button className="close-popup" onClick={() => setPopupVisible1(false)}>
// //                   X
// //                 </button>
// //                 <div className="popup-body">
// //                   <h2 className="popup-heading">Book Details</h2>
// //                   <ul>
// //                     {popupContent1.map((book, index) => (
// //                       <li key={index}>{book.publicationTitle}</li>
// //                     ))}
// //                   </ul>
// //                 </div>
// //               </div>
// //             </div>
// //           )}
// //         </main>

// //         <Footer />
// //       </div>
// //     </div>
// //   );
// // };

// // export default BookTableWithAPI;















// //=============================================final=====================






// // import React, { useState, useEffect } from "react";
// // import axios from "axios";
// // import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
// // import DatePicker from "react-datepicker"; // DatePicker component
// // import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
// // import "./bookinfo.scss"; // External styles
// // import Header from "./header"; // Header component
// // import Footer from "./footer"; // Footer component
// // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL
// // import { format } from 'date-fns';
// // import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
// // const BookTableWithAPI = () => {
// //   const [popupContent, setPopupContent] = useState(""); // Popup content
// //   const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
// //   const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
// //  // Search query for filtering rows
// //   const [rowData, setRowData] = useState({}); // Store row data
// //   const rowsPerPage = 9; // Number of rows per page
// //   const [notification, setNotification] = useState(""); // Message to show
// //   const [notificationType, setNotificationType] = useState(""); // 'success' or 'error'
// //   const [popupVisible1, setPopupVisible1] = useState(false);  // To control popup visibility
// // const [popupContent1, setPopupContent1] = useState(null);  // To store book details for popup
// // const [search, setSearch] = useState("");
// //  const [searchQuery, setSearchQuery] = useState(""); 
// // const [page, setPage] = useState(1); // Initialize page to 1 (first page)
// // const [size, setSize] = useState(10); // Default size
// // const [tableData, setTableData] = useState([]);
// // const [loading, setLoading] = useState(false);
// //   const [totalPages, setTotalPages] = useState(0); // Total number of pages
  
// //   useEffect(() => {
// //     fetchData(currentPage);
// //   }, [currentPage]);

// //   const [openCalendarId, setOpenCalendarId] = useState(null); // Track the row where calendar is open

// //   useEffect(() => {
// //     fetchData();
   
// //   }, []);


// // const handleShowPopup1 = (bookDetails) => {
// //   setPopupContent1(bookDetails);  // Set the book details for popup
// //   setPopupVisible1(true); // Show the popup
// // };

// // // const fetchData = async (page) => {
// // //   try {
// // //     setLoading(true);
// // //     const key = localStorage.getItem("jwtToken");

// // //     // Make sure page is a valid number
// // //     if (!page || page < 1) {
// // //       console.error("Invalid page number:", page);
// // //       return;
// // //     }

// // //     const response = await axios.get(
// // //       `${BASE_URL}/dashboard/orders?search=${searchQuery}&page=${page - 1}&size=${size}`,
// // //       {
// // //         headers: {
// // //           Auth: `Bearer ${key}`,
// // //         },
// // //       }
// // //     );

// // //     console.log("Response data:", response.data);

// // //     if (response.data && response.data.content) {
// // //       setTableData(response.data.content);
// // //       setTotalPages(response.data.totalPages); // Set total pages for pagination
// // //     } else {
// // //       console.error("Invalid data format received:", response.data);
// // //     }
// // //   } catch (error) {
// // //     console.error("Error fetching data:", error);
// // //     alert("Failed to fetch data from the server.");
// // //   } finally {
// // //     setLoading(false);
// // //   }
// // // };
// // const fetchData = async (page) => {
// //   try {
// //     setLoading(true);
// //     const key = localStorage.getItem("jwtToken");

// //     // Make sure page is a valid number
// //     if (!page || page < 1) {
// //       console.error("Invalid page number:", page);
// //       return;
// //     }

// //     const response = await axios.get(
// //       `${BASE_URL}/dashboard/orders?search=${searchQuery}&page=${page - 1}&size=${size}`,
// //       {
// //         headers: {
// //           Auth: `Bearer ${key}`,
// //         },
// //       }
// //     );

// //     console.log("Response data:", response.data);

// //     if (response.data && response.data.content) {
// //       setTableData(response.data.content);
// //       setTotalPages(response.data.totalPages); // Set total pages for pagination
// //     } else {
// //       console.error("Invalid data format received:", response.data);
// //     }
// //   } catch (error) {
// //     console.error("Error fetching data:", error);
// //     alert("Failed to fetch data from the server.");
// //   } finally {
// //     setLoading(false);
// //   }
// // };

// // // const handleSearchChange = (e) => {
// // //   const value = e.target.value;
// // //   setSearchQuery(value);
// // //   fetchData(page); // Trigger API fetch on search change
// // // };
// // const handleSearchChange = (e) => {
// //   const value = e.target.value;
// //   setSearchQuery(value);
// // };

// // const handleSearchKeyPress = (e) => {
// //   if (e.key === 'Enter') {
// //     fetchData(page); // Trigger search when Enter is pressed
// //   }
// // };

// // const filteredData = tableData.filter((row) =>
// //   row.contact?.toLowerCase().includes(searchQuery.toLowerCase()) || 
// //   row.ninOrderId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
// //   row.name?.toLowerCase().includes(searchQuery.toLowerCase())
// // );
// // useEffect(() => {
// //   if (searchQuery.trim() === "") return; // Skip fetching if search query is empty
// //   fetchData(page); // Fetch data on search query change
// // }, [searchQuery, page]);

// // const handlePageChange = (pageNumber) => {
// //   setPage(pageNumber); // Update page number when a button is clicked
// //   fetchData(pageNumber); // Fetch data for the selected page
// // };

// // useEffect(() => {
// //   fetchData(page); // Fetch data when component mounts
// // }, [page]); // When page changes, fetch new data

// // useEffect(() => {
// //   fetchData(page); // Fetch data on initial load
// // }, [page]);

// //   const handleShowPopup = (content) => {
// //     setPopupContent(content);
// //     setPopupVisible(true);
// //   };

// //   const closePopup = () => {
// //     setPopupVisible(false);
// //   };

// //   const handleInputChange = (ninOrderId, field, value) => {
// //     setRowData((prev) => ({
// //       ...prev,
// //       [ninOrderId]: {
// //         ...prev[ninOrderId],
// //         [field]: value,
// //       },
// //     }));
// //   };

// //   const handleUpdateRow = async (ninOrderId) => {
// //     const updatedRow = rowData[ninOrderId]; // Get the updated row data
// //     const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage

// //     try {
// //       // Send PUT request to update row data
// //       const response = await axios.put(
// //         `${BASE_URL}/dashboard/update-orders?ninOrderId=${ninOrderId}`,
// //         {
// //           deliveryStatus: updatedRow.deliveryStatus,
// //           trackingId: updatedRow.trackingId,
// //           dispatchDate: updatedRow.dispatchDate,
// //         },
// //         {
// //           headers: {
// //             Auth: `Bearer ${key}`,
// //           },
// //         }
// //       );

// //       setNotification("Row updated successfully!");
// //       setNotificationType("success");

// //       // Hide the notification after 5 seconds
// //       setTimeout(() => {
// //         setNotification("");
// //       }, 5000);

// //       fetchData(); // Refresh table data after update
// //     } catch (error) {
// //       console.error("Error updating row:", error);
// //       setNotification("Failed to update the row. Please try again.");
// //       setNotificationType("error");

// //       // Hide the notification after 5 seconds
// //       setTimeout(() => {
// //         setNotification("");
// //       }, 10000);
// //     }
// //   };

// //   const indexOfLastRow = currentPage * rowsPerPage;
// //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;

// // // const filteredData = tableData.filter((row) =>
// // //   row.name?.toLowerCase().includes(searchQuery.toLowerCase()) || 
// // //   row.contact?.toLowerCase().includes(searchQuery.toLowerCase())  ||
// // //     row.ninOrderId?.toLowerCase().includes(searchQuery.toLowerCase())
// // // );


// //   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

// //   const paddedRows = [...currentRows];
// //   while (paddedRows.length < rowsPerPage) {
// //     paddedRows.push({}); 
// //   }


// //   return (
// //     <div id="root">
// //       <div className="page-container">
// //         <RedirectComponent />
// //         <Header />

// //         <main>
// //           {notification && (
// //             <div className={`notification ${notificationType}`}>
// //               {notification}
// //             </div>
// //           )}

// //           {/* <div className="search-bar-container" style={{ marginLeft: "20px", marginTop: "20px" }}>
// //   <input
// //     type="text"
// //     className="search-bar"
// //   placeholder="Search by Contact No., Username, or Order ID"

// //     value={searchQuery}
// //     onChange={(e) => setSearchQuery(e.target.value)}
// //     style={{ width: "400px" }} // Adjust the width as needed
// //   />
// // </div> */}
// // <div className="search-bar-container" style={{ marginLeft: "20px", marginTop: "20px" }}>
// //       {/* <input
// //         type="text"
// //         className="search-bar"
// //         placeholder="Search by Contact No., Username, or Order ID"
// //         value={searchQuery}
// //         onChange={handleSearchChange}
// //         style={{ width: "400px" }}
// //       /> */}
// //       {/* <input
// //   type="text"
// //   value={searchQuery}
// //   onChange={handleSearchChange}
// //   onKeyPress={handleSearchKeyPress} // Add key press handler
// //   placeholder="Search"
// // /> */}

// // <input
// //   type="text"
// //   value={searchQuery}
// //   onChange={handleSearchChange}
// //   onKeyPress={handleSearchKeyPress} // Add key press handler
// //   placeholder="Search orderId or contacts..."
// //   style={{ width: '300px' }} // Increase width of the search bar
// // />

// //     </div>

// //           <div className="table-container">
// //             {loading ? (
// //               <div className="loading">Loading...</div>
// //             ) : (
// //               <div className="table-wrapper">
// //                 <table className="book-table">
// //                   <thead>
// //                     <tr>
// //                       <th>S.No</th>
// //                       <th>Order ID</th>
// //                       <th>User Name</th>
// //                       <th>Book Title</th>
// //                       <th style={{ width: "80px" }}>Qty</th>
// //                       <th style={{ width: "120px" }}>Contact</th>
// //                       <th>Email ID</th>
// //                       <th>Order Date</th>
// //                       <th>Shipping Add.</th>
// //                       <th>Dispatch Date</th>
// //                       <th>Tracking ID</th>
// //                       <th>Delivery Status</th>
// //                       <th>Actions</th>
// //                     </tr>
// //                   </thead>

// //                   {/* <tbody>
// //                     {paddedRows.map((row, index) => (
// //                       <tr key={index}>
// //                         <td>
// //                           {row.ninOrderId
// //                             ? currentPage === 1
// //                               ? index + 1
// //                               : index + 1 + (currentPage - 1) * 10
// //                             : ""}
// //                         </td>
// //                         <td>{row.ninOrderId || ""}</td>
// //                         <td>{row.name || ""}</td>
// //                         <td>
// //                           {row.bookDetails && row.bookDetails[0]?.publicationTitle ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup1(row.bookDetails)}
// //                             >
// //                               {row.bookDetails[0]?.publicationTitle.length > 15
// //                                 ? row.bookDetails[0]?.publicationTitle.substring(0, 15) + "..."
// //                                 : row.bookDetails[0]?.publicationTitle}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>{row.totalBook || ""}</td>
// //                         <td>{row.contact || ""}</td>

// //                         <td>
// //                           {row.email ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.email)}
// //                             >
// //                               {row.email.length > 15
// //                                 ? row.email.substring(0, 15) + "..."
// //                                 : row.email}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>

// //                         <td>{row.orderedDate || ""}</td>
// //                         <td>
// //                           {row.shippingAddress ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.shippingAddress)}
// //                             >
// //                               {row.shippingAddress.length > 15
// //                                 ? row.shippingAddress.substring(0, 15) + "..."
// //                                 : row.shippingAddress}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>

// //                         <td>
// //                           {row.ninOrderId && (
// //                             <DatePicker
// //                               selected={
// //                                 rowData[row.ninOrderId]?.dispatchDate
// //                                   ? new Date(rowData[row.ninOrderId]?.dispatchDate)
// //                                   : row.dispatchDate
// //                                   ? new Date(row.dispatchDate)
// //                                   : null
// //                               }
// //                               onChange={(date) =>
// //                                 handleInputChange(row.ninOrderId, "dispatchDate", format(date, "yyyy-MM-dd"))
// //                               }
// //                               placeholderText="Select Date"
// //                               dateFormat="yyyy-MM-dd"
// //                               className="date-picker inline-input"
// //                             />
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.ninOrderId && (
// //                             <input
// //                               type="text"
// //                               className="inline-input"
// //                               value={rowData[row.ninOrderId]?.trackingId || row.trackingId || ""}
// //                               onChange={(e) =>
// //                                 handleInputChange(row.ninOrderId, "trackingId", e.target.value)
// //                               }
// //                               placeholder="Enter Tracking ID"
// //                             />
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.ninOrderId && (
// //                             <select
// //                               value={rowData[row.ninOrderId]?.deliveryStatus || row.deliveryStatus || ""}
// //                               onChange={(e) =>
// //                                 handleInputChange(row.ninOrderId, "deliveryStatus", e.target.value)
// //                               }
// //                               className="inline-input"
// //                             >
// //                               <option value="Processing">In Process</option>
// //                               <option value="Dispatched">Dispatched</option>
// //                               <option value="Delivered">Delivered</option>
// //                             </select>
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.ninOrderId && (
// //                             <button onClick={() => handleUpdateRow(row.ninOrderId)} className="submit-btn">
// //                               Save
// //                             </button>
// //                           )}
// //                         </td>
// //                       </tr>
// //                     ))}
// //                   </tbody> */}


// //                   <tbody>
// //   {filteredData.map((row, index) => (
// //     <tr key={index}>
// //       <td>
// //         {row.ninOrderId
// //           ? currentPage === 1
// //             ? index + 1
// //             : index + 1 + (currentPage - 1) * rowsPerPage
// //           : ""}
// //       </td>
// //       <td>{row.ninOrderId || ""}</td>
// //       <td>{row.name || ""}</td>
// //       <td>
// //         {row.bookDetails && row.bookDetails[0]?.publicationTitle ? (
// //           <span
// //             className="show-more"
// //             onClick={() => handleShowPopup1(row.bookDetails)}
// //           >
// //             {row.bookDetails[0]?.publicationTitle.length > 15
// //               ? row.bookDetails[0]?.publicationTitle.substring(0, 15) + "..."
// //               : row.bookDetails[0]?.publicationTitle}
// //           </span>
// //         ) : (
// //           ""
// //         )}
// //       </td>
// //       <td>{row.totalBook || ""}</td>
// //       <td>{row.contact || ""}</td>
// //       <td>
// //         {row.email ? (
// //           <span
// //             className="show-more"
// //             onClick={() => handleShowPopup(row.email)}
// //           >
// //             {row.email.length > 15
// //               ? row.email.substring(0, 15) + "..."
// //               : row.email}
// //           </span>
// //         ) : (
// //           ""
// //         )}
// //       </td>
// //       <td>{row.orderedDate || ""}</td>
// //       <td>
// //         {row.shippingAddress ? (
// //           <span
// //             className="show-more"
// //             onClick={() => handleShowPopup(row.shippingAddress)}
// //           >
// //             {row.shippingAddress.length > 15
// //               ? row.shippingAddress.substring(0, 15) + "..."
// //               : row.shippingAddress}
// //           </span>
// //         ) : (
// //           ""
// //         )}
// //       </td>
// //       <td>
// //         {row.ninOrderId && (
// //           <DatePicker
// //             selected={
// //               rowData[row.ninOrderId]?.dispatchDate
// //                 ? new Date(rowData[row.ninOrderId]?.dispatchDate)
// //                 : row.dispatchDate
// //                 ? new Date(row.dispatchDate)
// //                 : null
// //             }
// //             onChange={(date) =>
// //               handleInputChange(row.ninOrderId, "dispatchDate", format(date, "yyyy-MM-dd"))
// //             }
// //             placeholderText="Select Date"
// //             dateFormat="yyyy-MM-dd"
// //             className="date-picker inline-input"
// //           />
// //         )}
// //       </td>
// //       <td>
// //         {row.ninOrderId && (
// //           <input
// //             type="text"
// //             className="inline-input"
// //             value={rowData[row.ninOrderId]?.trackingId || row.trackingId || ""}
// //             onChange={(e) =>
// //               handleInputChange(row.ninOrderId, "trackingId", e.target.value)
// //             }
// //             placeholder="Enter Tracking ID"
// //           />
// //         )}
// //       </td>
// //       <td>
// //         {row.ninOrderId && (
// //           <select
// //             value={rowData[row.ninOrderId]?.deliveryStatus || row.deliveryStatus || ""}
// //             onChange={(e) =>
// //               handleInputChange(row.ninOrderId, "deliveryStatus", e.target.value)
// //             }
// //             className="inline-input"
// //           >
// //             <option value="Processing">In Process</option>
// //             <option value="Dispatched">Dispatched</option>
// //             <option value="Delivered">Delivered</option>
// //           </select>
// //         )}
// //       </td>
// //       <td>
// //         {row.ninOrderId && (
// //           <button onClick={() => handleUpdateRow(row.ninOrderId)} className="submit-btn">
// //             Save
// //           </button>
// //         )}
// //       </td>
// //     </tr>
// //   ))}
// // </tbody>

// //                 </table>

           
// //                <div className="pagination">
// //   {Array.from({ length: totalPages }, (_, i) => (
// //     <button
// //       key={i}
// //       onClick={() => handlePageChange(i + 1)} // pageNumber starts from 1
// //       className={page === i + 1 ? "active" : ""}
// //     >
// //       {i + 1}
// //     </button>
// //   ))}
// // </div>
// //               </div>
// //             )}
// //           </div>

// //           {popupVisible && (
// //             <div className="popup-overlay">
// //               <div className="popup-content">
// //                 <button className="close-popup" onClick={closePopup}>
// //                   X
// //                 </button>
// //                 <div className="popup-body">
// //                   <p>{popupContent}</p>
// //                 </div>
// //               </div>
// //             </div>
// //           )}

// //           {popupVisible1 && popupContent1 && (
// //             <div className="popup-overlay">
// //               <div className="popup-content">
// //                 <button className="close-popup" onClick={() => setPopupVisible1(false)}>
// //                   X
// //                 </button>
// //                 <div className="popup-body">
// //                   <h2 className="popup-heading">Book Details</h2>
// //                   <ul>
// //                     {popupContent1.map((book, index) => (
// //                       <li key={index}>{book.publicationTitle}</li>
// //                     ))}
// //                   </ul>
// //                 </div>
// //               </div>
// //             </div>
// //           )}
// //         </main>

// //         <Footer />
// //       </div>
// //     </div>
// //   );
// // };

// // export default BookTableWithAPI;




// //============================dgwfvdbjhfwiurb==========================================





// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
// import DatePicker from "react-datepicker"; // DatePicker component
// import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
// import "./bookinfo.scss"; // External styles
// import Header from "./header"; // Header component
// import Footer from "./footer"; // Footer component
// import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL
// import { format } from 'date-fns';
// import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
// const BookTableWithAPI = () => {
//   const [popupContent, setPopupContent] = useState(""); // Popup content
//   const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
//   const [currentPage, setCurrentPage] = useState(1); 

//  // Search query for filtering rows
//   const [rowData, setRowData] = useState({}); // Store row data
//   const rowsPerPage = 9; // Number of rows per page
//   const [notification, setNotification] = useState(""); // Message to show
//   const [notificationType, setNotificationType] = useState(""); // 'success' or 'error'
//   const [popupVisible1, setPopupVisible1] = useState(false);  // To control popup visibility
// const [popupContent1, setPopupContent1] = useState(null);  // To store book details for popup
// const [search, setSearch] = useState("");
//  const [searchQuery, setSearchQuery] = useState(""); 
// const [page, setPage] = useState(1); // Initialize page to 1 (first page)
// const [size, setSize] = useState(9); // Default size
// const [tableData, setTableData] = useState([]);
// const [loading, setLoading] = useState(false);
//   const [totalPages, setTotalPages] = useState(0); // Total number of pages
  
//   useEffect(() => {
//     fetchData(currentPage);
//   }, [currentPage]);

//   const [openCalendarId, setOpenCalendarId] = useState(null); // Track the row where calendar is open

//   useEffect(() => {
//     fetchData();
   
//   }, []);


// const handleShowPopup1 = (bookDetails) => {
//   setPopupContent1(bookDetails);  // Set the book details for popup
//   setPopupVisible1(true); // Show the popup
// };

// const fetchData = async (page) => {
//   try {
//     setLoading(true);
//     const key = localStorage.getItem("jwtToken");

//     // Make sure page is a valid number
//     if (!page || page < 1) {
//       console.error("Invalid page number:", page);
//       return;
//     }

//     const response = await axios.get(
//       `${BASE_URL}/dashboard/orders?search=${searchQuery}&page=${page - 1}&size=${size}`,
//       {
//         headers: {
//           Auth: `Bearer ${key}`,
//         },
//       }
//     );

//     console.log("Response data:", response.data);

//     if (response.data && response.data.content) {
//       setTableData(response.data.content);
//       setTotalPages(response.data.totalPages); // Set total pages for pagination
//     } else {
//       console.error("Invalid data format received:", response.data);
//     }
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     alert("Failed to fetch data from the server.");
//   } finally {
//     setLoading(false);
//   }
// };

// const handleSearchChange = (e) => {
//   const value = e.target.value;
//   setSearchQuery(value);
// };

// const handleSearchKeyPress = (e) => {
//   if (e.key === 'Enter') {
//     fetchData(page); // Trigger search when Enter is pressed
//   }
// };

// const filteredData = tableData.filter((row) =>
//   row.contact?.toLowerCase().includes(searchQuery.toLowerCase()) || 
//   row.ninOrderId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
//   row.name?.toLowerCase().includes(searchQuery.toLowerCase())
// );
// useEffect(() => {
//   if (searchQuery.trim() === "") return; // Skip fetching if search query is empty
//   fetchData(page); // Fetch data on search query change
// }, [searchQuery, page]);

// const handlePageChange = (pageNumber) => {
//   setPage(pageNumber); // Update page number when a button is clicked
//   fetchData(pageNumber); // Fetch data for the selected page
// };

// useEffect(() => {
//   fetchData(page); // Fetch data when component mounts
// }, [page]); // When page changes, fetch new data

// useEffect(() => {
//   fetchData(page); // Fetch data on initial load
// }, [page]);

//   const handleShowPopup = (content) => {
//     setPopupContent(content);
//     setPopupVisible(true);
//   };

//   const closePopup = () => {
//     setPopupVisible(false);
//   };

//   const handleInputChange = (ninOrderId, field, value) => {
//     setRowData((prev) => ({
//       ...prev,
//       [ninOrderId]: {
//         ...prev[ninOrderId],
//         [field]: value,
//       },
//     }));
//   };

//   const handleUpdateRow = async (ninOrderId) => {
//     const updatedRow = rowData[ninOrderId]; // Get the updated row data
//     const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage

//     try {
//       // Send PUT request to update row data
//       const response = await axios.put(
//         `${BASE_URL}/dashboard/update-orders?ninOrderId=${ninOrderId}`,
//         {
//           deliveryStatus: updatedRow.deliveryStatus,
//           trackingId: updatedRow.trackingId,
//           dispatchDate: updatedRow.dispatchDate,
//         },
//         {
//           headers: {
//             Auth: `Bearer ${key}`,
//           },
//         }
//       );

//       setNotification("Row updated successfully!");
//       setNotificationType("success");

//       // Hide the notification after 5 seconds
//       setTimeout(() => {
//         setNotification("");
//       }, 5000);

//       fetchData(); // Refresh table data after update
//     } catch (error) {
//       console.error("Error updating row:", error);
//       setNotification("Failed to update the row. Please try again.");
//       setNotificationType("error");

//       // Hide the notification after 5 seconds
//       setTimeout(() => {
//         setNotification("");
//       }, 10000);
//     }
//   };

//   const indexOfLastRow = currentPage * rowsPerPage;
//   const indexOfFirstRow = indexOfLastRow - rowsPerPage;




//   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

//   const paddedRows = [...currentRows];
//   while (paddedRows.length < rowsPerPage) {
//     paddedRows.push({}); 
//   }


//   return (
//     <div id="root">
//       <div className="page-container">
//         <RedirectComponent />
//         <Header />

//         <main>
//           {notification && (
//             <div className={`notification ${notificationType}`}>
//               {notification}
//             </div>
//           )}

// <div className="search-bar-container" style={{ marginLeft: "20px", marginTop: "20px" }}>
  

// <input
//   type="text"
//   value={searchQuery}
//   onChange={handleSearchChange}
//   onKeyPress={handleSearchKeyPress} // Add key press handler
//   placeholder="Search orderId or contacts..."
//   style={{ width: '300px' }} // Increase width of the search bar
// />

//     </div>

//           <div className="table-container">
//             {loading ? (
//               <div className="loading">Loading...</div>
//             ) : (
//               <div className="table-wrapper">
//                 <table className="book-table">
//                   <thead>
//                     <tr>
//                       <th>S.No</th>
//                       <th>Order ID</th>
//                       <th>User Name</th>
//                       <th>Book Title</th>
//                       <th style={{ width: "80px" }}>Qty</th>
//                       <th style={{ width: "120px" }}>Contact</th>
//                       <th>Email ID</th>
//                       <th>Order Date</th>
//                       <th>Shipping Add.</th>
//                       <th>Dispatch Date</th>
//                       <th>Tracking ID</th>
//                       <th>Delivery Status</th>
//                       <th>Actions</th>
//                     </tr>
//                   </thead>


// {/* 
//                   <tbody>
//   {filteredData.map((row, index) => (
//     <tr key={index}>
//       <td>
//         {row.ninOrderId
//           ? currentPage === 1
//             ? index + 1
//             : index + 1 + (currentPage - 1) * rowsPerPage
//           : ""}
//       </td>

//       <td>
//   {row.ninOrderId ? (
//     <span
//       className="show-more"
//       onClick={() => handleShowPopup(row.ninOrderId)}
//     >
//       {row.ninOrderId.length > 15
//         ? row.ninOrderId.substring(0, 15) + "..."
//         : row.ninOrderId}
//     </span>
//   ) : (
//     ""
//   )}
// </td>
//       <td>{row.name || ""}</td>
//       <td>
//         {row.bookDetails && row.bookDetails[0]?.publicationTitle ? (
//           <span
//             className="show-more"
//             onClick={() => handleShowPopup1(row.bookDetails)}
//           >
//             {row.bookDetails[0]?.publicationTitle.length > 15
//               ? row.bookDetails[0]?.publicationTitle.substring(0, 15) + "..."
//               : row.bookDetails[0]?.publicationTitle}
//           </span>
//         ) : (
//           ""
//         )}
//       </td>
//       <td>{row.totalBook || ""}</td>
//       <td>{row.contact || ""}</td>
//       <td>
//         {row.email ? (
//           <span
//             className="show-more"
//             onClick={() => handleShowPopup(row.email)}
//           >
//             {row.email.length > 15
//               ? row.email.substring(0, 15) + "..."
//               : row.email}
//           </span>
//         ) : (
//           ""
//         )}
//       </td>
//       <td>{row.orderedDate || ""}</td>
//       <td>
//         {row.shippingAddress ? (
//           <span
//             className="show-more"
//             onClick={() => handleShowPopup(row.shippingAddress)}
//           >
//             {row.shippingAddress.length > 15
//               ? row.shippingAddress.substring(0, 15) + "..."
//               : row.shippingAddress}
//           </span>
//         ) : (
//           ""
//         )}
//       </td>
//       <td>
//         {row.ninOrderId && (
//           <DatePicker
//             selected={
//               rowData[row.ninOrderId]?.dispatchDate
//                 ? new Date(rowData[row.ninOrderId]?.dispatchDate)
//                 : row.dispatchDate
//                 ? new Date(row.dispatchDate)
//                 : null
//             }
//             onChange={(date) =>
//               handleInputChange(row.ninOrderId, "dispatchDate", format(date, "yyyy-MM-dd"))
//             }
//             placeholderText="Select Date"
//             dateFormat="yyyy-MM-dd"
//             className="date-picker inline-input"
//           />
//         )}
//       </td>
//       <td>
//         {row.ninOrderId && (
//           <input
//             type="text"
//             className="inline-input"
//             value={rowData[row.ninOrderId]?.trackingId || row.trackingId || ""}
//             onChange={(e) =>
//               handleInputChange(row.ninOrderId, "trackingId", e.target.value)
//             }
//             placeholder="Enter Tracking ID"
//           />
//         )}
//       </td>
//       <td>
//         {row.ninOrderId && (
//           <select
//             value={rowData[row.ninOrderId]?.deliveryStatus || row.deliveryStatus || ""}
//             onChange={(e) =>
//               handleInputChange(row.ninOrderId, "deliveryStatus", e.target.value)
//             }
//             className="inline-input"
//           >
//             <option value="Processing">In Process</option>
//             <option value="Dispatched">Dispatched</option>
//             <option value="Delivered">Delivered</option>
//           </select>
//         )}
//       </td>
//       <td>
//         {row.ninOrderId && (
//           <button onClick={() => handleUpdateRow(row.ninOrderId)} className="submit-btn">
//             Save
//           </button>
//         )}
//       </td>
//     </tr>
//   ))}
// </tbody> */}

// <tbody>
//   {filteredData.map((row, index) => (
//     <tr key={index}>
//       <td>
//         <span className="bullet-point">•</span>
//       </td>
//       <td>
//         {row.ninOrderId ? (
//           <span
//             className="show-more"
//             onClick={() => handleShowPopup(row.ninOrderId)}
//           >
//             {row.ninOrderId.length > 15
//               ? row.ninOrderId.substring(0, 15) + "..."
//               : row.ninOrderId}
//           </span>
//         ) : (
//           ""
//         )}
//       </td>
//       <td>{row.name || ""}</td>
//       <td>
//         {row.bookDetails && row.bookDetails[0]?.publicationTitle ? (
//           <span
//             className="show-more"
//             onClick={() => handleShowPopup1(row.bookDetails)}
//           >
//             {row.bookDetails[0]?.publicationTitle.length > 15
//               ? row.bookDetails[0]?.publicationTitle.substring(0, 15) + "..."
//               : row.bookDetails[0]?.publicationTitle}
//           </span>
//         ) : (
//           ""
//         )}
//       </td>
//       <td>{row.totalBook || ""}</td>
//       <td>{row.contact || ""}</td>
//       <td>
//         {row.email ? (
//           <span
//             className="show-more"
//             onClick={() => handleShowPopup(row.email)}
//           >
//             {row.email.length > 15
//               ? row.email.substring(0, 15) + "..."
//               : row.email}
//           </span>
//         ) : (
//           ""
//         )}
//       </td>
//       <td>{row.orderedDate || ""}</td>
//       <td>
//         {row.shippingAddress ? (
//           <span
//             className="show-more"
//             onClick={() => handleShowPopup(row.shippingAddress)}
//           >
//             {row.shippingAddress.length > 15
//               ? row.shippingAddress.substring(0, 15) + "..."
//               : row.shippingAddress}
//           </span>
//         ) : (
//           ""
//         )}
//       </td>
//       <td>
//         {row.ninOrderId && (
//           <DatePicker
//             selected={
//               rowData[row.ninOrderId]?.dispatchDate
//                 ? new Date(rowData[row.ninOrderId]?.dispatchDate)
//                 : row.dispatchDate
//                 ? new Date(row.dispatchDate)
//                 : null
//             }
//             onChange={(date) =>
//               handleInputChange(row.ninOrderId, "dispatchDate", format(date, "yyyy-MM-dd"))
//             }
//             placeholderText="Select Date"
//             dateFormat="yyyy-MM-dd"
//             className="date-picker inline-input"
//           />
//         )}
//       </td>
//       <td>
//         {row.ninOrderId && (
//           <input
//             type="text"
//             className="inline-input"
//             value={rowData[row.ninOrderId]?.trackingId || row.trackingId || ""}
//             onChange={(e) =>
//               handleInputChange(row.ninOrderId, "trackingId", e.target.value)
//             }
//             placeholder="Enter Tracking ID"
//           />
//         )}
//       </td>
//       <td>
//         {row.ninOrderId && (
//           <select
//             value={rowData[row.ninOrderId]?.deliveryStatus || row.deliveryStatus || ""}
//             onChange={(e) =>
//               handleInputChange(row.ninOrderId, "deliveryStatus", e.target.value)
//             }
//             className="inline-input"
//           >
//             <option value="Processing">In Process</option>
//             <option value="Dispatched">Dispatched</option>
//             <option value="Delivered">Delivered</option>
//           </select>
//         )}
//       </td>
//       <td>
//         {row.ninOrderId && (
//           <button onClick={() => handleUpdateRow(row.ninOrderId)} className="submit-btn">
//             Save
//           </button>
//         )}
//       </td>
//     </tr>
//   ))}
// </tbody>


//                 </table>

           
//                <div className="pagination">
//   {Array.from({ length: totalPages }, (_, i) => (
//     <button
//       key={i}
//       onClick={() => handlePageChange(i + 1)} // pageNumber starts from 1
//       className={page === i + 1 ? "active" : ""}
//     >
//       {i + 1}
//     </button>
//   ))}
// </div>
//               </div>
//             )}
//           </div>

//           {popupVisible && (
//             <div className="popup-overlay">
//               <div className="popup-content">
//                 <button className="close-popup" onClick={closePopup}>
//                   X
//                 </button>
//                 <div className="popup-body">
//                   <p>{popupContent}</p>
//                 </div>
//               </div>
//             </div>
//           )}

//           {/* {popupVisible1 && popupContent1 && (
//             <div className="popup-overlay">
//               <div className="popup-content">
//                 <button className="close-popup" onClick={() => setPopupVisible1(false)}>
//                   X
//                 </button>
//                 <div className="popup-body">
//                   <h2 className="popup-heading">Book Details</h2>
//                   <ul>
//                     {popupContent1.map((book, index) => (
//                       <li key={index}>{book.publicationTitle}</li>
//                     ))}
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           )} */}

//           {popupVisible1 && popupContent1 && (
//   <div className="popup-overlay">
//     <div className="popup-content">
//       <button className="close-popup" onClick={() => setPopupVisible1(false)}>
//         X
//       </button>
//       <div className="popup-body">
//         <h2 className="popup-heading">Book Details</h2>
//         <div className="book-list">
//           {popupContent1.map((book, index) => (
//             <div key={index} className="book-details">
//               <p className="book-title"><strong>Title:</strong> {book.publicationTitle}</p>
//               <p className="book-quantity"><strong>Quantity:</strong> {book.quantity}</p>
//               <p className="book-price"><strong>Price:</strong> ₹{book.price}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   </div>
// )}
//         </main>

//         <Footer />
//       </div>
//     </div>
//   );
// };

// export default BookTableWithAPI;














//================================2 jan final code 




import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
import DatePicker from "react-datepicker"; // DatePicker component
import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
import "./bookinfo.scss"; // External styles
import Header from "./header"; // Header component
import Footer from "./footer"; // Footer component
import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL
import { format } from 'date-fns';
import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";


const BookTableWithAPI = () => {
  const [popupContent, setPopupContent] = useState(""); // Popup content
  const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
  const [currentPage, setCurrentPage] = useState(1); 
  const [rowData, setRowData] = useState({}); // Store row data
  const rowsPerPage = 9; // Number of rows per page
  const [notification, setNotification] = useState(""); // Message to show
  const [notificationType, setNotificationType] = useState(""); // 'success' or 'error'
  const [popupVisible1, setPopupVisible1] = useState(false);  // To control popup visibility
  const [popupContent1, setPopupContent1] = useState(null);  // To store book details for popup
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); 
  const [page, setPage] = useState(1); // Initialize page to 1 (first page)
  const [size, setSize] = useState(9); // Default size
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);
const [openCalendarId, setOpenCalendarId] = useState(null); // Track the row where calendar is open
useEffect(() => {
    fetchData();
   }, []);
const handleShowPopup1 = (bookDetails) => {
  setPopupContent1(bookDetails);  // Set the book details for popup
  setPopupVisible1(true); // Show the popup
};

const fetchData = async (page) => {
  try {
    setLoading(true);
    const key = localStorage.getItem("jwtToken");

    // Make sure page is a valid number
    if (!page || page < 1) {
      console.error("Invalid page number:", page);
      return;
    }

    const response = await axios.get(
      `${BASE_URL}/dashboard/orders?search=${searchQuery}&page=${page - 1}&size=${size}`,
      {
        headers: {
          Auth: `Bearer ${key}`,
        },
      }
    );

    console.log("Response data:", response.data);

    if (response.data && response.data.content) {
      setTableData(response.data.content);
      setTotalPages(response.data.totalPages); // Set total pages for pagination
    } else {
      console.error("Invalid data format received:", response.data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    alert("Failed to fetch data from the server.");
  } finally {
    setLoading(false);
  }
};

const handleSearchChange = (e) => {
  const value = e.target.value;
  setSearchQuery(value);
};

const handleSearchKeyPress = (e) => {
  if (e.key === 'Enter') {
    fetchData(page); // Trigger search when Enter is pressed
  }
};

const filteredData = tableData.filter((row) =>
  row.contact?.toLowerCase().includes(searchQuery.toLowerCase()) || 
  row.ninOrderId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
  row.name?.toLowerCase().includes(searchQuery.toLowerCase())
);
useEffect(() => {
  if (searchQuery.trim() === "") return; // Skip fetching if search query is empty
  fetchData(page); // Fetch data on search query change
}, [searchQuery, page]);

const handlePageChange = (pageNumber) => {
  setPage(pageNumber); // Update page number when a button is clicked
  fetchData(pageNumber); // Fetch data for the selected page
};

useEffect(() => {
  fetchData(page); // Fetch data when component mounts
}, [page]); // When page changes, fetch new data

useEffect(() => {
  fetchData(page); // Fetch data on initial load
}, [page]);

  const handleShowPopup = (content) => {
    setPopupContent(content);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  const handleInputChange = (ninOrderId, field, value) => {
    setRowData((prev) => ({
      ...prev,
      [ninOrderId]: {
        ...prev[ninOrderId],
        [field]: value,
      },
    }));
  };

    const handleUpdateRow = async (ninOrderId) => {
    const updatedRow = rowData[ninOrderId]; // Get the updated row data
    const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage

    try {
      // Send PUT request to update row data
      const response = await axios.put(
        `${BASE_URL}/dashboard/update-orders?ninOrderId=${ninOrderId}`,
        {
          deliveryStatus: updatedRow.deliveryStatus,
          trackingId: updatedRow.trackingId,
          dispatchDate: updatedRow.dispatchDate,
        },
        {
          headers: {
            Auth: `Bearer ${key}`,
          },
        }
      );

      setNotification("Row updated successfully!");
      setNotificationType("success");

      // Hide the notification after 5 seconds
      setTimeout(() => {
        setNotification("");
      }, 5000);

      fetchData(); // Refresh table data after update
    } catch (error) {
      console.error("Error updating row:", error);
      setNotification("Failed to update the row. Please try again.");
      setNotificationType("error");

      // Hide the notification after 5 seconds
      setTimeout(() => {
        setNotification("");
      }, 10000);
    }
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const paddedRows = [...currentRows];
  while (paddedRows.length < rowsPerPage) {
    paddedRows.push({}); 
  }

return (
  <div id="root">
    <div className="page-container">
      <RedirectComponent />
      <Header />

      <main>
        {notification && (
          <div className={`notification ${notificationType}`}>
            {notification}
          </div>
        )}

        <div className="search-bar-container" style={{ marginLeft: "20px", marginTop: "20px" }}>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyPress={handleSearchKeyPress} // Add key press handler
            placeholder="Search orderId or contacts..."
            style={{ width: '300px' }} // Increase width of the search bar
          />
        </div>

        <div className="table-container">
          {/* {loading ? (
            <div className="loading">Loading...</div>
          ) : ( */}
            <div className="table-wrapper">
              <table className="book-table">
                <thead>
                  <tr>
                    {/* <th> </th> */}
                    <th>Order ID</th>
                    <th>Name</th>
                    <th>Book Title</th>
                    <th style={{ width: "60px" }}>Qty</th>
                    <th>Total Price</th> {/* Added column for Total Price */}
                    <th style={{ width: "120px" }}>Contact</th>
                    <th>Email ID</th>
                    <th>Order Date</th>
                    <th>Shipping Add.</th>
                    <th>Payment Status</th> {/* Added column for Payment Status */}
                    <th>Dispatch Date</th>
                    <th>Tracking ID</th>
                    <th>Delivery Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {filteredData.length > 0 ? (
                    // Display actual data rows
                    paddedRows.map((row, index) => (
                      <tr key={index}>
                        {/* <td>
                          <span className="bullet-point">•</span>
                        </td> */}
                        <td>
                          {row.ninOrderId ? (
                            <span
                              className="show-more"
                              onClick={() => handleShowPopup(row.ninOrderId)}
                            >
                              {row.ninOrderId.length > 15
                                ? row.ninOrderId.substring(0, 15) + "..."
                                : row.ninOrderId}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>

                        <td>
                          {row.name ? (
                            <span
                              className="show-more"
                              onClick={() => handleShowPopup(row.name)}
                            >
                              {row.name.length > 15
                                ? row.name.substring(0, 15) + "..."
                                : row.name}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>

                        <td>
                          {row.bookDetails && row.bookDetails[0]?.publicationTitle ? (
                            <span
                              className="show-more"
                              onClick={() => handleShowPopup1(row.bookDetails)}
                            >
                              {row.bookDetails[0]?.publicationTitle.length > 15
                                ? row.bookDetails[0]?.publicationTitle.substring(0, 15) + "..."
                                : row.bookDetails[0]?.publicationTitle}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        
                        <td>{row.totalBook || ""}</td>

                        <td>
                          {/* Display Total Price (Qty * Price) */}
                          {row.bookDetails && row.bookDetails[0]?.quantity && row.bookDetails[0]?.price
                            ? `${(row.bookDetails[0]?.quantity * row.bookDetails[0]?.price).toFixed(2)}`
                            : ""
                          }
                        </td>

                        <td>{row.contact || ""}</td>

                        <td>
                          {row.email ? (
                            <span
                              className="show-more"
                              onClick={() => handleShowPopup(row.email)}
                            >
                              {row.email.length > 15
                                ? row.email.substring(0, 15) + "..."
                                : row.email}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>

                        <td>{row.orderedDate || ""}</td>

                        <td>
                          {row.shippingAddress ? (
                            <span
                              className="show-more"
                              onClick={() => handleShowPopup(row.shippingAddress)}
                            >
                              {row.shippingAddress.length > 15
                                ? row.shippingAddress.substring(0, 15) + "..."
                                : row.shippingAddress}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>

                        {/* <td>
                         
                          <span
                            className={`payment-status ${
                              row.paymentConfirmed ? "confirmed" : "not-confirmed"
                            }`}
                          >
                            {row.paymentConfirmed ? "Confirmed" : "Not Confirmed"}
                          </span>
                        </td> */}

                        <td>
  <span
    className={`payment-status ${
      row && row.paymentConfirmed === true ? "confirmed" : "not-confirmed"
    }`}
  >
    {row && row.paymentConfirmed !== undefined
      ? row.paymentConfirmed
        ? "Confirmed"
        : "Not Confirmed"
      : ""}
  </span>
</td>


                        <td>
                          {row.ninOrderId && (
                            <DatePicker
                              selected={
                                rowData[row.ninOrderId]?.dispatchDate
                                  ? new Date(rowData[row.ninOrderId]?.dispatchDate)
                                  : row.dispatchDate
                                  ? new Date(row.dispatchDate)
                                  : null
                              }
                              onChange={(date) =>
                                handleInputChange(row.ninOrderId, "dispatchDate", format(date, "yyyy-MM-dd"))
                              }
                              placeholderText="Select Date"
                              dateFormat="yyyy-MM-dd"
                              className="date-picker inline-input"
                            />
                          )}
                        </td>

                        <td>
                          {row.ninOrderId && (
                            <input
                              type="text"
                              className="inline-input"
                              value={rowData[row.ninOrderId]?.trackingId || row.trackingId || ""}
                              onChange={(e) =>
                                handleInputChange(row.ninOrderId, "trackingId", e.target.value)
                              }
                              placeholder="Enter Tracking ID"
                            />
                          )}
                        </td>

                        <td>
                          {row.ninOrderId && (
                            <select
                              value={rowData[row.ninOrderId]?.deliveryStatus || row.deliveryStatus || ""}
                              onChange={(e) =>
                                handleInputChange(row.ninOrderId, "deliveryStatus", e.target.value)
                              }
                              className="inline-input"
                            >
                              <option value="Processing">In Process</option>
                              <option value="Dispatched">Dispatched</option>
                              <option value="Delivered">Delivered</option>
                            </select>
                          )}
                        </td>

                        <td>
                          {row.ninOrderId && (
                            <button onClick={() => handleUpdateRow(row.ninOrderId)} className="submit-btn">
                              Save
                            </button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    // Add empty rows to make sure the table shows 9 rows
                    Array.from({ length: 9 - filteredData.length }).map((_, index) => (
                      <tr key={index}>
                        <td colSpan="13" style={{ textAlign: "center" }}>No Data</td> {/* Or leave the cells empty */}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <div className="pagination">
                {Array.from({ length: totalPages }, (_, i) => (
                  <button
                    key={i}
                    onClick={() => handlePageChange(i + 1)} 
                    className={page === i + 1 ? "active" : ""}
                  >
                    {i + 1}
                  </button>
                ))}
              </div>
            </div>
          {/* )} */}
        </div>

        {popupVisible && (
          <div className="popup-overlay">
            <div className="popup-content">
              <button className="close-popup" onClick={closePopup}>
                X
              </button>
              <div className="popup-body">
                <p>{popupContent}</p>
              </div>
            </div>
          </div>
        )}

        {popupVisible1 && popupContent1 && (
          <div className="popup-overlay">
            <div className="popup-content">
              <button className="close-popup" onClick={() => setPopupVisible1(false)}>
                X
              </button>
              <div className="popup-body">
                <h2 className="popup-heading">Book Details</h2>
                <div className="book-list">
                  {popupContent1.map((book, index) => (
                    <div key={index} className="book-details">
                      <p className="book-title"><strong>Title:</strong> {book.publicationTitle}</p>
                      <p className="book-quantity"><strong>Quantity:</strong> {book.quantity}</p>
                      <p className="book-price"><strong>Price:</strong> ₹{book.price}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </main>

      <Footer />
    </div>
  </div>
);



};

export default BookTableWithAPI;