
// // import React, { useState, useEffect } from "react";
// // import axios from "axios";
// // import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
// // import DatePicker from "react-datepicker"; // DatePicker component
// // import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
// // import "../bookinfo/bookinfo.scss"; // External styles
// // import Header from "./header"; // Header component
// // import Footer from "../bookinfo/footer"; // Footer component
// // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL
// // import { format } from 'date-fns';
// // import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";


// // const BookTableWithAPI = () => {
// //   const [popupContent, setPopupContent] = useState(""); // Popup content
// //   const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
// //   const [currentPage, setCurrentPage] = useState(1); 
// //   const [rowData, setRowData] = useState({}); // Store row data
// //   const rowsPerPage = 8; // Number of rows per page
// //   const [notification, setNotification] = useState(""); // Message to show
// //   const [notificationType, setNotificationType] = useState(""); // 'success' or 'error'
// //   const [popupVisible1, setPopupVisible1] = useState(false);  // To control popup visibility
// //   const [popupContent1, setPopupContent1] = useState(null);  // To store book details for popup
// //   const [search, setSearch] = useState("");
// //   const [searchQuery, setSearchQuery] = useState(""); 
// //   const [page, setPage] = useState(1); // Initialize page to 1 (first page)
// //   const [size, setSize] = useState(9); // Default size
// //   const [tableData, setTableData] = useState([]);
// //   const [loading, setLoading] = useState(false);
// //   const [totalPages, setTotalPages] = useState(0); // Total number of pages
// // const [selectedOrderId, setSelectedOrderId] = useState(null); // Make sure you set the selected order ID elsewhere
// // const [showConfirmBox, setShowConfirmBox] = useState(false);
// // // const [confirmButtonColor, setConfirmButtonColor] = useState('red');
// // const [updatedOrderId, setUpdatedOrderId] = useState(null); 


// // // const [buttonColors, setButtonColors] = useState({});
// // const [buttonColors, setButtonColors] = useState({}); // Track the button colors


// //   useEffect(() => {
// //     fetchData(currentPage);
// //   }, [currentPage]);
// // const [openCalendarId, setOpenCalendarId] = useState(null); // Track the row where calendar is open
// // useEffect(() => {
// //     fetchData();
// //    }, []);
// // const handleShowPopup1 = (bookDetails) => {
// //   setPopupContent1(bookDetails);  // Set the book details for popup
// //   setPopupVisible1(true); // Show the popup
// // };
// // // const handlePageChange = (newPage) => {
// // //   setCurrentPage(newPage); // Update current page
// // //   fetchData(newPage); // Fetch data for the new page
// // // };

// // const fetchData = async (page) => {
// //   try {
// //     setLoading(true);
// //     const key = localStorage.getItem("jwtToken");

// //     // Make sure page is a valid number
// //     if (!page || page < 1) {
// //       console.error("Invalid page number:", page);
// //       return;
// //     }

// //     const response = await axios.get(
// //       `${BASE_URL}/dashboard/orders?search=${searchQuery}&page=${page - 1}&size=${size}`,
// //       {
// //         headers: {
// //           Auth: `Bearer ${key}`,
// //         },
// //       }
// //     );

// //     console.log("Response data:", response.data);

// //     if (response.data && response.data.content) {
// //       setTableData(response.data.content);
// //       setTotalPages(response.data.totalPages); // Set total pages for pagination
// //     } else {
// //       console.error("Invalid data format received:", response.data);
// //     }
// //   } catch (error) {
// //     console.error("Error fetching data:", error);
// //     alert("Failed to fetch data from the server.");
// //   } finally {
// //     setLoading(false);
// //   }
// // };

// // const handleSearchChange = (e) => {
// //   const value = e.target.value;
// //   setSearchQuery(value);
// // };

// // const handleSearchKeyPress = (e) => {
// //   if (e.key === 'Enter') {
// //     fetchData(page); // Trigger search when Enter is pressed
// //   }
// // };

// // const filteredData = tableData.filter((row) =>
// //   row.contact?.toLowerCase().includes(searchQuery.toLowerCase()) || 
// //   row.ninOrderId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
// //   row.name?.toLowerCase().includes(searchQuery.toLowerCase())
// // );
// // useEffect(() => {
// //   if (searchQuery.trim() === "") return; // Skip fetching if search query is empty
// //   fetchData(page); // Fetch data on search query change
// // }, [searchQuery, page]);

// // const handlePageChange = (pageNumber) => {
// //   setPage(pageNumber); // Update page number when a button is clicked
// //   fetchData(pageNumber); // Fetch data for the selected page
// // };

// // useEffect(() => {
// //   fetchData(page); // Fetch data when component mounts
// // }, [page]); // When page changes, fetch new data

// // useEffect(() => {
// //   fetchData(page); // Fetch data on initial load
// // }, [page]);

// //   const handleShowPopup = (content) => {
// //     setPopupContent(content);
// //     setPopupVisible(true);
// //   };

// //   const closePopup = () => {
// //     setPopupVisible(false);
// //   };

// //   const handleInputChange = (ninOrderId, field, value) => {
// //     setRowData((prev) => ({
// //       ...prev,
// //       [ninOrderId]: {
// //         ...prev[ninOrderId],
// //         [field]: value,
// //       },
// //     }));
// //   };

// // //   const handleUpdateRow = async (ninOrderId) => {
// // //   const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage
// // // console.log("xddhdxgh",ninOrderId)
// // //   try {
// // //     // Send PUT request to confirm the payment (without additional data)
// // //     const response = await axios.put(
// // //       `${BASE_URL}/dashboard/confirm-payment?ninOrderId=${ninOrderId}`,
// // //       {},
// // //       {
// // //         headers: {
// // //           Auth: `Bearer ${key}`,
// // //         },
// // //       }
// // //     );

// // //     setNotification("Order confirmed successfully!");
// // //     setNotificationType("success");

// // //     // Hide the notification after 5 seconds
// // //     setTimeout(() => {
// // //       setNotification("");
// // //     }, 5000);

// // //     fetchData(); // Refresh table data after update
// // //   } catch (error) {
// // //     console.error("Error confirming order:", error);
// // //     setNotification("Failed to confirm order. Please try again.");
// // //     setNotificationType("error");

// // //     // Hide the notification after 5 seconds
// // //     setTimeout(() => {
// // //       setNotification("");
// // //     }, 10000);
// // //   }
// // // };

// // // const handleConfirm = () => {
// // //   // Call the API function with the selected order ID
// // //   handleUpdateRow(selectedOrderId);  // You can call your existing function here
// // //   setConfirmButtonColor('green');  // Change the button color to green after confirmation
// // //   setShowConfirmBox(false);  // Close the confirmation box
// // // };

// // const handleUpdateRow = async (ninOrderId) => {
// //   const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage
// //   console.log("xddhdxgh", ninOrderId);

// //   try {
// //     // Send PUT request to confirm the payment (without additional data)
// //     const response = await axios.put(
// //       `${BASE_URL}/dashboard/confirm-payment?ninOrderId=${ninOrderId}`,
// //       {},
// //       {
// //         headers: {
// //           Auth: `Bearer ${key}`,
// //         },
// //       }
// //     );

// //     if (response.status === 200) {
// //       setButtonColors((prevColors) => ({
// //         ...prevColors,
// //         [ninOrderId]: "green", // Set the button color to green for the specific row
// //       }));
// //     }

// //     setNotification("Order confirmed successfully!");
// //     setNotificationType("success");

// //     // Hide the notification after 5 seconds
// //     setTimeout(() => {
// //       setNotification("");
// //     }, 5000);

// //     fetchData(); // Refresh table data after update
// //   } catch (error) {
// //     console.error("Error confirming order:", error);
// //     setNotification("Failed to confirm order. Please try again.");
// //     setNotificationType("error");

// //     // Hide the notification after 5 seconds
// //     setTimeout(() => {
// //       setNotification("");
// //     }, 10000);
// //   }
// // };

// // const handleConfirm = () => {
// //   // Call the API function with the selected order ID
// //   handleUpdateRow(selectedOrderId);  // You can call your existing function here
// //   setShowConfirmBox(false);  // Close the confirmation box
// // };
// //   const indexOfLastRow = currentPage * rowsPerPage;
// //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;
// //   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

// //   const paddedRows = [...currentRows];
// //   while (paddedRows.length < rowsPerPage) {
// //     paddedRows.push({}); 
// //   }


// // return (
// //   <div id="root">
// //     <div className="page-container">
// //       <RedirectComponent />
// //       <Header />

// //       <main>
// //         {notification && (
// //           <div className={`notification ${notificationType}`}>
// //             {notification}
// //           </div>
// //         )}

// //         <div className="search-bar-container" style={{ marginLeft: "20px", marginTop: "20px" }}>
// //           <input
// //             type="text"
// //             value={searchQuery}
// //             onChange={handleSearchChange}
// //             onKeyPress={handleSearchKeyPress} // Add key press handler
// //             placeholder="Search orderId or contacts..."
// //             style={{ width: '300px' }} // Increase width of the search bar
// //           />
// //         </div>

// //         <div className="table-container">
// //           {loading ? (
// //             <div className="loading">Loading...</div>
// //           ) : (
// //             <div className="table-wrapper">
// //               <table className="book-table">
// //                 <thead>
// //                   <tr>
// //                     <th> </th>
// //                     <th>Order ID</th>
// //                     <th>RRN Number</th> {/* Added RRN Number */}
// //                     <th>Name</th>
// //                     <th>Book Title</th>
// //                     <th style={{ width: "80px" }}>Qty</th>
// //                     <th>Total Price</th> {/* Added Total Price */}
// //                     <th style={{ width: "120px" }}>Contact</th>
// //                     <th>Email ID</th>
// //                     <th>Order Date</th>
// //                     <th>Shipping Add.</th>
// //                     <th>Actions</th>
// //                   </tr>
// //                 </thead>

// //                 <tbody>
// //                   {filteredData.length > 0 ? (
// //                     paddedRows.map((row, index) => (
// //                       <tr key={index}>
// //                         <td>
// //                           <span className="bullet-point">•</span>
// //                         </td>
// //                         <td>
// //                           {row.ninOrderId ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.ninOrderId)}
// //                             >
// //                               {row.ninOrderId.length > 15
// //                                 ? row.ninOrderId.substring(0, 15) + "..."
// //                                 : row.ninOrderId}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.rrnNumber ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.rrnNumber)}
// //                             >
// //                               {row.rrnNumber.length > 15
// //                                 ? row.rrnNumber.substring(0, 15) + "..."
// //                                 : row.rrnNumber}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.name ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.name)}
// //                             >
// //                               {row.name.length > 15
// //                                 ? row.name.substring(0, 15) + "..."
// //                                 : row.name}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.bookDetails && row.bookDetails[0]?.publicationTitle ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup1(row.bookDetails)}
// //                             >
// //                               {row.bookDetails[0]?.publicationTitle.length > 15
// //                                 ? row.bookDetails[0]?.publicationTitle.substring(0, 15) + "..."
// //                                 : row.bookDetails[0]?.publicationTitle}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>{row.totalBook || ""}</td>
// //                         {/* <td>{row.totalPrice || ""}</td>  */}
// //                             <td>{row.totalPrice != null ? row.totalPrice.toFixed(2) : ''}</td>

// //                         <td>{row.contact || ""}</td>
// //                         <td>
// //                           {row.email ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.email)}
// //                             >
// //                               {row.email.length > 15
// //                                 ? row.email.substring(0, 15) + "..."
// //                                 : row.email}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>{row.orderedDate || ""}</td>
// //                         <td>
// //                           {row.shippingAddress ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.shippingAddress)}
// //                             >
// //                               {row.shippingAddress.length > 15
// //                                 ? row.shippingAddress.substring(0, 15) + "..."
// //                                 : row.shippingAddress}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
                      
// //                          {/* <td>
// //         {row.ninOrderId && (


// // <button
// //   onClick={() => {
// //     setSelectedOrderId(row.ninOrderId);  // Set the selected order ID for confirmation
// //     setShowConfirmBox(true);  // Show the confirmation box
// //   }}
// //   className="submit-btn"
// //   style={{
// //     backgroundColor: buttonColors[row.ninOrderId] || 'red', // Default color is red
// //     color: 'white',
// //   }}
// // >
// //   Confirm
// // </button>


// //         )}
// //       </td> */}

// //       <td>
// //   {row.ninOrderId && (
// //     <button
// //       onClick={() => {
// //         setSelectedOrderId(row.ninOrderId); // Set the selected order ID for confirmation
// //         setShowConfirmBox(true); // Show the confirmation box
// //       }}
// //       className="submit-btn"
// //       style={{
// //         backgroundColor: row.paymentConfirmed ? 'green' : 'red', // Green for true, red for false
// //         color: 'white',
// //       }}
// //     >
// //       Confirm
// //     </button>
// //   )}
// // </td>


      
// //                       </tr>
// //                     ))
// //                   ) : (
// //                     Array.from({ length: 9 - filteredData.length }).map((_, index) => (
// //                       <tr key={index}>
// //                         <td colSpan="10" style={{ textAlign: "center" }}>No Data</td>
// //                       </tr>
// //                     ))
// //                   )}
// //                 </tbody>
// //               </table>

// //               <div className="pagination">
// //                 {Array.from({ length: totalPages }, (_, i) => (
// //                   <button
// //                     key={i}
// //                     onClick={() => handlePageChange(i + 1)}
// //                     className={page === i + 1 ? "active" : ""}
// //                   >
// //                     {i + 1}
// //                   </button>
// //                 ))}
// //               </div>
// //             </div>
// //           )}
// //         </div>

// //         {popupVisible && (
// //           <div className="popup-overlay">
// //             <div className="popup-content">
// //               <button className="close-popup" onClick={closePopup}>
// //                 X
// //               </button>
// //               <div className="popup-body">
// //                 <p>{popupContent}</p>
// //               </div>
// //             </div>
// //           </div>
// //         )}

// //         {popupVisible1 && popupContent1 && (
// //           <div className="popup-overlay">
// //             <div className="popup-content">
// //               <button className="close-popup" onClick={() => setPopupVisible1(false)}>
// //                 X
// //               </button>
// //               <div className="popup-body">
// //                 <h2 className="popup-heading">Book Details</h2>
// //                 <div className="book-list">
// //                   {popupContent1.map((book, index) => (
// //                     <div key={index} className="book-details">
// //                       <p className="book-title"><strong>Title:</strong> {book.publicationTitle}</p>
// //                       <p className="book-quantity"><strong>Quantity:</strong> {book.quantity}</p>
// //                       <p className="book-price"><strong>Price:</strong> ₹{book.price}</p>
// //                     </div>
// //                   ))}
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //         )}
// //       </main>
// // {showConfirmBox && (
// //   <div className="confirmation-overlay">
// //     <div className="confirmation-box">
// //       <p>Are you sure you want to confirm order ID: <strong>{selectedOrderId}</strong>?</p>

// //       <div className="button-container">
// //         <button
// //           onClick={() => {
// //             handleConfirm(); // Perform confirm action
// //             // fetchData(currentPage); // Fetch data for the current page
// //             setShowConfirmBox(false); // Close the confirmation box
// //           }}
// //           className="confirm-button"
// //         >
// //           OK
// //         </button>
// //         <button
// //           onClick={() => {
// //             fetchData(currentPage); // Fetch data for the current page (no change)
// //             setShowConfirmBox(false); // Close the confirmation box
// //           }}
// //           className="cancel-button"
// //         >
// //           Cancel
// //         </button>
// //       </div>
// //     </div>
// //   </div>
// // )}



// //       <Footer />
// //     </div>
// //   </div>
// // );

// // };

// // export default BookTableWithAPI;



// //====================================================10/================================



// // import React, { useState, useEffect } from "react";
// // import axios from "axios";
// // import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
// // import DatePicker from "react-datepicker"; // DatePicker component
// // import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
// // import "../bookinfo/bookinfo.scss"; // External styles
// // import Header from "./header"; // Header component
// // import Footer from "../bookinfo/footer"; // Footer component
// // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL
// // import { format } from 'date-fns';
// // import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
// // const BookTableWithAPI = () => {
// //   const [popupContent, setPopupContent] = useState(""); // Popup content
// //   const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
// //   const [currentPage, setCurrentPage] = useState(1);
// //   const [rowData, setRowData] = useState({}); // Store row data
// //   const rowsPerPage = 8; // Number of rows per page
// //   const [notification, setNotification] = useState(""); // Message to show
// //   const [notificationType, setNotificationType] = useState(""); // 'success' or 'error'
// //   const [popupVisible1, setPopupVisible1] = useState(false);  // To control popup visibility
// //   const [popupContent1, setPopupContent1] = useState(null);  // To store book details for popup
// //   const [search, setSearch] = useState("");
// //   const [searchQuery, setSearchQuery] = useState(""); 
// //   const [page, setPage] = useState(1); // Initialize page to 1 (first page)
// //   const [size, setSize] = useState(9); // Default size
// //   const [tableData, setTableData] = useState([]);
// //   const [loading, setLoading] = useState(false);
// //   const [totalPages, setTotalPages] = useState(0); // Total number of pages
// //   const [selectedOrderId, setSelectedOrderId] = useState(null); // Make sure you set the selected order ID elsewhere
// //   const [showConfirmBox, setShowConfirmBox] = useState(false);
// //   const [updatedOrderId, setUpdatedOrderId] = useState(null); 
// //   const [buttonColors, setButtonColors] = useState({}); // Track the button colors
// //   const [openCalendarId, setOpenCalendarId] = useState(null); // Track the row where calendar is open
// //   const indexOfLastRow = currentPage * rowsPerPage;
// //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;
// //   const filteredData = tableData.filter((row) =>
// //     row.contact?.toLowerCase().includes(searchQuery.toLowerCase()) || 
// //     row.ninOrderId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
// //     row.name?.toLowerCase().includes(searchQuery.toLowerCase())
// //   );
// //   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
// // useEffect(() => {
// //   fetchData(currentPage);
// // }, [currentPage]);
// // useEffect(() => {
// //     fetchData();
// //    }, []);
// // const handleShowPopup1 = (bookDetails) => {
// //   setPopupContent1(bookDetails);  // Set the book details for popup
// //   setPopupVisible1(true); // Show the popup
// // };
// // useEffect(() => {
// //   // This effect will fetch data whenever the page or searchQuery changes
// //   fetchData(page);
// // }, [page, searchQuery]); // Trigger fetch when page or searchQuery changes


// // const fetchData = async (page) => {
// //   try {
// //     setLoading(true);
// //     const key = localStorage.getItem("jwtToken");

// //     if (!page || page < 1) {
// //       console.error("Invalid page number:", page);
// //       return;
// //     }

// //     console.log("Fetching data for page:", page);

// //     const response = await axios.get(
// //       `${BASE_URL}/dashboard/orders?search=${searchQuery}&page=${page - 1}&size=${size}`,
// //       {
// //         headers: {
// //           Auth: `Bearer ${key}`,
// //         },
// //       }
// //     );

// //     // Log the response data to verify if it's correct
// //     console.log("Response Data:", response.data);

// //     if (response.data && response.data.content) {
// //       setTableData(response.data.content);
// //       setTotalPages(response.data.totalPages);
// //     } else {
// //       console.error("Invalid data format received:", response.data);
// //     }
// //   } catch (error) {
// //     console.error("Error fetching data:", error);
// //     alert("Failed to fetch data from the server.");
// //   } finally {
// //     setLoading(false);
// //   }
// // };

// // useEffect(() => {
// //   fetchData(page);
// // }, [page]);

// // console.log("Table Data:", tableData);


// // const handleSearchChange = (e) => {
// //   const value = e.target.value;
// //   setSearchQuery(value);
// // };
// // const handleSearchKeyPress = (e) => {
// //   if (e.key === 'Enter') {
// //     fetchData(page); // Trigger search when Enter is pressed
// //   }
// // };

// // // useEffect(() => {
// // //   if (searchQuery.trim() === "") return; // Skip fetching if search query is empty
// // //   fetchData(page); // Fetch data on search query change
// // // }, [searchQuery, page]);
// // const handlePageChange = (page) => {
// //     console.log("Page clicked:", page);  // Log page to check if it's being set correctly

// //     if (page < 1 || page > totalPages) return; // Prevent invalid page change
// //     setCurrentPage(page);  // This triggers the effect and fetches new data
// // };
// // useEffect(() => {
// //   fetchData(page); // Fetch data when component mounts
// // }, [page]); // When page changes, fetch new data

// // useEffect(() => {
// //   fetchData(page); // Fetch data on initial load
// // }, [page]);

// //   const handleShowPopup = (content) => {
// //     setPopupContent(content);
// //     setPopupVisible(true);
// //   };

// //   const closePopup = () => {
// //     setPopupVisible(false);
// //   };

// //   const handleInputChange = (ninOrderId, field, value) => {
// //     setRowData((prev) => ({
// //       ...prev,
// //       [ninOrderId]: {
// //         ...prev[ninOrderId],
// //         [field]: value,
// //       },
// //     }));
// //   };



// // const handleUpdateRow = async (ninOrderId) => {
// //   const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage
// //   console.log("xddhdxgh", ninOrderId);

// //   try {
// //     // Send PUT request to confirm the payment (without additional data)
// //     const response = await axios.put(
// //       `${BASE_URL}/dashboard/confirm-payment?ninOrderId=${ninOrderId}`,
// //       {},
// //       {
// //         headers: {
// //           Auth: `Bearer ${key}`,
// //         },
// //       }
// //     );

// //     if (response.status === 200) {
// //       setButtonColors((prevColors) => ({
// //         ...prevColors,
// //         [ninOrderId]: "green", // Set the button color to green for the specific row
// //       }));
// //     }

// //     setNotification("Order confirmed successfully!");
// //     setNotificationType("success");

// //     // Hide the notification after 5 seconds
// //     setTimeout(() => {
// //       setNotification("");
// //     }, 5000);

// //     fetchData(); // Refresh table data after update
// //   } catch (error) {
// //     console.error("Error confirming order:", error);
// //     setNotification("Failed to confirm order. Please try again.");
// //     setNotificationType("error");

// //     // Hide the notification after 5 seconds
// //     setTimeout(() => {
// //       setNotification("");
// //     }, 10000);
// //   }
// // };

// // const handleConfirm = () => {

// //   handleUpdateRow(selectedOrderId);  // You can call your existing function here
// //   setShowConfirmBox(false);  // Close the confirmation box
// // };
// //   const paddedRows = [...currentRows];
// //   while (paddedRows.length < rowsPerPage) {
// //     paddedRows.push({}); 
// //   }

// // return (
// //   <div id="root">
// //     <div className="page-container">
// //       <RedirectComponent />
// //       <Header />

// //       <main>
// //         {notification && (
// //           <div className={`notification ${notificationType}`}>
// //             {notification}
// //           </div>
// //         )}

// //         <div className="search-bar-container" style={{ marginLeft: "20px", marginTop: "20px" }}>
// //           <input
// //             type="text"
// //             value={searchQuery}
// //             onChange={handleSearchChange}
// //             onKeyPress={handleSearchKeyPress} // Add key press handler
// //             placeholder="Search orderId or contacts..."
// //             style={{ width: '300px' }} // Increase width of the search bar
// //           />
// //         </div>

// //         <div className="table-container">
// //           {loading ? (
// //             <div className="loading">Loading...</div>
// //           ) : (
// //             <div className="table-wrapper">
// //               <table className="book-table">
// //                 <thead>
// //                   <tr>
// //                     <th> </th>
// //                     <th>Order ID</th>
// //                     <th>RRN Number</th> {/* Added RRN Number */}
// //                     <th>Name</th>
// //                     <th>Book Title</th>
// //                     <th style={{ width: "80px" }}>Qty</th>
// //                     <th>Total Price</th> {/* Added Total Price */}
// //                     <th style={{ width: "120px" }}>Contact</th>
// //                     <th>Email ID</th>
// //                     <th>Order Date</th>
// //                     <th>Shipping Add.</th>
// //                     <th>Actions</th>
// //                   </tr>
// //                 </thead>

// //                 <tbody>
// //                   {filteredData.length > 0 ? (
// //                     paddedRows.map((row, index) => (
// //                       <tr key={index}>
// //                         <td>
// //                           <span className="bullet-point">•</span>
// //                         </td>
// //                         <td>
// //                           {row.ninOrderId ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.ninOrderId)}
// //                             >
// //                               {row.ninOrderId.length > 15
// //                                 ? row.ninOrderId.substring(0, 15) + "..."
// //                                 : row.ninOrderId}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.rrnNumber ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.rrnNumber)}
// //                             >
// //                               {row.rrnNumber.length > 15
// //                                 ? row.rrnNumber.substring(0, 15) + "..."
// //                                 : row.rrnNumber}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.name ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.name)}
// //                             >
// //                               {row.name.length > 15
// //                                 ? row.name.substring(0, 15) + "..."
// //                                 : row.name}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.bookDetails && row.bookDetails[0]?.publicationTitle ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup1(row.bookDetails)}
// //                             >
// //                               {row.bookDetails[0]?.publicationTitle.length > 15
// //                                 ? row.bookDetails[0]?.publicationTitle.substring(0, 15) + "..."
// //                                 : row.bookDetails[0]?.publicationTitle}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>{row.totalBook || ""}</td>
// //                         {/* <td>{row.totalPrice || ""}</td>  */}
// //                             <td>{row.totalPrice != null ? row.totalPrice.toFixed(2) : ''}</td>

// //                         <td>{row.contact || ""}</td>
// //                         <td>
// //                           {row.email ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.email)}
// //                             >
// //                               {row.email.length > 15
// //                                 ? row.email.substring(0, 15) + "..."
// //                                 : row.email}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>{row.orderedDate || ""}</td>
// //                         <td>
// //                           {row.shippingAddress ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.shippingAddress)}
// //                             >
// //                               {row.shippingAddress.length > 15
// //                                 ? row.shippingAddress.substring(0, 15) + "..."
// //                                 : row.shippingAddress}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //     <td>
// //   {row.ninOrderId && (
// //     <button
// //       onClick={() => {
// //         setSelectedOrderId(row.ninOrderId); // Set the selected order ID for confirmation
// //         setShowConfirmBox(true); // Show the confirmation box
// //       }}
// //       className="submit-btn"
// //       style={{
// //         backgroundColor: row.paymentConfirmed ? "green" : "red", // Green for true, red for false
// //         color: "white",
// //       }}
// //     >
// //       Confirm
// //     </button>
// //   )}
// // </td>


      
// //                       </tr>
            
// // ))
// //   ) : (
// //     <tr>
// //       <td colSpan="10" style={{ textAlign: "center" }}>
// //         No data available for this page.
// //       </td>
// //     </tr>
// //   )}
                  
// //                 </tbody>
// //               </table>

// // <div className="pagination">
// //     {Array.from({ length: totalPages }, (_, i) => (
// //         <button
// //             key={i}
// //             onClick={() => handlePageChange(i + 1)}  // i + 1 to make it 1-based
// //             className={currentPage === i + 1 ? "active" : ""}  // Highlight active page
// //         >
// //             {i + 1}
// //         </button>
// //     ))}
// // </div>



// //             </div>
// //           )}
// //         </div>

// //         {popupVisible && (
// //           <div className="popup-overlay">
// //             <div className="popup-content">
// //               <button className="close-popup" onClick={closePopup}>
// //                 X
// //               </button>
// //               <div className="popup-body">
// //                 <p>{popupContent}</p>
// //               </div>
// //             </div>
// //           </div>
// //         )}

// //         {popupVisible1 && popupContent1 && (
// //           <div className="popup-overlay">
// //             <div className="popup-content">
// //               <button className="close-popup" onClick={() => setPopupVisible1(false)}>
// //                 X
// //               </button>
// //               <div className="popup-body">
// //                 <h2 className="popup-heading">Book Details</h2>
// //                 <div className="book-list">
// //                   {popupContent1.map((book, index) => (
// //                     <div key={index} className="book-details">
// //                       <p className="book-title"><strong>Title:</strong> {book.publicationTitle}</p>
// //                       <p className="book-quantity"><strong>Quantity:</strong> {book.quantity}</p>
// //                       <p className="book-price"><strong>Price:</strong> ₹{book.price}</p>
// //                     </div>
// //                   ))}
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //         )}
// //       </main>
// // {showConfirmBox && (
// //   <div className="confirmation-overlay">
// //     <div className="confirmation-box">
// //       <p>
// //         Are you sure you want to confirm order ID:{" "}
// //         <strong>{selectedOrderId}</strong>?
// //       </p>

// //       <div className="button-container">
// //         <button
// //           onClick={() => {
// //             handleConfirm(); // Perform confirm action
// //             fetchData(currentPage); // Use currentPage state to fetch data
// //             setShowConfirmBox(false); // Close the confirmation box
// //           }}
// //           className="confirm-button"
// //         >
// //           OK
// //         </button>
// //         <button
// //           onClick={() => {
// //             setShowConfirmBox(false); // Close the confirmation box
// //           }}
// //           className="cancel-button"
// //         >
// //           Cancel
// //         </button>
// //       </div>
// //     </div>
// //   </div>
// // )}


// //       <Footer />
// //     </div>
// //   </div>
// // );

// // };

// // export default BookTableWithAPI;

// //=====================rfqejkfrcqrdfbc===============================================





// // import React, { useState, useEffect } from "react";
// // import axios from "axios";
// // import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
// // import DatePicker from "react-datepicker"; // DatePicker component
// // import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
// // import "../bookinfo/bookinfo.scss"; // External styles
// // import Header from "./header"; // Header component
// // import Footer from "../bookinfo/footer"; // Footer component
// // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL
// // import { format } from 'date-fns';
// // import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
// // const BookTableWithAPI = () => {
// //   const [popupContent, setPopupContent] = useState(""); // Popup content
// //   const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
// //   const [currentPage, setCurrentPage] = useState(1);
// //   const [rowData, setRowData] = useState({}); // Store row data
// //   const rowsPerPage = 8; // Number of rows per page
// //   const [notification, setNotification] = useState(""); // Message to show
// //   const [notificationType, setNotificationType] = useState(""); // 'success' or 'error'
// //   const [popupVisible1, setPopupVisible1] = useState(false);  // To control popup visibility
// //   const [popupContent1, setPopupContent1] = useState(null);  // To store book details for popup
// //   const [search, setSearch] = useState("");
// //   const [searchQuery, setSearchQuery] = useState(""); 
// //   const [page, setPage] = useState(1); // Initialize page to 1 (first page)
// //   const [size, setSize] = useState(9); // Default size
// //   const [tableData, setTableData] = useState([]);
// //   const [loading, setLoading] = useState(false);
// //   const [totalPages, setTotalPages] = useState(0); // Total number of pages
// //   const [selectedOrderId, setSelectedOrderId] = useState(null); // Make sure you set the selected order ID elsewhere
// //   const [showConfirmBox, setShowConfirmBox] = useState(false);
// //   const [updatedOrderId, setUpdatedOrderId] = useState(null); 
// //   const [buttonColors, setButtonColors] = useState({}); // Track the button colors
// //   const [openCalendarId, setOpenCalendarId] = useState(null); // Track the row where calendar is open
// // const filteredData = tableData.filter((row) =>
// //   row.contact?.toLowerCase().includes(searchQuery.toLowerCase()) ||
// //   row.ninOrderId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
// //   row.name?.toLowerCase().includes(searchQuery.toLowerCase())
// // );

// // const indexOfLastRow = currentPage * rowsPerPage;
// // const indexOfFirstRow = indexOfLastRow - rowsPerPage;
// // const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);


// // useEffect(() => {
// //   console.log("Current page updated to:", currentPage);
// //   fetchData(currentPage); // Fetch data whenever currentPage changes
// // }, [currentPage]);

// // const handleShowPopup1 = (bookDetails) => {
// //   setPopupContent1(bookDetails);  // Set the book details for popup
// //   setPopupVisible1(true); // Show the popup
// // };
// // useEffect(() => {
// //   fetchData(page); // Fetch data when either page or searchQuery changes
// // }, [page, searchQuery]);
// // const fetchData = async (page) => {
// //   try {
// //     setLoading(true);
// //     const key = localStorage.getItem("jwtToken");

// //     if (!page || page < 1) {
// //       console.error("Invalid page number:", page);
// //       return;
// //     }

// //     console.log("Fetching data for page:", page);

// //     const response = await axios.get(
// //       `${BASE_URL}/dashboard/orders?search=${searchQuery}&page=${page}&size=${size}`,
// //       {
// //         headers: {
// //           Auth: `Bearer ${key}`,
// //         },
// //       }
// //     );

// //     console.log("Response Data:", response.data); // Log full response

// // if (response.data && response.data.content && response.data.content.length > 0) {
// //   setTableData(response.data.content); // Update table data with the fetched content
// //   setTotalPages(response.data.totalPages); // Update total pages
// // } else {
// //   console.error("No data found for this page");
// // }

// //   } catch (error) {
// //     console.error("Error fetching data:", error);
// //     alert("Failed to fetch data from the server.");
// //   } finally {
// //     setLoading(false);
// //   }
// // };



// // const handleSearchChange = (e) => {
// //   const value = e.target.value;
// //   setSearchQuery(value);
// // };
// // const handleSearchKeyPress = (e) => {
// //   if (e.key === 'Enter') {
// //     fetchData(page); // Trigger search when Enter is pressed
// //   }
// // };
// // const handlePageChange = (newPage) => {
// //   if (newPage >= 1 && newPage <= totalPages) {
// //     setCurrentPage(newPage); // Update currentPage for both pagination UI and fetching data
// //     fetchData(newPage); // Fetch data for the updated page
// //   }
// // };




// //   const handleShowPopup = (content) => {
// //     setPopupContent(content);
// //     setPopupVisible(true);
// //   };

// //   const closePopup = () => {
// //     setPopupVisible(false);
// //   };

// //   const handleInputChange = (ninOrderId, field, value) => {
// //     setRowData((prev) => ({
// //       ...prev,
// //       [ninOrderId]: {
// //         ...prev[ninOrderId],
// //         [field]: value,
// //       },
// //     }));
// //   };



// // const handleUpdateRow = async (ninOrderId) => {
// //   const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage
// //   console.log("xddhdxgh", ninOrderId);

// //   try {
// //     // Send PUT request to confirm the payment (without additional data)
// //     const response = await axios.put(
// //       `${BASE_URL}/dashboard/confirm-payment?ninOrderId=${ninOrderId}`,
// //       {},
// //       {
// //         headers: {
// //           Auth: `Bearer ${key}`,
// //         },
// //       }
// //     );

// //     if (response.status === 200) {
// //       setButtonColors((prevColors) => ({
// //         ...prevColors,
// //         [ninOrderId]: "green", // Set the button color to green for the specific row
// //       }));
// //     }

// //     setNotification("Order confirmed successfully!");
// //     setNotificationType("success");

// //     // Hide the notification after 5 seconds
// //     setTimeout(() => {
// //       setNotification("");
// //     }, 5000);

// //     fetchData(); // Refresh table data after update
// //   } catch (error) {
// //     console.error("Error confirming order:", error);
// //     setNotification("Failed to confirm order. Please try again.");
// //     setNotificationType("error");

// //     // Hide the notification after 5 seconds
// //     setTimeout(() => {
// //       setNotification("");
// //     }, 10000);
// //   }
// // };

// // const handleConfirm = () => {

// //   handleUpdateRow(selectedOrderId);  // You can call your existing function here
// //   setShowConfirmBox(false);  // Close the confirmation box
// // };
// //   const paddedRows = [...currentRows];
// // while (paddedRows.length < rowsPerPage) {
// //   paddedRows.push({}); // Add empty rows until the rowsPerPage limit is reached
// // }


// // return (
// //   <div id="root">
// //     <div className="page-container">
// //       <RedirectComponent />
// //       <Header />

// //       <main>
// //         {notification && (
// //           <div className={`notification ${notificationType}`}>
// //             {notification}
// //           </div>
// //         )}

// //         <div className="search-bar-container" style={{ marginLeft: "20px", marginTop: "20px" }}>
// //           <input
// //             type="text"
// //             value={searchQuery}
// //             onChange={handleSearchChange}
// //             onKeyPress={handleSearchKeyPress} // Add key press handler
// //             placeholder="Search orderId or contacts..."
// //             style={{ width: '300px' }} // Increase width of the search bar
// //           />
// //         </div>

// //         <div className="table-container">
// //           {loading ? (
// //             <div className="loading">Loading...</div>
// //           ) : (
// //             <div className="table-wrapper">
// //               <table className="book-table">
// //                 <thead>
// //                   <tr>
// //                     <th> </th>
// //                     <th>Order ID</th>
// //                     <th>RRN Number</th> {/* Added RRN Number */}
// //                     <th>Name</th>
// //                     <th>Book Title</th>
// //                     <th style={{ width: "80px" }}>Qty</th>
// //                     <th>Total Price</th> {/* Added Total Price */}
// //                     <th style={{ width: "120px" }}>Contact</th>
// //                     <th>Email ID</th>
// //                     <th>Order Date</th>
// //                     <th>Shipping Add.</th>
// //                     <th>Actions</th>
// //                   </tr>
// //                 </thead>

// //                 <tbody>
// //                   {filteredData.length > 0 ? (
// //                     paddedRows.map((row, index) => (
// //                       <tr key={index}>
// //                         <td>
// //                           <span className="bullet-point">•</span>
// //                         </td>
// //                         <td>
// //                           {row.ninOrderId ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.ninOrderId)}
// //                             >
// //                               {row.ninOrderId.length > 15
// //                                 ? row.ninOrderId.substring(0, 15) + "..."
// //                                 : row.ninOrderId}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.rrnNumber ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.rrnNumber)}
// //                             >
// //                               {row.rrnNumber.length > 15
// //                                 ? row.rrnNumber.substring(0, 15) + "..."
// //                                 : row.rrnNumber}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.name ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.name)}
// //                             >
// //                               {row.name.length > 15
// //                                 ? row.name.substring(0, 15) + "..."
// //                                 : row.name}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.bookDetails && row.bookDetails[0]?.publicationTitle ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup1(row.bookDetails)}
// //                             >
// //                               {row.bookDetails[0]?.publicationTitle.length > 15
// //                                 ? row.bookDetails[0]?.publicationTitle.substring(0, 15) + "..."
// //                                 : row.bookDetails[0]?.publicationTitle}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>{row.totalBook || ""}</td>
// //                         {/* <td>{row.totalPrice || ""}</td>  */}
// //                             <td>{row.totalPrice != null ? row.totalPrice.toFixed(2) : ''}</td>

// //                         <td>{row.contact || ""}</td>
// //                         <td>
// //                           {row.email ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.email)}
// //                             >
// //                               {row.email.length > 15
// //                                 ? row.email.substring(0, 15) + "..."
// //                                 : row.email}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>{row.orderedDate || ""}</td>
// //                         <td>
// //                           {row.shippingAddress ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.shippingAddress)}
// //                             >
// //                               {row.shippingAddress.length > 15
// //                                 ? row.shippingAddress.substring(0, 15) + "..."
// //                                 : row.shippingAddress}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //     <td>
// //   {row.ninOrderId && (
// //     <button
// //       onClick={() => {
// //         setSelectedOrderId(row.ninOrderId); // Set the selected order ID for confirmation
// //         setShowConfirmBox(true); // Show the confirmation box
// //       }}
// //       className="submit-btn"
// //       style={{
// //         backgroundColor: row.paymentConfirmed ? "green" : "red", // Green for true, red for false
// //         color: "white",
// //       }}
// //     >
// //       Confirm
// //     </button>
// //   )}
// // </td>


      
// //                       </tr>
            
// // ))
// //   ) : (
// //     <tr>
// //       <td colSpan="10" style={{ textAlign: "center" }}>
// //         No data available for this page.
// //       </td>
// //     </tr>
// //   )}
                  
// //                 </tbody>
// //               </table>

// // <div className="pagination">
// //   {Array.from({ length: totalPages }, (_, i) => (
// //     <button
// //       key={i}
// //       onClick={() => handlePageChange(i + 1)} // Pass the 1-based page number
// //       className={currentPage === i + 1 ? "active" : ""} // Highlight active page
// //     >
// //       {i + 1}
// //     </button>
// //   ))}
// // </div>




// //             </div>
// //           )}
// //         </div>

// //         {popupVisible && (
// //           <div className="popup-overlay">
// //             <div className="popup-content">
// //               <button className="close-popup" onClick={closePopup}>
// //                 X
// //               </button>
// //               <div className="popup-body">
// //                 <p>{popupContent}</p>
// //               </div>
// //             </div>
// //           </div>
// //         )}

// //         {popupVisible1 && popupContent1 && (
// //           <div className="popup-overlay">
// //             <div className="popup-content">
// //               <button className="close-popup" onClick={() => setPopupVisible1(false)}>
// //                 X
// //               </button>
// //               <div className="popup-body">
// //                 <h2 className="popup-heading">Book Details</h2>
// //                 <div className="book-list">
// //                   {popupContent1.map((book, index) => (
// //                     <div key={index} className="book-details">
// //                       <p className="book-title"><strong>Title:</strong> {book.publicationTitle}</p>
// //                       <p className="book-quantity"><strong>Quantity:</strong> {book.quantity}</p>
// //                       <p className="book-price"><strong>Price:</strong> ₹{book.price}</p>
// //                     </div>
// //                   ))}
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //         )}
// //       </main>
// // {showConfirmBox && (
// //   <div className="confirmation-overlay">
// //     <div className="confirmation-box">
// //       <p>
// //         Are you sure you want to confirm order ID:{" "}
// //         <strong>{selectedOrderId}</strong>?
// //       </p>

// //       <div className="button-container">
// //         <button
// //           onClick={() => {
// //             handleConfirm(); // Perform confirm action
// //             fetchData(currentPage); // Use currentPage state to fetch data
// //             setShowConfirmBox(false); // Close the confirmation box
// //           }}
// //           className="confirm-button"
// //         >
// //           OK
// //         </button>
// //         <button
// //           onClick={() => {
// //             setShowConfirmBox(false); // Close the confirmation box
// //           }}
// //           className="cancel-button"
// //         >
// //           Cancel
// //         </button>
// //       </div>
// //     </div>
// //   </div>
// // )}


// //       <Footer />
// //     </div>
// //   </div>
// // );

// // };

// // export default BookTableWithAPI;





// // import React, { useState, useEffect } from "react";
// // import axios from "axios";
// // import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
// // import DatePicker from "react-datepicker"; // DatePicker component
// // import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
// // import "../bookinfo/bookinfo.scss"; // External styles
// // import Header from "./header"; // Header component
// // import Footer from "../bookinfo/footer"; // Footer component
// // import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL
// // import { format } from 'date-fns';
// // import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";


// // const BookTableWithAPI = () => {
// //   const [popupContent, setPopupContent] = useState(""); // Popup content
// //   const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
// //   const [currentPage, setCurrentPage] = useState(1); 
// //   const [rowData, setRowData] = useState({}); // Store row data
// //   const rowsPerPage = 8; // Number of rows per page
// //   const [notification, setNotification] = useState(""); // Message to show
// //   const [notificationType, setNotificationType] = useState(""); // 'success' or 'error'
// //   const [popupVisible1, setPopupVisible1] = useState(false);  // To control popup visibility
// //   const [popupContent1, setPopupContent1] = useState(null);  // To store book details for popup
// //   const [search, setSearch] = useState("");
// //   const [searchQuery, setSearchQuery] = useState(""); 
// //   const [page, setPage] = useState(1); // Initialize page to 1 (first page)
// //   const [size, setSize] = useState(9); // Default size
// //   const [tableData, setTableData] = useState([]);
// //   const [loading, setLoading] = useState(false);
// //   const [totalPages, setTotalPages] = useState(0); // Total number of pages
// // const [selectedOrderId, setSelectedOrderId] = useState(null); // Make sure you set the selected order ID elsewhere
// // const [showConfirmBox, setShowConfirmBox] = useState(false);
// // // const [confirmButtonColor, setConfirmButtonColor] = useState('red');
// // const [updatedOrderId, setUpdatedOrderId] = useState(null); 


// // // const [buttonColors, setButtonColors] = useState({});
// // const [buttonColors, setButtonColors] = useState({}); // Track the button colors


// //   useEffect(() => {
// //     fetchData(currentPage);
// //   }, [currentPage]);
// // const [openCalendarId, setOpenCalendarId] = useState(null); // Track the row where calendar is open
// // useEffect(() => {
// //     fetchData();
// //    }, []);
// // const handleShowPopup1 = (bookDetails) => {
// //   setPopupContent1(bookDetails);  // Set the book details for popup
// //   setPopupVisible1(true); // Show the popup
// // };
// // // const handlePageChange = (newPage) => {
// // //   setCurrentPage(newPage); // Update current page
// // //   fetchData(newPage); // Fetch data for the new page
// // // };

// // const fetchData = async (page) => {
// //   try {
// //     setLoading(true);
// //     const key = localStorage.getItem("jwtToken");

// //     // Make sure page is a valid number
// //     if (!page || page < 1) {
// //       console.error("Invalid page number:", page);
// //       return;
// //     }

// //     const response = await axios.get(
// //       `${BASE_URL}/dashboard/orders?search=${searchQuery}&page=${page - 1}&size=${size}`,
// //       {
// //         headers: {
// //           Auth: `Bearer ${key}`,
// //         },
// //       }
// //     );

// //     console.log("Response data:", response.data);

// //     if (response.data && response.data.content) {
// //       setTableData(response.data.content);
// //       setTotalPages(response.data.totalPages); // Set total pages for pagination
// //     } else {
// //       console.error("Invalid data format received:", response.data);
// //     }
// //   } catch (error) {
// //     console.error("Error fetching data:", error);
// //     alert("Failed to fetch data from the server.");
// //   } finally {
// //     setLoading(false);
// //   }
// // };

// // const handleSearchChange = (e) => {
// //   const value = e.target.value;
// //   setSearchQuery(value);
// // };

// // const handleSearchKeyPress = (e) => {
// //   if (e.key === 'Enter') {
// //     fetchData(page); // Trigger search when Enter is pressed
// //   }
// // };

// // const filteredData = tableData.filter((row) =>
// //   row.contact?.toLowerCase().includes(searchQuery.toLowerCase()) || 
// //   row.ninOrderId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
// //   row.name?.toLowerCase().includes(searchQuery.toLowerCase())
// // );
// // useEffect(() => {
// //   if (searchQuery.trim() === "") return; // Skip fetching if search query is empty
// //   fetchData(page); // Fetch data on search query change
// // }, [searchQuery, page]);

// // const handlePageChange = (pageNumber) => {
// //   setPage(pageNumber); // Update page number when a button is clicked
// //   fetchData(pageNumber); // Fetch data for the selected page
// // };

// // useEffect(() => {
// //   fetchData(page); // Fetch data when component mounts
// // }, [page]); // When page changes, fetch new data

// // useEffect(() => {
// //   fetchData(page); // Fetch data on initial load
// // }, [page]);

// //   const handleShowPopup = (content) => {
// //     setPopupContent(content);
// //     setPopupVisible(true);
// //   };

// //   const closePopup = () => {
// //     setPopupVisible(false);
// //   };

// //   const handleInputChange = (ninOrderId, field, value) => {
// //     setRowData((prev) => ({
// //       ...prev,
// //       [ninOrderId]: {
// //         ...prev[ninOrderId],
// //         [field]: value,
// //       },
// //     }));
// //   };

// // //   const handleUpdateRow = async (ninOrderId) => {
// // //   const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage
// // // console.log("xddhdxgh",ninOrderId)
// // //   try {
// // //     // Send PUT request to confirm the payment (without additional data)
// // //     const response = await axios.put(
// // //       `${BASE_URL}/dashboard/confirm-payment?ninOrderId=${ninOrderId}`,
// // //       {},
// // //       {
// // //         headers: {
// // //           Auth: `Bearer ${key}`,
// // //         },
// // //       }
// // //     );

// // //     setNotification("Order confirmed successfully!");
// // //     setNotificationType("success");

// // //     // Hide the notification after 5 seconds
// // //     setTimeout(() => {
// // //       setNotification("");
// // //     }, 5000);

// // //     fetchData(); // Refresh table data after update
// // //   } catch (error) {
// // //     console.error("Error confirming order:", error);
// // //     setNotification("Failed to confirm order. Please try again.");
// // //     setNotificationType("error");

// // //     // Hide the notification after 5 seconds
// // //     setTimeout(() => {
// // //       setNotification("");
// // //     }, 10000);
// // //   }
// // // };

// // // const handleConfirm = () => {
// // //   // Call the API function with the selected order ID
// // //   handleUpdateRow(selectedOrderId);  // You can call your existing function here
// // //   setConfirmButtonColor('green');  // Change the button color to green after confirmation
// // //   setShowConfirmBox(false);  // Close the confirmation box
// // // };

// // const handleUpdateRow = async (ninOrderId) => {
// //   const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage
// //   console.log("xddhdxgh", ninOrderId);

// //   try {
// //     // Send PUT request to confirm the payment (without additional data)
// //     const response = await axios.put(
// //       `${BASE_URL}/dashboard/confirm-payment?ninOrderId=${ninOrderId}`,
// //       {},
// //       {
// //         headers: {
// //           Auth: `Bearer ${key}`,
// //         },
// //       }
// //     );

// //     if (response.status === 200) {
// //       setButtonColors((prevColors) => ({
// //         ...prevColors,
// //         [ninOrderId]: "green", // Set the button color to green for the specific row
// //       }));
// //     }

// //     setNotification("Order confirmed successfully!");
// //     setNotificationType("success");

// //     // Hide the notification after 5 seconds
// //     setTimeout(() => {
// //       setNotification("");
// //     }, 5000);

// //     fetchData(); // Refresh table data after update
// //   } catch (error) {
// //     console.error("Error confirming order:", error);
// //     setNotification("Failed to confirm order. Please try again.");
// //     setNotificationType("error");

// //     // Hide the notification after 5 seconds
// //     setTimeout(() => {
// //       setNotification("");
// //     }, 10000);
// //   }
// // };

// // const handleConfirm = () => {
// //   // Call the API function with the selected order ID
// //   handleUpdateRow(selectedOrderId);  // You can call your existing function here
// //   setShowConfirmBox(false);  // Close the confirmation box
// // };
// //   const indexOfLastRow = currentPage * rowsPerPage;
// //   const indexOfFirstRow = indexOfLastRow - rowsPerPage;
// //   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

// //   const paddedRows = [...currentRows];
// //   while (paddedRows.length < rowsPerPage) {
// //     paddedRows.push({}); 
// //   }


// // return (
// //   <div id="root">
// //     <div className="page-container">
// //       <RedirectComponent />
// //       <Header />

// //       <main>
// //         {notification && (
// //           <div className={`notification ${notificationType}`}>
// //             {notification}
// //           </div>
// //         )}

// //         <div className="search-bar-container" style={{ marginLeft: "20px", marginTop: "20px" }}>
// //           <input
// //             type="text"
// //             value={searchQuery}
// //             onChange={handleSearchChange}
// //             onKeyPress={handleSearchKeyPress} // Add key press handler
// //             placeholder="Search orderId or contacts..."
// //             style={{ width: '300px' }} // Increase width of the search bar
// //           />
// //         </div>

// //         <div className="table-container">
// //           {/* {loading ? (
// //             <div className="loading">Loading...</div>
// //           ) : ( */}
// //             <div className="table-wrapper">
// //               <table className="book-table">
// //                 <thead>
// //                   <tr>
// //                     <th> </th>
// //                     <th>Order ID</th>
// //                     <th>RRN Number</th> {/* Added RRN Number */}
// //                     <th>Name</th>
// //                     <th>Book Title</th>
// //                     <th style={{ width: "80px" }}>Qty</th>
// //                     <th>Total Price</th> {/* Added Total Price */}
// //                     <th style={{ width: "120px" }}>Contact</th>
// //                     <th>Email ID</th>
// //                     <th>Order Date</th>
// //                     <th>Shipping Add.</th>
// //                     <th>Actions</th>
// //                   </tr>
// //                 </thead>

// //                 <tbody>
// //                   {filteredData.length > 0 ? (
// //                     paddedRows.map((row, index) => (
// //                       <tr key={index}>
// //                         <td>
// //                           <span className="bullet-point">•</span>
// //                         </td>
// //                         <td>
// //                           {row.ninOrderId ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.ninOrderId)}
// //                             >
// //                               {row.ninOrderId.length > 15
// //                                 ? row.ninOrderId.substring(0, 15) + "..."
// //                                 : row.ninOrderId}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.rrnNumber ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.rrnNumber)}
// //                             >
// //                               {row.rrnNumber.length > 15
// //                                 ? row.rrnNumber.substring(0, 15) + "..."
// //                                 : row.rrnNumber}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.name ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.name)}
// //                             >
// //                               {row.name.length > 15
// //                                 ? row.name.substring(0, 15) + "..."
// //                                 : row.name}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>
// //                           {row.bookDetails && row.bookDetails[0]?.publicationTitle ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup1(row.bookDetails)}
// //                             >
// //                               {row.bookDetails[0]?.publicationTitle.length > 15
// //                                 ? row.bookDetails[0]?.publicationTitle.substring(0, 15) + "..."
// //                                 : row.bookDetails[0]?.publicationTitle}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>{row.totalBook || ""}</td>
// //                         {/* <td>{row.totalPrice || ""}</td>  */}
// //                             <td>{row.totalPrice != null ? row.totalPrice.toFixed(2) : ''}</td>

// //                         <td>{row.contact || ""}</td>
// //                         <td>
// //                           {row.email ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.email)}
// //                             >
// //                               {row.email.length > 15
// //                                 ? row.email.substring(0, 15) + "..."
// //                                 : row.email}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
// //                         <td>{row.orderedDate || ""}</td>
// //                         <td>
// //                           {row.shippingAddress ? (
// //                             <span
// //                               className="show-more"
// //                               onClick={() => handleShowPopup(row.shippingAddress)}
// //                             >
// //                               {row.shippingAddress.length > 15
// //                                 ? row.shippingAddress.substring(0, 15) + "..."
// //                                 : row.shippingAddress}
// //                             </span>
// //                           ) : (
// //                             ""
// //                           )}
// //                         </td>
                      
// //                          {/* <td>
// //         {row.ninOrderId && (


// // <button
// //   onClick={() => {
// //     setSelectedOrderId(row.ninOrderId);  // Set the selected order ID for confirmation
// //     setShowConfirmBox(true);  // Show the confirmation box
// //   }}
// //   className="submit-btn"
// //   style={{
// //     backgroundColor: buttonColors[row.ninOrderId] || 'red', // Default color is red
// //     color: 'white',
// //   }}
// // >
// //   Confirm
// // </button>


// //         )}
// //       </td> */}

// //       <td>
// //   {row.ninOrderId && (
// //     <button
// //       onClick={() => {
// //         setSelectedOrderId(row.ninOrderId); // Set the selected order ID for confirmation
// //         setShowConfirmBox(true); // Show the confirmation box
// //       }}
// //       className="submit-btn"
// //       style={{
// //         backgroundColor: row.paymentConfirmed ? 'green' : 'red', // Green for true, red for false
// //         color: 'white',
// //       }}
// //     >
// //       Confirm
// //     </button>
// //   )}
// // </td>


      
// //                       </tr>
// //                     ))
// //                   ) : (
// //                     Array.from({ length: 9 - filteredData.length }).map((_, index) => (
// //                       <tr key={index}>
// //                         <td colSpan="10" style={{ textAlign: "center" }}>No Data</td>
// //                       </tr>
// //                     ))
// //                   )}
// //                 </tbody>
// //               </table>

// //               <div className="pagination">
// //                 {Array.from({ length: totalPages }, (_, i) => (
// //                   <button
// //                     key={i}
// //                     onClick={() => handlePageChange(i + 1)}
// //                     className={page === i + 1 ? "active" : ""}
// //                   >
// //                     {i + 1}
// //                   </button>
// //                 ))}
// //               </div>
// //             </div>
// //           {/* )} */}
// //         </div>

// //         {popupVisible && (
// //           <div className="popup-overlay">
// //             <div className="popup-content">
// //               <button className="close-popup" onClick={closePopup}>
// //                 X
// //               </button>
// //               <div className="popup-body">
// //                 <p>{popupContent}</p>
// //               </div>
// //             </div>
// //           </div>
// //         )}

// //         {popupVisible1 && popupContent1 && (
// //           <div className="popup-overlay">
// //             <div className="popup-content">
// //               <button className="close-popup" onClick={() => setPopupVisible1(false)}>
// //                 X
// //               </button>
// //               <div className="popup-body">
// //                 <h2 className="popup-heading">Book Details</h2>
// //                 <div className="book-list">
// //                   {popupContent1.map((book, index) => (
// //                     <div key={index} className="book-details">
// //                       <p className="book-title"><strong>Title:</strong> {book.publicationTitle}</p>
// //                       <p className="book-quantity"><strong>Quantity:</strong> {book.quantity}</p>
// //                       <p className="book-price"><strong>Price:</strong> ₹{book.price}</p>
// //                     </div>
// //                   ))}
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //         )}
// //       </main>
// // {showConfirmBox && (
// //   <div className="confirmation-overlay">
// //     <div className="confirmation-box">
// //       <p>Are you sure you want to confirm order ID: <strong>{selectedOrderId}</strong>?</p>

// //       <div className="button-container">
// //         <button
// //           onClick={() => {
// //             handleConfirm(); // Perform confirm action
// //             // fetchData(currentPage); // Fetch data for the current page
// //             setShowConfirmBox(false); // Close the confirmation box
// //           }}
// //           className="confirm-button"
// //         >
// //           OK
// //         </button>
// //         <button
// //           onClick={() => {
// //             fetchData(currentPage); // Fetch data for the current page (no change)
// //             setShowConfirmBox(false); // Close the confirmation box
// //           }}
// //           className="cancel-button"
// //         >
// //           Cancel
// //         </button>
// //       </div>
// //     </div>
// //   </div>
// // )}



// //       <Footer />
// //     </div>
// //   </div>
// // );

// // };

// // export default BookTableWithAPI;





// ///=============================================testing=================================================




// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
// import DatePicker from "react-datepicker"; // DatePicker component
// import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
// import "../bookinfo/bookinfo.scss"; // External styles
// import Header from "./header"; // Header component
// import Footer from "../bookinfo/footer"; // Footer component
// import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL
// import { format } from 'date-fns';
// import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";


// const BookTableWithAPI = () => {
//   const [popupContent, setPopupContent] = useState(""); // Popup content
//   const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
//   const [currentPage, setCurrentPage] = useState(1); 
//   const [rowData, setRowData] = useState({}); // Store row data
//   const rowsPerPage = 9; // Number of rows per page
//   const [notification, setNotification] = useState(""); // Message to show
//   const [notificationType, setNotificationType] = useState(""); // 'success' or 'error'
//   const [popupVisible1, setPopupVisible1] = useState(false);  // To control popup visibility
//   const [popupContent1, setPopupContent1] = useState(null);  // To store book details for popup
//   const [search, setSearch] = useState("");
//   const [searchQuery, setSearchQuery] = useState(""); 
//   const [page, setPage] = useState(1); // Initialize page to 1 (first page)
//   const [size, setSize] = useState(9); // Default size
//   const [tableData, setTableData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [totalPages, setTotalPages] = useState(0); // Total number of pages
// const [selectedOrderId, setSelectedOrderId] = useState(null); // Make sure you set the selected order ID elsewhere
// const [showConfirmBox, setShowConfirmBox] = useState(false);
// const [updatedOrderId, setUpdatedOrderId] = useState(null); 
// const [buttonColors, setButtonColors] = useState({}); // Track the button colors
// const [openCalendarId, setOpenCalendarId] = useState(null); 
// const filteredData = tableData.filter((row) =>
//   row.contact?.toLowerCase().includes(searchQuery.toLowerCase()) || 
//   row.ninOrderId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
//   row.name?.toLowerCase().includes(searchQuery.toLowerCase())
// );

//   useEffect(() => {
//     fetchData(currentPage);
//   }, [currentPage]);

// useEffect(() => {
//     fetchData();
//    }, []);
// const handleShowPopup1 = (bookDetails) => {
//   setPopupContent1(bookDetails);  // Set the book details for popup
//   setPopupVisible1(true); // Show the popup
// };
// const fetchData = async (page) => {
//   try {
//     setLoading(true);
//     const key = localStorage.getItem("jwtToken");

//     // Make sure page is a valid number
//     if (!page || page < 1) {
//       console.error("Invalid page number:", page);
//       return;
//     }

//     const response = await axios.get(
//       `${BASE_URL}/dashboard/orders?search=${searchQuery}&page=${page - 1}&size=${size}`,
//       {
//         headers: {
//           Auth: `Bearer ${key}`,
//         },
//       }
//     );

//     console.log("Response data:", response.data);

//     if (response.data && response.data.content) {
//       setTableData(response.data.content);
//       setTotalPages(response.data.totalPages); // Set total pages for pagination
//     } else {
//       console.error("Invalid data format received:", response.data);
//     }
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     alert("Failed to fetch data from the server.");
//   } finally {
//     setLoading(false);
//   }
// };

// const handleSearchChange = (e) => {
//   const value = e.target.value;
//   setSearchQuery(value);
// };

// const handleSearchKeyPress = (e) => {
//   if (e.key === 'Enter') {
//     fetchData(page); // Trigger search when Enter is pressed
//   }
// };

// useEffect(() => {
//   if (searchQuery.trim() === "") return; // Skip fetching if search query is empty
//   fetchData(page); // Fetch data on search query change
// }, [searchQuery, page]);

// const handlePageChange = (pageNumber) => {
//   setPage(pageNumber); // Update page number when a button is clicked
//   fetchData(pageNumber); // Fetch data for the selected page
// };

// useEffect(() => {
//   fetchData(page); // Fetch data when component mounts
// }, [page]); // When page changes, fetch new data

// useEffect(() => {
//   fetchData(page); // Fetch data on initial load
// }, [page]);

//   const handleShowPopup = (content) => {
//     setPopupContent(content);
//     setPopupVisible(true);
//   };

//   const closePopup = () => {
//     setPopupVisible(false);
//   };

//   const handleInputChange = (ninOrderId, field, value) => {
//     setRowData((prev) => ({
//       ...prev,
//       [ninOrderId]: {
//         ...prev[ninOrderId],
//         [field]: value,
//       },
//     }));
//   };
// const handleUpdateRow = async (ninOrderId) => {
//   const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage
//   console.log("xddhdxgh", ninOrderId);

//   try {
//     // Send PUT request to confirm the payment (without additional data)
//     const response = await axios.put(
//       `${BASE_URL}/dashboard/confirm-payment?ninOrderId=${ninOrderId}`,
//       {},
//       {
//         headers: {
//           Auth: `Bearer ${key}`,
//         },
//       }
//     );

//     if (response.status === 200) {
//       setButtonColors((prevColors) => ({
//         ...prevColors,
//         [ninOrderId]: "green", // Set the button color to green for the specific row
//       }));
//     }

//     setNotification("Order confirmed successfully!");
//     setNotificationType("success");

//     // Hide the notification after 5 seconds
//     setTimeout(() => {
//       setNotification("");
//     }, 5000);

//     fetchData(); // Refresh table data after update
//   } catch (error) {
//     console.error("Error confirming order:", error);
//     setNotification("Failed to confirm order. Please try again.");
//     setNotificationType("error");

//     // Hide the notification after 5 seconds
//     setTimeout(() => {
//       setNotification("");
//     }, 10000);
//   }
// };

// const handleConfirm = () => {
//   // Call the API function with the selected order ID
//   handleUpdateRow(selectedOrderId);  // You can call your existing function here
//   setShowConfirmBox(false);  // Close the confirmation box
// };
//   const indexOfLastRow = currentPage * rowsPerPage;
//   const indexOfFirstRow = indexOfLastRow - rowsPerPage;
//   const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

//   const paddedRows = [...currentRows];
//   while (paddedRows.length < rowsPerPage) {
//     paddedRows.push({}); 
//   }


// return (
//   <div id="root">
//     <div className="page-container">
//       <RedirectComponent />
//       <Header />

//       <main>
//         {notification && (
//           <div className={`notification ${notificationType}`}>
//             {notification}
//           </div>
//         )}

//         <div className="search-bar-container" style={{ marginLeft: "20px", marginTop: "20px" }}>
//           <input
//             type="text"
//             value={searchQuery}
//             onChange={handleSearchChange}
//             onKeyPress={handleSearchKeyPress} // Add key press handler
//             placeholder="Search orderId or contacts..."
//             style={{ width: '300px' }} // Increase width of the search bar
//           />
//         </div>

//         <div className="table-container">

//             <div className="table-wrapper">
//               <table className="book-table">
//                 <thead>
//                   <tr>
//                     <th> </th>
//                     <th>Order ID</th>
//                     <th>RRN Number</th> {/* Added RRN Number */}
//                     <th>Name</th>
//                     <th>Book Title</th>
//                     <th style={{ width: "80px" }}>Qty</th>
//                     <th>Total Price</th> {/* Added Total Price */}
//                     <th style={{ width: "120px" }}>Contact</th>
//                     <th>Email ID</th>
//                     <th>Order Date</th>
//                     <th>Shipping Add.</th>
//                     <th>Actions</th>
//                   </tr>
//                 </thead>

//                 <tbody>
//                   {filteredData.length > 0 ? (
//                     paddedRows.map((row, index) => (
//                       <tr key={index}>
//                         <td>
//                           <span className="bullet-point">•</span>
//                         </td>
//                         <td>
//                           {row.ninOrderId ? (
//                             <span
//                               className="show-more"
//                               onClick={() => handleShowPopup(row.ninOrderId)}
//                             >
//                               {row.ninOrderId.length > 15
//                                 ? row.ninOrderId.substring(0, 15) + "..."
//                                 : row.ninOrderId}
//                             </span>
//                           ) : (
//                             ""
//                           )}
//                         </td>
//                         <td>
//                           {row.rrnNumber ? (
//                             <span
//                               className="show-more"
//                               onClick={() => handleShowPopup(row.rrnNumber)}
//                             >
//                               {row.rrnNumber.length > 15
//                                 ? row.rrnNumber.substring(0, 15) + "..."
//                                 : row.rrnNumber}
//                             </span>
//                           ) : (
//                             ""
//                           )}
//                         </td>
//                         <td>
//                           {row.name ? (
//                             <span
//                               className="show-more"
//                               onClick={() => handleShowPopup(row.name)}
//                             >
//                               {row.name.length > 15
//                                 ? row.name.substring(0, 15) + "..."
//                                 : row.name}
//                             </span>
//                           ) : (
//                             ""
//                           )}
//                         </td>
//                         <td>
//                           {row.bookDetails && row.bookDetails[0]?.publicationTitle ? (
//                             <span
//                               className="show-more"
//                               onClick={() => handleShowPopup1(row.bookDetails)}
//                             >
//                               {row.bookDetails[0]?.publicationTitle.length > 15
//                                 ? row.bookDetails[0]?.publicationTitle.substring(0, 15) + "..."
//                                 : row.bookDetails[0]?.publicationTitle}
//                             </span>
//                           ) : (
//                             ""
//                           )}
//                         </td>
//                         <td>{row.totalBook || ""}</td>
//                         {/* <td>{row.totalPrice || ""}</td>  */}
//                             <td>{row.totalPrice != null ? row.totalPrice.toFixed(2) : ''}</td>

//                         <td>{row.contact || ""}</td>
//                         <td>
//                           {row.email ? (
//                             <span
//                               className="show-more"
//                               onClick={() => handleShowPopup(row.email)}
//                             >
//                               {row.email.length > 15
//                                 ? row.email.substring(0, 15) + "..."
//                                 : row.email}
//                             </span>
//                           ) : (
//                             ""
//                           )}
//                         </td>
//                         <td>{row.orderedDate || ""}</td>
//                         <td>
//                           {row.shippingAddress ? (
//                             <span
//                               className="show-more"
//                               onClick={() => handleShowPopup(row.shippingAddress)}
//                             >
//                               {row.shippingAddress.length > 15
//                                 ? row.shippingAddress.substring(0, 15) + "..."
//                                 : row.shippingAddress}
//                             </span>
//                           ) : (
//                             ""
//                           )}
//                         </td>
   

//       <td>
//   {row.ninOrderId && (
//     <button
//       onClick={() => {
//         setSelectedOrderId(row.ninOrderId); // Set the selected order ID for confirmation
//         setShowConfirmBox(true); // Show the confirmation box
//       }}
//       className="submit-btn"
//       style={{
//         backgroundColor: row.paymentConfirmed ? 'green' : 'red', // Green for true, red for false
//         color: 'white',
//       }}
//     >
//       Confirm
//     </button>
//   )}
// </td>


      
//                       </tr>
//                     ))
//                   ) : (
//                     Array.from({ length: 9 - filteredData.length }).map((_, index) => (
//                       <tr key={index}>
//                         <td colSpan="10" style={{ textAlign: "center" }}>No Data</td>
//                       </tr>
//                     ))
//                   )}
//                 </tbody>
//               </table>

//               <div className="pagination">
//                 {Array.from({ length: totalPages }, (_, i) => (
//                   <button
//                     key={i}
//                     onClick={() => handlePageChange(i + 1)}
//                     className={page === i + 1 ? "active" : ""}
//                   >
//                     {i + 1}
//                   </button>
//                 ))}
//               </div>
//             </div>
//           {/* )} */}
//         </div>

//         {popupVisible && (
//           <div className="popup-overlay">
//             <div className="popup-content">
//               <button className="close-popup" onClick={closePopup}>
//                 X
//               </button>
//               <div className="popup-body">
//                 <p>{popupContent}</p>
//               </div>
//             </div>
//           </div>
//         )}

//         {popupVisible1 && popupContent1 && (
//           <div className="popup-overlay">
//             <div className="popup-content">
//               <button className="close-popup" onClick={() => setPopupVisible1(false)}>
//                 X
//               </button>
//               <div className="popup-body">
//                 <h2 className="popup-heading">Book Details</h2>
//                 <div className="book-list">
//                   {popupContent1.map((book, index) => (
//                     <div key={index} className="book-details">
//                       <p className="book-title"><strong>Title:</strong> {book.publicationTitle}</p>
//                       <p className="book-quantity"><strong>Quantity:</strong> {book.quantity}</p>
//                       <p className="book-price"><strong>Price:</strong> ₹{book.price}</p>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}
//       </main>
// {showConfirmBox && (
//   <div className="confirmation-overlay">
//     <div className="confirmation-box">
//       <p>Are you sure you want to confirm order ID: <strong>{selectedOrderId}</strong>?</p>

//       <div className="button-container">
//         <button
//           onClick={() => {
//             handleConfirm(); // Perform confirm action
//             // fetchData(currentPage); // Fetch data for the current page
//             setShowConfirmBox(false); // Close the confirmation box
//           }}
//           className="confirm-button"
//         >
//           OK
//         </button>
//         <button
//           onClick={() => {
//             fetchData(currentPage); // Fetch data for the current page (no change)
//             setShowConfirmBox(false); // Close the confirmation box
//           }}
//           className="cancel-button"
//         >
//           Cancel
//         </button>
//       </div>
//     </div>
//   </div>
// )}



//       <Footer />
//     </div>
//   </div>
// );

// };

// export default BookTableWithAPI;







//=========================================10/8374rghhc============================





import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaCalendarAlt } from "react-icons/fa"; // Calendar icon
import DatePicker from "react-datepicker"; // DatePicker component
import "react-datepicker/dist/react-datepicker.css"; // DatePicker CSS
import "../bookinfo/bookinfo.scss"; // External styles
import Header from "./header"; // Header component
import Footer from "../bookinfo/footer"; // Footer component
import { BASE_URL } from '../../components/config/apiConfig'; // Replace with your actual backend URL
import { format } from 'date-fns';
import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";


const BookTableWithAPI = () => {
  const [popupContent, setPopupContent] = useState(""); // Popup content
  const [popupVisible, setPopupVisible] = useState(false); // Popup visibility
  const [currentPage, setCurrentPage] = useState(1); 
  const [rowData, setRowData] = useState({}); // Store row data
  const rowsPerPage = 9; // Number of rows per page
  const [notification, setNotification] = useState(""); // Message to show
  const [notificationType, setNotificationType] = useState(""); // 'success' or 'error'
  const [popupVisible1, setPopupVisible1] = useState(false);  // To control popup visibility
  const [popupContent1, setPopupContent1] = useState(null);  // To store book details for popup
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); 
  const [page, setPage] = useState(1); // Initialize page to 1 (first page)
  const [size, setSize] = useState(9); // Default size
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0); // Total number of pages
const [selectedOrderId, setSelectedOrderId] = useState(null); // Make sure you set the selected order ID elsewhere
const [showConfirmBox, setShowConfirmBox] = useState(false);
const [updatedOrderId, setUpdatedOrderId] = useState(null); 
const [buttonColors, setButtonColors] = useState({}); // Track the button colors
const [openCalendarId, setOpenCalendarId] = useState(null); 
const filteredData = tableData.filter((row) =>
  row.contact?.toLowerCase().includes(searchQuery.toLowerCase()) || 
  row.ninOrderId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
  row.name?.toLowerCase().includes(searchQuery.toLowerCase())
);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

useEffect(() => {
    fetchData();
   }, []);
const handleShowPopup1 = (bookDetails) => {
  setPopupContent1(bookDetails);  // Set the book details for popup
  setPopupVisible1(true); // Show the popup
};

 useEffect(() => {
    // Retrieve the page number from localStorage if it exists
    const savedPage = localStorage.getItem('currentPage');
    if (savedPage) {
      setCurrentPage(Number(savedPage));
    } else {
      setCurrentPage(1); // default to first page if not found
    }
  }, []);
const fetchData = async (page) => {
  try {
    setLoading(true);
    const key = localStorage.getItem("jwtToken");

    // Make sure page is a valid number
    if (!page || page < 1) {
      console.error("Invalid page number:", page);
      return;
    }

    const response = await axios.get(
      `${BASE_URL}/dashboard/orders?search=${searchQuery}&page=${page - 1}&size=${size}`,
      {
        headers: {
          Auth: `Bearer ${key}`,
        },
      }
    );

    console.log("Response data:", response.data);

    if (response.data && response.data.content) {
      setTableData(response.data.content);
      setTotalPages(response.data.totalPages); // Set total pages for pagination
    } else {
      console.error("Invalid data format received:", response.data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    alert("Failed to fetch data from the server.");
  } finally {
    setLoading(false);
  }
};

const handleSearchChange = (e) => {
  const value = e.target.value;
  setSearchQuery(value);
};

const handleSearchKeyPress = (e) => {
  if (e.key === 'Enter') {
    fetchData(page); // Trigger search when Enter is pressed
  }
};

useEffect(() => {
  if (searchQuery.trim() === "") return; // Skip fetching if search query is empty
  fetchData(page); // Fetch data on search query change
}, [searchQuery, page]);

// const handlePageChange = (pageNumber) => {
//   setPage(pageNumber); // Update page number when a button is clicked
//   fetchData(pageNumber); // Fetch data for the selected page
// };
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber); 
    localStorage.setItem('currentPage', pageNumber); // Save the page number to localStorage
    fetchData(pageNumber); 
  };

useEffect(() => {
  fetchData(page); // Fetch data when component mounts
}, [page]); // When page changes, fetch new data

useEffect(() => {
  fetchData(page); // Fetch data on initial load
}, [page]);

  const handleShowPopup = (content) => {
    setPopupContent(content);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  const handleInputChange = (ninOrderId, field, value) => {
    setRowData((prev) => ({
      ...prev,
      [ninOrderId]: {
        ...prev[ninOrderId],
        [field]: value,
      },
    }));
  };
const handleUpdateRow = async (ninOrderId) => {
  const key = localStorage.getItem("jwtToken"); // Retrieve the JWT token from local storage
  console.log("xddhdxgh", ninOrderId);

  try {
    // Send PUT request to confirm the payment (without additional data)
    const response = await axios.put(
      `${BASE_URL}/dashboard/confirm-payment?ninOrderId=${ninOrderId}`,
      {},
      {
        headers: {
          Auth: `Bearer ${key}`,
        },
      }
    );

    if (response.status === 200) {
      setButtonColors((prevColors) => ({
        ...prevColors,
        [ninOrderId]: "green", // Set the button color to green for the specific row
      }));
    }

    setNotification("Order confirmed successfully!");
    setNotificationType("success");

    // Hide the notification after 5 seconds
    setTimeout(() => {
      setNotification("");
    }, 5000);

    fetchData(); // Refresh table data after update
  } catch (error) {
    console.error("Error confirming order:", error);
    setNotification("Failed to confirm order. Please try again.");
    setNotificationType("error");

    // Hide the notification after 5 seconds
    setTimeout(() => {
      setNotification("");
    }, 10000);
  }
};

const handleConfirm = () => {
  // Call the API function with the selected order ID
  handleUpdateRow(selectedOrderId);  // You can call your existing function here
  setShowConfirmBox(false);  // Close the confirmation box
};
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const paddedRows = [...currentRows];
  while (paddedRows.length < rowsPerPage) {
    paddedRows.push({}); 
  }


return (
  <div id="root">
    <div className="page-container">
      <RedirectComponent />
      <Header />

      <main>
        {notification && (
          <div className={`notification ${notificationType}`}>
            {notification}
          </div>
        )}

        <div className="search-bar-container" style={{ marginLeft: "20px", marginTop: "20px" }}>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyPress={handleSearchKeyPress} // Add key press handler
            placeholder="Search orderId or contacts..."
            style={{ width: '300px' }} // Increase width of the search bar
          />
        </div>

        <div className="table-container">

            <div className="table-wrapper">
              <table className="book-table">
                <thead>
                  <tr>
                    {/* <th> S.no</th> */}
                    <th>Order ID</th>
                    <th>Ref. No.</th> {/* Added RRN Number */}
                    <th>Name</th>
                    <th>Book Title</th>
                    <th style={{ width: "80px" }}>Qty</th>
                    <th>Total Price</th> {/* Added Total Price */}
                    <th style={{ width: "120px" }}>Contact</th>
                    <th>Email ID</th>
                    <th>Order Date</th>
                    <th>Shipping Add.</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {filteredData.length > 0 ? (
                    paddedRows.map((row, index) => (
                      <tr key={index}>
                        {/* <td>
                          <span className="bullet-point">•</span>
                        </td> */}
                        <td>
                          {row.ninOrderId ? (
                            <span
                              className="show-more"
                              onClick={() => handleShowPopup(row.ninOrderId)}
                            >
                              {row.ninOrderId.length > 15
                                ? row.ninOrderId.substring(0, 15) + "..."
                                : row.ninOrderId}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {row.rrnNumber ? (
                            <span
                              className="show-more"
                              onClick={() => handleShowPopup(row.rrnNumber)}
                            >
                              {row.rrnNumber.length > 15
                                ? row.rrnNumber.substring(0, 15) + "..."
                                : row.rrnNumber}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {row.name ? (
                            <span
                              className="show-more"
                              onClick={() => handleShowPopup(row.name)}
                            >
                              {row.name.length > 15
                                ? row.name.substring(0, 15) + "..."
                                : row.name}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          {row.bookDetails && row.bookDetails[0]?.publicationTitle ? (
                            <span
                              className="show-more"
                              onClick={() => handleShowPopup1(row.bookDetails)}
                            >
                              {row.bookDetails[0]?.publicationTitle.length > 15
                                ? row.bookDetails[0]?.publicationTitle.substring(0, 15) + "..."
                                : row.bookDetails[0]?.publicationTitle}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>{row.totalBook || ""}</td>
                        {/* <td>{row.totalPrice || ""}</td>  */}
                            <td>{row.totalPrice != null ? row.totalPrice.toFixed(2) : ''}</td>

                        <td>{row.contact || ""}</td>
                        <td>
                          {row.email ? (
                            <span
                              className="show-more"
                              onClick={() => handleShowPopup(row.email)}
                            >
                              {row.email.length > 15
                                ? row.email.substring(0, 15) + "..."
                                : row.email}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>{row.orderedDate || ""}</td>
                        <td>
                          {row.shippingAddress ? (
                            <span
                              className="show-more"
                              onClick={() => handleShowPopup(row.shippingAddress)}
                            >
                              {row.shippingAddress.length > 15
                                ? row.shippingAddress.substring(0, 15) + "..."
                                : row.shippingAddress}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
   

      <td>
  {row.ninOrderId && (
    <button
      onClick={() => {
        setSelectedOrderId(row.ninOrderId); // Set the selected order ID for confirmation
        setShowConfirmBox(true); // Show the confirmation box
      }}
      className="submit-btn"
      style={{
        backgroundColor: row.paymentConfirmed ? 'green' : 'red', // Green for true, red for false
        color: 'white',
      }}
    >
      Confirm
    </button>
  )}
</td>


      
                      </tr>
                    ))
                  ) : (
                    Array.from({ length: 9 - filteredData.length }).map((_, index) => (
                      <tr key={index}>
                        <td colSpan="10" style={{ textAlign: "center" }}>No Data</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <div className="pagination">
                {Array.from({ length: totalPages }, (_, i) => (
                  <button
                    key={i}
                    onClick={() => handlePageChange(i + 1)}
                    className={page === i + 1 ? "active" : ""}
                  >
                    {i + 1}
                  </button>
                ))}
              </div>
            </div>
          {/* )} */}
        </div>

        {popupVisible && (
          <div className="popup-overlay">
            <div className="popup-content">
              <button className="close-popup" onClick={closePopup}>
                X
              </button>
              <div className="popup-body">
                <p>{popupContent}</p>
              </div>
            </div>
          </div>
        )}

        {popupVisible1 && popupContent1 && (
          <div className="popup-overlay">
            <div className="popup-content">
              <button className="close-popup" onClick={() => setPopupVisible1(false)}>
                X
              </button>
              <div className="popup-body">
                <h2 className="popup-heading">Book Details</h2>
                <div className="book-list">
                  {popupContent1.map((book, index) => (
                    <div key={index} className="book-details">
                      <p className="book-title"><strong>Title:</strong> {book.publicationTitle}</p>
                      <p className="book-quantity"><strong>Quantity:</strong> {book.quantity}</p>
                      <p className="book-price"><strong>Price:</strong> ₹{book.price}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
{showConfirmBox && (
  <div className="confirmation-overlay">
    <div className="confirmation-box">
      <p>Are you sure you want to confirm order ID: <strong>{selectedOrderId}</strong>?</p>

      <div className="button-container">
        <button
          onClick={() => {
            handleConfirm(); // Perform confirm action
            // fetchData(currentPage); // Fetch data for the current page
            setShowConfirmBox(false); // Close the confirmation box
          }}
          className="confirm-button"
        >
          OK
        </button>
        <button
          onClick={() => {
          
            setShowConfirmBox(false); // Close the confirmation box
          }}
          className="cancel-button"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
)}



      <Footer />
    </div>
  </div>
);

};

export default BookTableWithAPI;
