//=============================================visitor page =====================================================================

import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Footer from "../footer/Footer";

//=============================global css=========================
const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
// 
  html, body {
    font-family: 'Arial', sans-serif;
    color: #324e6a;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #E5EFF3, #D0E0E5); /* Soft gradient for a professional look */
  }
`;

// Page Wrapper with solid background color
const PageWrapper = styled.div`
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  color: #324e6a;
  background-color: #F4F9FB; /* Light background to keep the page clean and professional */
`;

const HeaderSection = styled.section`
  text-align: center;  
  color: black;
  margin-bottom: 40px;
`;

const Heading = styled.h1`
  background-color: #324e6a; /* Background color */
  color: white; /* Text color */
`;

const SubHeading = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 20px;
  font-weight: 300;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 30px;
  padding: 0 10px;
`;

const FeaturesSection = styled.section`
  margin-bottom: 50px;
`;

const FeatureHeading = styled.h3`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 600;
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const FeatureIcon = styled.div`
  background-color: #3b9f6b;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  margin-right: 20px;
`;

const FeatureText = styled.div`
  flex: 1;
`;

const DownloadSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Space between the heading and the button */
  margin-top: 40px;
  padding: 20px;
`;

const DownloadHeading = styled.h3`
  font-size: 2rem;
  margin: 0;
`;

const DownloadButton = styled.a`
  text-decoration: none;
  background-color: #3b9f6b;
  padding: 15px 40px;
  font-size: 1.5rem;
  border-radius: 5px;
  color: white;
  text-align: center;

  &:hover {
    background-color: #2f7b56;
  }
`;

const VisitorPage = () => {
  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        {/* Header Section */}
        <HeaderSection>
          <Heading>Welcome to Nutrify India Now 2.0</Heading>
        </HeaderSection>
        <SubHeading>Your Comprehensive Health and Nutrition Companion</SubHeading>
        <Paragraph>
          Nutrify India Now 2.0, is your go-to app for managing health and nutrition. From meal tracking to health monitoring, we provide tools to make your health journey easier.
        </Paragraph>

        {/* Features Section */}
        <FeaturesSection>
          <FeatureHeading>Key Features</FeatureHeading>

          <FeatureItem>
            <FeatureIcon>🍽️</FeatureIcon>
            <FeatureText>
              <h4>Track and Record Your Meals</h4>
              <p>
                Nutrify India Now 2.0 allows users to log all their meals, whether it’s breakfast, lunch, dinner, or snacks. The app offers the flexibility to create personalized meals or use pre-designed options. Simply input your food items, and the app will record their nutritional values. You can then analyze your nutrition intake daily, weekly, or monthly. This feature enables you to maintain a balanced diet and keep track of your nutritional goals effortlessly.
              </p>
            </FeatureText>
          </FeatureItem>

          <FeatureItem>
            <FeatureIcon>🏃‍♂️</FeatureIcon>
            <FeatureText>
              <h4>Comprehensive Health Monitoring</h4>
              <p>
                Stay on top of your health with features designed to track key metrics:
                <br />
                <strong>Weight Tracking:</strong> Keep a record of your weight changes and trends over time.
                <br />
                <strong>Water Intake Monitoring:</strong> Stay hydrated by logging your daily water consumption.
                <br />
                <strong>Sleep Analysis:</strong> Log your sleep patterns and analyze their impact on your overall health.
                <br />
                <strong>24-Hour Activity Tracking:</strong> Monitor your physical activity throughout the day.
              </p>
            </FeatureText>
          </FeatureItem>

          <FeatureItem>
            <FeatureIcon>📱</FeatureIcon>
            <FeatureText>
              <h4>Smartwatch Integration</h4>
              <p>
                The app seamlessly integrates with your smartwatch, enabling real-time tracking of your health and fitness data. This connectivity ensures that all your health metrics are consolidated in one place, making it easier to track your progress.
              </p>
            </FeatureText>
          </FeatureItem>

          <FeatureItem>
            <FeatureIcon>🧮</FeatureIcon>
            <FeatureText>
              <h4>Health Calculators</h4>
              <p>
                Nutrify India Now 2.0 includes powerful tools such as:
                <br />
                <strong>BMI (Body Mass Index) Calculator:</strong> Determine your weight category and understand where you stand.
                <br />
                <strong>BMR (Basal Metabolic Rate) Calculator:</strong> Estimate your daily calorie needs based on your activity level and metabolism.
              </p>
            </FeatureText>
          </FeatureItem>

          <FeatureItem>
            <FeatureIcon>📚</FeatureIcon>
            <FeatureText>
              <h4>Access NIN Publications</h4>
              <p>
                Explore and purchase health and nutrition publications directly through the app. Gain access to valuable resources created by the experts at ICMR-NIN, covering various aspects of health and wellness.
              </p>
            </FeatureText>
          </FeatureItem>
        </FeaturesSection>

        {/* Download Section */}
        <DownloadSection>
          <DownloadHeading>Download Nutrify India Now 2.0</DownloadHeading>
          <DownloadButton
            href="https://play.google.com/store/search?q=nutrify+india+now+2.0&c=apps&hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Now from Google Play
          </DownloadButton>
        </DownloadSection>

        <section style={{ textAlign: 'center', marginTop: '20px' }}>
          <h2 style={{ fontSize: '1.6rem', color: '#324e6a' }}>
            Take control of your health today with Nutrify India Now 2.0!
          </h2>
        </section>

        <Footer />
      </PageWrapper>
    </>
  );
};

export default VisitorPage;
