
// Define the base URL for the API endpoint.
// Uncomment one of the following lines depending on the environment you're working in.

// For production server
// const BASE_URL = 'http://68.183.89.215:7073'; // Use this when deploying on the live server

// For local development
// const BASE_URL = 'http://localhost:7073'; // Use this for testing on the local machine

// Currently using the production API for Nutrify India Now
const BASE_URL = 'https://apis.nutrifyindianow.in'; 

// Export the BASE_URL constant for use across the application
export default BASE_URL;
export { BASE_URL };
