/**
 * ContactUs Component
 * 
 * This component renders a contact form where users can submit their queries, feedback, or requests. It allows users
 * to enter their name, phone number, email, and message, and submit the form to the backend API for processing.
 * 
 * - The contact information such as address, phone number, and email of the organization (ICMR - National Institute of Nutrition) is displayed.
 * - A form is provided where users can enter their personal information and their queries.
 * - Upon submission, the form data is sent to the backend API using a POST request, and feedback is shown to the user based on the success or failure of the request.
 * 
 * - `handleChange`: Handles form field changes, updating the state with the latest input values.
 * - `handleSubmit`: Handles form submission, sending the form data to the backend API and displaying success or error messages.
 * 
 * The page layout includes the contact details section and the form, both of which are styled for a clean user interface.
 * The form is submitted using asynchronous fetch API with a FormData object to handle data transmission.
 */

import React, { useState } from 'react';
import './ContactUs.css';
import { BASE_URL } from '../../components/config/apiConfig';

// ContactUs component to render the contact form
const ContactUs = () => {
  // useState hook to manage form data state
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    queries: '',
    requestType: 'Contact',
  });
  // handleChange function to update formData on input change
  const handleChange = (e) => {
    // Spread previous formData and update the field that was changed
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  // handleSubmit function to send the form data to the backend API
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Construct the API URL for submitting the contact form
    const url = `${BASE_URL}/api/contact-us/upload`;

    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });

    try {
      // Fetch request to send form data to the backend
      const response = await fetch(url, {
        method: 'POST',
        body: formDataToSend,
      });
      // Check if the response is not OK 
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // If successful, parse the JSON response
      const result = await response.json();
      console.log('Success:', result);
      alert('Form submitted successfully!');
    } catch (error) {
      console.error('Error:', error);
      alert('Form submission failed!');
    }
  };

  return (
    <div>
      {/* Heading for the contact form section */}
      <h3 className="query-heading">Drop Us a Message</h3>
      <div className="contact-container">
        {/* Section displaying contact information */}
        <div className="contact-info">
          {/* Displaying the contact address */}
          <h2>Contact Us</h2>
          <p>
            <span style={{ fontWeight: 'bold', color: 'black' }}>ICMR</span> - National Institute of Nutrition Jamai-Osmania PO, Hyderabad-500 007, India
          </p>
          {/* Displaying the phone number */}
          <p>
            <span style={{ fontWeight: 'bold', color: 'black' }}>Phone:</span> +914027197200
          </p>
          {/* Displaying the contact email */}
          <p>
            <span style={{ fontWeight: 'bold', color: 'black' }}>Email:</span> nutrifyindianow.nin@gmail.com
          </p>
        </div>
        {/* Section containing the contact form */}
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            {/* Input field for the name */}
            <input
              type="text"
              name="name"
              placeholder="Enter your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {/* Input field for the phone number */}
            <input
              type="text"
              name="number"
              placeholder="Enter your Phone Number"
              value={formData.number}
              onChange={handleChange}
              required
            />
            {/* Input field for the email address */}
            <input
              type="email"
              name="email"
              placeholder="Enter a valid email address"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {/* Textarea for the user to enter their query/message */}
            <textarea
              name="queries"
              placeholder="Enter your message"
              value={formData.queries}
              onChange={handleChange}
              required
            ></textarea>
            {/* Submit button to submit the form */}
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>

    </div>

  );
};

export default ContactUs;
