/**
 * RecipeTable Component
 * 
 * This component is responsible for displaying a list of recipes fetched from an API. It provides functionality 
 * to search recipes by their name. The recipe data is displayed in a table format with various nutritional 
 * information such as protein, fat, carbohydrates, and vitamins.
 * 
 * - The component uses React's `useState` and `useEffect` hooks to manage the state of recipes and the search 
 *   query.
 * - It fetches recipe data from an API when the component is mounted and stores it in the `recipes` state.
 * - Users can filter the displayed recipes based on the search query entered in the input field. The table 
 *   updates dynamically to show only those recipes whose names match the search query.
 * - The recipe data includes various nutritional information such as protein, fat, fiber, vitamins, and minerals.
 * 
 * - `recipes`: Holds the array of recipes fetched from the API.
 * - `searchQuery`: Holds the value entered by the user in the search input field.
 * 
 * The component uses inline styles for the layout of the table, ensuring the recipes are displayed in a clean and 
 * easy-to-read format. The table is scrollable horizontally to handle large datasets.
 */

import React, { useState, useEffect } from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import BASE_URL from '../../components/config/apiConfig';
import "./recipes.scss"; // Import external CSS fi

// Define custom styles for the table container, table, and cells
const tableContainerStyle = {
    overflowX: 'auto',
};

const foodTableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
};

const tableHeaderStyle = {
    backgroundColor: '#f2f2f2',
    border: '1px solid #dddddd',
    padding: '8px',
    textAlign: 'left',
};

const tableCellStyle = {
    border: '1px solid #dddddd',
    padding: '8px',
    textAlign: 'left',
};

function RecipeTable() {
    // Define state variables for storing recipe data and search query
    const [recipes, setRecipes] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    // useEffect hook to fetch recipe data from API when the component is mounted
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/recipes/all`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                console.log('Fetched data:', data);
                setRecipes(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    // Filter recipes based on search query
    const filteredRecipes = recipes.filter(recipe =>
        recipe.recipe_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div style={{ position: 'relative' }}>
            <div className="scrollableSidebar">
                <Sidebar />
            </div>

            <div className="content-after-sidebar" >

                <h1 className="recipe-title">Recipes</h1>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                    <input
                        type="text"
                        placeholder="Search by recipes name ..."
                        style={{ flex: 1, height: '30px', marginLeft: '10px', paddingLeft: '10px', border: '1px solid #ccc' }}
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </div>
                {/* Table displaying the filtered list of recipes */}
                <div style={tableContainerStyle}>
                    <table style={foodTableStyle}>
                        <thead>
                            <tr>
                                {/* Table headers for different recipe properties */}
                                <th style={tableHeaderStyle}>Recipe Id</th>
                                <th style={tableHeaderStyle}>Recipe Name</th>
                                <th style={tableHeaderStyle}>Protein</th>
                                <th style={tableHeaderStyle}>Total Fat</th>
                                <th style={tableHeaderStyle}>Total Dietary Fiber</th>
                                <th style={tableHeaderStyle}>Carbohydrate</th>
                                <th style={tableHeaderStyle}>Energy (Joules)</th>
                                <th style={tableHeaderStyle}>Riboflavin B2</th>
                                <th style={tableHeaderStyle}>Total B6</th>
                                <th style={tableHeaderStyle}>Total Folate B9</th>
                                <th style={tableHeaderStyle}>Total Ascorbic Acid</th>
                                <th style={tableHeaderStyle}>Retinol</th>
                                <th style={tableHeaderStyle}>Total Carotenoids</th>
                                <th style={tableHeaderStyle}>Ergocalciferol D2</th>
                                <th style={tableHeaderStyle}>Cholecalciferol D3</th>
                                <th style={tableHeaderStyle}>Vitamin 25-Hydroxy D3</th>
                                <th style={tableHeaderStyle}>Iron</th>
                                <th style={tableHeaderStyle}>Zinc</th>
                                <th style={tableHeaderStyle}>Potassium</th>
                                <th style={tableHeaderStyle}>Sodium</th>
                                <th style={tableHeaderStyle}>Calcium</th>
                                <th style={tableHeaderStyle}>Total Saturated Fatty Acids</th>
                                {/* Add more headers as needed */}
                            </tr>
                        </thead>
                        <tbody>
                            {/* Iterate through the filtered recipes and render table rows */}
                            {filteredRecipes.map(recipe => (
                                <tr key={recipe.recipesId}>

                                    <td style={tableCellStyle}>{recipe.recipesId}</td>
                                    <td style={tableCellStyle}>{recipe.recipe_name}</td>
                                    <td style={tableCellStyle}>{recipe.protein}</td>
                                    <td style={tableCellStyle}>{recipe.total_fat}</td>
                                    <td style={tableCellStyle}>{recipe.total_dietary_fibre}</td>
                                    <td style={tableCellStyle}>{recipe.carbohydrate}</td>
                                    <td style={tableCellStyle}>{recipe.energy_joules}</td>
                                    <td style={tableCellStyle}>{recipe.riboflavinB2}</td>
                                    <td style={tableCellStyle}>{recipe.totalB6}</td>
                                    <td style={tableCellStyle}>{recipe.total_folateb9}</td>
                                    <td style={tableCellStyle}>{recipe.total_ascorbic_acid}</td>
                                    <td style={tableCellStyle}>{recipe.retinol}</td>
                                    <td style={tableCellStyle}>{recipe.total_carotenoids}</td>
                                    <td style={tableCellStyle}>{recipe.ergocalciferolD2}</td>
                                    <td style={tableCellStyle}>{recipe.cholecalciferolD3}</td>
                                    <td style={tableCellStyle}>{recipe.vitamin25HydroxyD3}</td>
                                    <td style={tableCellStyle}>{recipe.iron}</td>
                                    <td style={tableCellStyle}>{recipe.zinc}</td>
                                    <td style={tableCellStyle}>{recipe.potassium}</td>
                                    <td style={tableCellStyle}>{recipe.sodium}</td>
                                    <td style={tableCellStyle}>{recipe.calcium}</td>
                                    <td style={tableCellStyle}>{recipe.total_saturated_fatty_acids}</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default RecipeTable;
